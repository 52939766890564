import axios from 'api';

const url = '/api/logs';

const LogAPI = {
  get: (params: Record<string, unknown>) => axios.get(url, { params }),
  getClientLogs: (params: Record<string, any>) => axios.get(`${url}/client`, { params }),
};

export default LogAPI;
