import { HTTP_METHODS } from '@/types/common';
import { IStorageItemData } from '@/utils/fileStorage';
import { commonApi } from 'redux/commonApi';
import { IUser, IUserResponse } from 'redux/user/user.types';

const url = 'api/users';

const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['Users', 'Documents'] });

export const usersApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<IUserResponse, Record<string, unknown>>({
      query: (params: Record<string, unknown>) => ({
        url: url,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: (res) => {
        return res && res.result && res.result.length
          ? [
              ...res.result.map(({ _id }) => ({
                type: 'Users' as const,
                id: _id,
              })),
              'Users',
            ]
          : ['Users'];
      },
    }),
    getUsersByRole: builder.query<IUser[], string>({
      query: (role: string) => ({
        url: `${url}/role/${role}`,
        method: HTTP_METHODS.get,
      }),
      providesTags: (res) => {
        return res && res && res.length
          ? [
              ...res.map(({ _id }) => ({
                type: 'Users' as const,
                id: _id,
              })),
              'Users',
            ]
          : ['Users'];
      },
    }),
    getUser: builder.query<IUser, string>({
      query: (userId) => ({
        url: `${url}/${userId}`,
        method: HTTP_METHODS.get,
      }),
      providesTags: ['Users'],
    }),
    getUserDocsAsFile: builder.query<Array<IStorageItemData>, string>({
      query: (userId) => ({
        url: `api/upload/docs/${userId}`,
        method: HTTP_METHODS.get,
      }),
      providesTags: ['Documents'],
    }),
    createUser: builder.mutation<IUser, Partial<IUser>>({
      query: (data) => {
        return {
          url,
          method: HTTP_METHODS.post,
          data,
        };
      },
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<IUser, Partial<IUser>>({
      query: ({ _id, ...data }) => ({
        url: `${url}/${_id}`,
        method: HTTP_METHODS.patch,
        data,
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Users', id: arg?._id },
              { type: 'Users', id: 'LIST' },
            ]
          : [],
    }),
  }),
  overrideExisting: true,
});

export const { useGetAllUsersQuery, useGetUsersByRoleQuery, useGetUserQuery, useCreateUserMutation, useGetUserDocsAsFileQuery } =
  usersApi;
