import React, { useEffect } from 'react';

import { Button, Grid, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import format from 'date-fns/format';
import { useForm, Controller } from 'react-hook-form';

import { closeModal, showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCreateFineMutation, useGetUserPlanQuery, useUpdatePlanMutation } from 'redux/plans/plans.service';
import { IFine, IPlan } from 'redux/plans/plans.types';

const CreateFine = () => {
  const fineData = useAppSelector<IFine & { planId: string; userId?: string }>(modalDataSelector);
  const dispatch = useAppDispatch();

  const [create, { isLoading: isCreating, isSuccess: isCreatedSuccess, isError: isCreationError, error }] =
    useCreateFineMutation();

  const {
    data: plan,
    isLoading: isPlanLoading,
    refetch,
  } = useGetUserPlanQuery({ userId: fineData?.userId, period: format(new Date(), 'M/yyyy') }, { skip: !fineData?.userId });

  const [update, { isLoading: isUpdating, isSuccess: isUpdatedSuccess, isError: isUpdatingError, error: updateError }] =
    useUpdatePlanMutation();

  const { control, errors, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { reason: fineData?.reason || '', amount: fineData?.amount || '' },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const submit = (data: Record<string, any>) => {
    if (!fineData?._id) {
      create({
        planId: fineData.planId,
        amount: data.amount,
        reason: data.reason,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } else if (fineData._id) {
      const planData = plan as unknown as IPlan;
      const payload = {
        _id: fineData.planId,
        fines: planData?.fines.map((fine) => {
          if (fine?._id === fineData._id) {
            return { ...fine, amount: data.amount, reason: data.reason, updatedAt: new Date() };
          } else return fine;
        }),
      };
      update(payload);
    }
  };

  const close = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (isCreationError || isUpdatingError) {
      const errorData = isCreationError ? error : updateError;
      // @ts-ignore
      dispatch(showErrorAlert(errorData?.data as unknown as Record<string, any>));
    }
    if (isCreatedSuccess || isUpdatedSuccess) {
      refetch();
      dispatch(showSuccessAlert('Действие успешно'));
      dispatch(closeModal());
    }
  }, [isCreatedSuccess, isCreationError, isUpdatedSuccess]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="amount"
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Сумма в у.е"
                required
                fullWidth
                type={'number'}
                error={!!errors.amount}
                size={'small'}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="reason"
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Причина"
                required
                multiline
                minRows={2}
                maxRows={5}
                fullWidth
                type={'text'}
                error={!!errors.reason}
                size={'small'}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width={'100%'}
            gridGap={'15px'}
            padding={'10px 0'}
          >
            <Button variant={'contained'} size={'small'} onClick={close}>
              Закрыть
            </Button>
            <Button
              disabled={isCreating || isUpdating}
              variant={'contained'}
              size={'small'}
              color={'secondary'}
              onClick={handleSubmit(submit)}
            >
              Отправить
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(CreateFine);
