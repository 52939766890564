import React from 'react';

import { Avatar, Box } from '@material-ui/core';

export interface ProfileAvatarProps {
  size: number;
  imgUrl: string;
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ size, imgUrl, children }) => {
  return (
    <Box position={'relative'}>
      <Avatar style={{ width: size, height: size, borderRadius: '50%' }} src={imgUrl} />
      {children}
    </Box>
  );
};
