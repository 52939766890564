import { createAction, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import { EntityParsedResponse, ISortPayload } from '@/types/common';
import { composeClientName } from '@/utils/helper';
import { filterEmptyObjectField } from '@/utils/validators';
import EventAPI from 'api/event';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { CLOSE_APP_MODAL } from 'redux/app/app.types';
import { IClient } from 'redux/client/client.types';
import { IUserAssignee } from 'redux/user/user.types';

import {
  IEvent,
  EVENT_CREATE,
  EVENT_DELETE,
  EVENT_GET_CURRENT,
  EVENT_UPDATE,
  EVENTS_CLEAR_FILTERS,
  EVENTS_GET_ALL,
  EVENTS_SET_FILTERS,
  EVENT_FILTERS,
  EVENTS_SET_SORTING,
  EVENTS_CLEAR_SORTING,
  CreateEventRequest,
} from './event.types';

export const getEvents = createAsyncThunk(EVENTS_GET_ALL, async (params: Record<string, any>, { rejectWithValue }) => {
  return EventAPI.getAll(params)
    .then((response): EntityParsedResponse<IEvent> => {
      return {
        total: response.data?.totalCount || 0,
        result: response.data.result,
      };
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const createEvent = createAsyncThunk(EVENT_CREATE, async (event: CreateEventRequest, { rejectWithValue, dispatch }) => {
  return EventAPI.create(event)
    .then((res) => {
      dispatch(showSuccessAlert('Событие успешно добавлено!'));
      dispatch({ type: CLOSE_APP_MODAL });
      return res.data.event;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const updateEvent = createAsyncThunk(
  EVENT_UPDATE,
  async (event: Partial<CreateEventRequest>, { rejectWithValue, dispatch }) => {
    return EventAPI.update(filterEmptyObjectField(event))
      .then((res) => {
        dispatch(showSuccessAlert('Событие успешно обновлено!'));
        dispatch({ type: CLOSE_APP_MODAL });
        return res.data.event;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const postEvent = (event: CreateEventRequest, close = true) => createEvent(event);
export const patchEvent = (event: Partial<CreateEventRequest>, close = true) => updateEvent(event);

export function postCreateClientEvent(client: IClient) {
  const eventData: CreateEventRequest = {
    event: {
      title: 'Регистрация',
      description: `Новый клиент ${composeClientName(client)}`,
      deadline: new Date().toISOString(),
      author: client.registered_by as IUserAssignee,
      assignee: client?.assignee as IUserAssignee,
      status: 'Выполнено',
      office: client.office,
      createdAt: new Date().toISOString(),
    },
    client: client,
  };

  return createEvent(eventData);
}

export const deleteEvent = createAsyncThunk(EVENT_DELETE, async (id: string, { rejectWithValue, dispatch }) => {
  return EventAPI.delete(id)
    .then(() => {
      dispatch(showSuccessAlert('Событие удалено!'));
      return id;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const getSingleEvent = createAsyncThunk(EVENT_GET_CURRENT, async (id: string, { rejectWithValue }) => {
  return EventAPI.getOne(id)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response?.data?.detail || ''));
});

export const setEventsFilters = createAction(EVENTS_SET_FILTERS, (payload) => {
  window.localStorage.setItem(EVENT_FILTERS, JSON.stringify(payload));
  return { payload };
});
export const clearEventsFilters = createAction(EVENTS_CLEAR_FILTERS);

export const setEventSorting = createAction(EVENTS_SET_SORTING, (payload: Array<ISortPayload>) => {
  return {
    payload: {
      [payload[0].id]: payload[0].desc ? '-1' : '1',
    },
  };
});

export const clearClientSorting = createAction(EVENTS_CLEAR_SORTING);

export const isEventActionsPending = isAnyOf(
  getEvents.pending,
  createEvent.pending,
  updateEvent.pending,
  deleteEvent.pending,
  getSingleEvent.pending,
);
export const isEventActionsRejected = isAnyOf(
  getEvents.rejected,
  createEvent.rejected,
  updateEvent.rejected,
  deleteEvent.rejected,
  getSingleEvent.rejected,
);
