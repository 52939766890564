import { AxiosResponse } from 'axios';

import axios from 'api';
import { IOffice, OfficeActionResponse, SwitchOffOfficeRequest } from 'redux/office/office.types';

const url = '/api/offices';

const OfficeAPI = {
  getAll: (params: Record<string, string>) => axios.get(url, params),
  getOne: (id: string) => axios.get(`${url}/${id}`),
  create: (data: Partial<IOffice>) => axios.post(url, data),
  update: (data: Partial<IOffice>) => axios.patch(`${url}/${data._id}`, data),
  toggleActive: (data: SwitchOffOfficeRequest): Promise<AxiosResponse<OfficeActionResponse>> =>
    axios.patch(`${url}/toggle_active/${data._id}`, data),
  delete: (id: string) => axios.delete(`${url}/${id}`),
};

export default OfficeAPI;
