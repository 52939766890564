import { createStyles, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      // @ts-ignore
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      circle: {
        flexShrink: 0,
        borderRadius: '50%',
        width: '12px',
        height: '12px',
        marginRight: '12px',
      },
      text: {
        whiteSpace: 'nowrap',
      },
      success: {
        backgroundColor: theme.palette.secondary.main,
      },
      warning: {
        backgroundColor: theme.palette.warning.main,
      },
      inProgress: {
        backgroundColor: theme.palette.primary.main,
      },
      error: {
        // @ts-ignore
        backgroundColor: theme.palette.error.main,
      },
    }),
  {
    name: 'CustomPin',
  },
);

export default useStyles;
