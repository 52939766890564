import { IUser } from 'redux/user/user.types';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_EMPLOYEE_PASSWORD = 'CHANGE_EMPLOYEE_PASSWORD';

export interface LoginRequest {
  mobile: string;
  password: string;
}

export interface AuthState {
  isLoading: boolean;
  isLoggedIn: boolean;
}

export interface ChangePasswordDto {
  newPassword: string;
  oldPassword: string;
}

export interface ChangeEmployeePasswordDto {
  newPassword: string;
  employeeId: string;
}

export interface LoginResponse {
  access_token: string;
  isPasswordExpired: boolean;
  refresh_token: string;
  user: IUser;
}
