import React, { FC, useEffect, useMemo, useState } from 'react';

import { Checkbox, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { ControllerRenderProps } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { roles } from '@/MOCK/quickCreationMockData';
import { useStyles } from 'components/Topics/UsersSelector/UsersSelector.styles';
import { userListSelector } from 'redux/user/user.selectors';

interface IUserSelectorProps {
  setUsers: (value: Array<string>) => void;
  label: string;
  placeholder: string;
  error: boolean;
  inputProps: ControllerRenderProps;
}

export interface IUserForSelector {
  _id: string;
  name: string;
  office: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const UsersSelector: FC<IUserSelectorProps> = ({ setUsers, error, inputProps, label, placeholder }) => {
  const classes = useStyles();
  const stateUsers = useSelector(userListSelector);
  const [inputValue, setInputValue] = useState<Array<IUserForSelector>>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<Array<string>>([]);

  const selectedIds: Array<string> = useMemo(() => inputValue.map(({ _id }) => _id), [inputValue]);
  const users: Array<IUserForSelector> = useMemo(
    () =>
      stateUsers.map((user) => ({
        _id: user._id,
        name: `${user?.personalData?.surname} ${user?.personalData?.name} (${roles[user?.role]})`,
        office: user.office.name,
      })),
    [stateUsers, selectedIds],
  );

  useEffect(() => {
    if (inputProps?.value?.length && !isReady) {
      const mapValue = inputProps.value.map((props: Record<string, string | Object> | string) =>
        typeof props === 'object' ? props._id : props,
      );
      const defaultAssignees = users.filter((user) => mapValue.includes(user._id));
      setInputValue(defaultAssignees);
      setIsReady(true);
    }
  }, [inputProps, isReady]);

  const handleChangeInput = (newValue: any): void => {
    if (newValue) {
      setUsers(newValue.map((value: IUserForSelector) => value._id));
      setTimeout(() => setInputValue(newValue), 100);
    }
  };

  const handleChangeOptionCheckbox = (elem: IUserForSelector, value: boolean): void => {
    if (!value) {
      handleChangeInput([...inputValue.filter((item) => item._id !== elem._id)]);
    } else {
      handleChangeInput([elem, ...inputValue]);
    }
  };

  const handleChangeGroupCheckbox = (params: any, value: boolean): void => {
    if (!value) {
      setSelectedGroup([...selectedGroup.filter((group) => group !== params.group)]);
      handleChangeInput(inputValue.filter((item) => item.office !== params.group));
    } else {
      setSelectedGroup([...selectedGroup, params.group]);
      handleChangeInput([...inputValue, ...users.filter((user) => user.office === params.group)]);
    }
  };

  const handleChangeAutocomplete = (value: any, reason: string, details: any): void => {
    if (reason === 'remove-option') {
      setUsers([...inputValue.filter((value) => value._id !== details.option?._id).map(({ _id }) => _id)]);
      setTimeout(() => setInputValue([...inputValue.filter((value) => value._id !== details?.option?._id)]), 100);
      return;
    } else {
      handleChangeInput(value);
    }
  };

  return (
    <Autocomplete
      multiple
      value={inputValue}
      options={users.sort((a, b) => -b.office.localeCompare(a.office))}
      fullWidth
      disableClearable
      getOptionSelected={(option) => {
        return inputValue.map((val) => val._id).includes(option._id);
      }}
      disableCloseOnSelect
      noOptionsText={'Пользователь не найден'}
      groupBy={(option) => option.office}
      getOptionLabel={(user) => (user ? user.name : '')}
      renderGroup={(params) => (
        <>
          <li
            className={`${classes.group} ${selectedGroup.includes(params.group) ? classes.selected : ''}`}
            key={params.key}
            onClick={() => handleChangeGroupCheckbox(params, !selectedGroup.includes(params.group))}
          >
            <Checkbox
              style={{ marginRight: 12, marginLeft: 10 }}
              checked={selectedGroup.includes(params.group)}
              onChange={(event, value) => handleChangeGroupCheckbox(params, value)}
            />
            {params.group}
          </li>
          <li>
            <ul>{params.children}</ul>
          </li>
        </>
      )}
      renderOption={(props) => (
        <div {...props} key={props._id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginLeft: 15, marginRight: 16 }}
            checked={inputValue.map(({ _id }) => _id).includes(props._id)}
            onChange={(e, value) => handleChangeOptionCheckbox(props, value)}
          />
          {props.name}
        </div>
      )}
      onChange={(e, value, reason, details) => handleChangeAutocomplete(value, reason, details)}
      renderInput={(params) => <TextField {...params} error={error} label={label} placeholder={placeholder} fullWidth />}
    />
  );
};

export default UsersSelector;
