import React from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import SnippetWrapper from 'components/CustomComponents/CustomSnippetWrapper/SnippetWrapper';
import ScrollableBox from 'components/CustomComponents/ScrollableBox';

import useStyles from './ClientList.styles';

export interface IClients {
  _id: string;
  events: Array<ClientsEvents>;
  client: string;
}

export interface ClientsEvents {
  _id: string;
  title: string;
  deadline: string;
  status: string;
}

interface ClientListProps {
  clients: Array<IClients>;
  selected: string;
  action: string;
  selectClient: (value: string) => void;
  remove?: (client: IClients) => void;
  addClient?: (client: IClients) => void;
  handleAddAllClient: (action: string) => void;
}

const ClientsList = ({ clients, selected, selectClient, addClient, action, remove, handleAddAllClient }: ClientListProps) => {
  const classes = useStyles();

  const handleSelectClient = (clientId: string) => {
    selectClient(clientId);
  };

  const handleClientAction = (event: React.MouseEvent<HTMLButtonElement>, client: IClients) => {
    event.stopPropagation();
    if (action === 'add' && addClient) {
      addClient(client);
    } else if (action === 'remove' && remove) {
      remove(client);
    }
  };

  return (
    <SnippetWrapper width={'100%'}>
      <Typography variant={'body2'} style={{ margin: '10px 0' }} align={'center'}>
        {action === 'add' ? 'Список доступных клиентов' : 'Выбранные клиенты'}
      </Typography>
      <Button
        onClick={() => handleAddAllClient(action)}
        fullWidth
        size={'small'}
        variant={'outlined'}
        color={'secondary'}
      >
        {action === 'add' ? 'Передать всех' : 'Убрать всех'}
      </Button>
      <div className={classes.mainContainer}>
        <ScrollableBox>
          {clients?.map((client) => (
            <div
              className={classes.clientWrapper}
              key={client._id}
              onClick={() => handleSelectClient(client._id)}
              style={{ background: selected === client._id ? '#000' : '#5d6b61' }}
            >
              <Typography variant={'body2'}>{client.client}</Typography>
              <IconButton size={'small'} onClick={(event) => handleClientAction(event, client)}>
                {action === 'add' ? (
                  <AddIcon fontSize={'small'} color={'secondary'} />
                ) : (
                  <CloseRoundedIcon fontSize={'small'} color={'error'} />
                )}
              </IconButton>
            </div>
          ))}
        </ScrollableBox>
        {!clients.length && (
          <Typography variant={'caption'} align={'center'}>
            Клиенты отсутствуют
          </Typography>
        )}
      </div>
    </SnippetWrapper>
  );
};

export default ClientsList;
