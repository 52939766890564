import { createSelector } from 'reselect';

import { IFilters, IStatuses } from '@/types/common';
import { REQUEST_STATUS_ERROR, REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS } from '@/utils/constants';
import { IClient, IClientStatuses } from 'redux/client/client.types';
import { RootState } from 'redux/store';

const clientSorting = (state: RootState) => state.clients.sort;
export const clientListSelector = (state: RootState): IClient[] => state?.clients?.data;
export const blackListClients = (state: RootState): IClient[] => state?.clients.blacklist;
export const brokerClientsSelector = (state: RootState): Partial<IClient>[] =>
  state?.clients?.brokerClients.map((item) => {
    return {
      ...item,
      fullName: `${item.name} ${item.surname}`,
      totalProfit: item.totalProfit.toFixed(2),
      totalSwap: item.totalSwap.toFixed(2),
      totalCommission: item.totalCommission.toFixed(2),
    };
  });

export const blackListClientsSelector = createSelector(blackListClients, (list): IClient[] => list);
export const currentClientSelector = (state: RootState): IClient | null => state.clients.currentClient;
export const currentClientAssigneeSelector = createSelector(currentClientSelector, (client) => {
  return client?.assignee;
});
export const currentClientAssigneeIdSelector = createSelector(currentClientSelector, (client) => {
  if (typeof client?.assignee !== 'string') {
    return client?.assignee?._id;
  }
});
export const currentClientIdSelector = createSelector(currentClientSelector, (client) => client?._id);

export const currentClientBrokerIdSelector = (state: RootState) => state.clients.currentClient?.brokerClientId;

export const failedEventTypeSelector = (state: RootState): string => state.clients.failedEvent;

const clientFilters = (state: RootState): IFilters => state.clients.filters;
export const clientFilterSelector = createSelector(clientFilters, (filters) => filters);
export const clientSortingSelector = createSelector(clientSorting, (sort) => sort);
export const clientBrokerClientSortingSelector = (state: RootState) => state.clients.sortBrokerClients;
export const brokerClientFilter = (state: RootState): IFilters => state.clients.brokerClientFilters;
export const brokerClientFilterSelector = createSelector(brokerClientFilter, (filters) => filters);

export const totalClientsSelector = (state: RootState): number => state.clients.totalClients;
export const totalBrokerClientsSelector = (state: RootState): number => state.clients.totalBrokerClients;
export const clientsFetchStatusSelector = (state: RootState): IStatuses => state.clients.status;
export const clientLoaderStatus = (state: RootState): IStatuses => state.clients.status;
export const clientLoaderStatusSelector = createSelector(
  clientLoaderStatus,
  (status): IClientStatuses => ({
    isLoading: status === REQUEST_STATUS_PENDING,
    isSuccess: status === REQUEST_STATUS_SUCCESS,
    isError: status === REQUEST_STATUS_ERROR,
  }),
);
