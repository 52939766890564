import { IEvent } from 'redux/event/event.types';
import { IUserAssignee } from 'redux/user/user.types';

export enum PlansTypes {
  individual = 'individual',
  office = 'office',
  role = 'role',
}
export interface IFine {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  reason: string;
  amount: number;
}
export interface IPlan {
  _id: string;
  type: PlansTypes;
  period: string;
  goal: number;
  amount: number;
  isActive: boolean;
  isComplete: boolean;
  officeId: string;
  createdAt: Date;
  updatedAt: Date;
  reward: number;
  fines: Array<IFine>;
  rewardBefore: number;
  events: Array<IEvent>;
  successEventIds: Array<string>;
  isPayed?: boolean;
  assignee?: string | IUserAssignee;
  percent: {
    before: number;
    in: number;
  };
}

export type CreatePlanDto = Omit<IPlan, '_id' | 'isActive' | 'isComplete'>;
export type UpdatePlanDto = Partial<IPlan>;
export type CreateFineDto = Omit<IFine, '_id'> & { planId: string };

export interface ClosePlanDto {
  officeId: string;
  period: string;
}

export interface CreatePaymentDto {
  userId: string;
  salary: string;
  planId: string;
}

export interface PlansState {
  shouldRefresh: boolean;
  plansPeriod: Array<string>;
  currentOfficePlanAmount: number;
  currentPeriod: string;
}

export interface ParsedPlans {
  individual: IPlan[];
  office: IPlan[];
  role: IPlan[];
  individualPlanAmountsSum: number;
}

export const CHANGE_CURRENT_PLAN_PERIOD = 'CHANGE_CURRENT_PLAN_PERIOD';
