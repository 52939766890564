import { createSlice } from '@reduxjs/toolkit';

import { getLastMessage } from 'redux/message/message.actions';
import { MessageState } from 'redux/message/message.types';

const initialState: MessageState = {
  message: '',
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLastMessage.fulfilled, (state, { payload }) => {
      state.message = payload.message;
    });
  },
});

export default messageSlice.reducer;
