import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 600,
    overflow: 'auto',
    gap: 10,
    minWidth: 200,
  },
  eventWrapper: {
    display: 'flex',
    fontSize: 16,
  },
}));

export default useStyles;
