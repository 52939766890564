import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  Fade,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ru from 'date-fns/locale/ru';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { taskTypeOptions } from '@/MOCK/quickCreationMockData';
import { BaseModalProps, IUserAutocompleteOptionItem, OptionItem } from '@/types/common';
import { _userListForClientAutocomplete, rolesListForAutocomplete } from '@/utils/selectVariants';
import { transformValidatePlan } from '@/utils/validators';
import { closeModal, showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { IRTKError } from 'redux/commonApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IOffice } from 'redux/office/office.types';
import { useCreatePlanMutation, useUpdatePlanMutation } from 'redux/plans/plans.service';
import { CreatePlanDto, IPlan, PlansTypes } from 'redux/plans/plans.types';
import { isHeadOfAttractionDepartmentRole } from 'redux/profile/profile.selectors';
import { IUser } from 'redux/user/user.types';

const CreatePlan = (props: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [createPlan, { isLoading: isCreating, isSuccess: isCreateSuccess, error, isError }] = useCreatePlanMutation();
  const [updatePlan, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = useUpdatePlanMutation();
  const isHeadOfAttractionDepartment = useAppSelector(isHeadOfAttractionDepartmentRole);

  const { planData, currentOfficeUsers, office } = useAppSelector<{
    planData: IPlan;
    currentOfficeUsers: Array<IUser>;
    office: IOffice;
  }>(modalDataSelector);

  const [task, setTask] = useState<Record<string, any>>({
    type: '',
    assignee: null,
    officeId: office._id,
    role: [],
    goal: '',
    amount: '',
    reward: '',
    rewardBefore: '',
    percent: {
      before: '',
      in: '',
    },
    period: null,
  });
  const defaultValues = useMemo(() => {
    return planData ? { ...planData, rewardBefore: planData?.rewardBefore || '' } : task;
  }, [planData]);

  const { errors, register, reset, handleSubmit, control, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const responsePersons = _userListForClientAutocomplete(currentOfficeUsers);
  const { roles } = props;
  const employeePosition = rolesListForAutocomplete(roles);

  const submit = (data: CreatePlanDto) => {
    if (isHeadOfAttractionDepartment) {
      data.type = PlansTypes.individual;
    }
    const validated = transformValidatePlan(data) as CreatePlanDto;
    planData
      ? updatePlan({ ...validated, _id: planData._id })
      : createPlan({
          ...validated,
          officeId: office._id,
        });
  };

  function changeHandler<T>(e: React.ChangeEvent<{}>, option: OptionItem<T>) {
    if (!option?.type) return;
    setTask({ ...task, [option.type]: option.value });
    setValue(option.type, option.value);
  }

  const clearValue = (field: string, value: string | null) => {
    setTask({ ...task, [field as string]: value });
    setValue(field, value);
  };

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess) {
      dispatch(showSuccessAlert(`План ${isCreateSuccess ? 'создан' : 'обновлен'}`));
      dispatch(closeModal());
    }
    if (isError) {
      dispatch(showErrorAlert((error as IRTKError).data?.error as Record<string, string>));
    }
  }, [isUpdateSuccess, isCreateSuccess, isError]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="type"
            rules={{ required: !isHeadOfAttractionDepartment }}
            defaultValue={task.type}
            control={control}
            render={(props) => {
              return (
                <FormControl disabled={isHeadOfAttractionDepartment} fullWidth size={'small'} required variant="outlined">
                  <InputLabel id="select-outlined-label-group">
                    {isHeadOfAttractionDepartment ? 'Индивидуальный' : 'Тип плана'}
                  </InputLabel>
                  <Select
                    disabled={!!planData || isHeadOfAttractionDepartment}
                    {...props}
                    onChange={({ target }) => {
                      setTask((prevState) => ({ ...prevState, [target.name as string]: target.value }));
                      target.name && setValue(target.name as 'type', target.value);
                    }}
                    error={!!errors.type}
                    labelId="select-outlined-label-group"
                    id="select-outlined-group"
                  >
                    {taskTypeOptions.map((type) => (
                      <MenuItem value={type.value} key={type.value}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="period"
            render={(props) => (
              <ReactDatePicker
                {...props}
                locale={ru}
                isClearable
                selected={task.period}
                required
                onChange={(period) => {
                  setTask({
                    ...task,
                    period,
                  });
                  setValue('period', period);
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={
                  <TextField
                    error={!!errors.date}
                    {...props}
                    label="Выберите период"
                    required
                    fullWidth={true}
                    size={'small'}
                    variant="outlined"
                  />
                }
              />
            )}
          />
        </Grid>

        <Fade in={!!task.type}>
          <Grid item xs={12}>
            {task.type === 'individual' && (
              <Controller
                name="assignee"
                control={control}
                rules={{ required: task.type === 'individual' }}
                defaultValue={responsePersons.find((user) => user.value === task?.assignee)}
                render={(props) => (
                  <Autocomplete
                    {...props}
                    options={responsePersons}
                    size={'small'}
                    getOptionLabel={(option) => (option ? option.title : '')}
                    getOptionSelected={(option) => option.value._id === task[option.type as string]?._id}
                    onChange={(e, newValue: OptionItem<IUserAutocompleteOptionItem> | null) => {
                      newValue ? changeHandler(e, newValue) : clearValue('assignee', null);
                      setTask((prevState) => ({ ...prevState, role: [] }));
                    }}
                    // @ts-ignore
                    value={
                      responsePersons.find((person) => person.value._id === (planData ? task.assignee : task.assignee?._id)) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Сотрудник" required error={!!errors.assignee} variant="outlined" />
                    )}
                  />
                )}
              />
            )}
            {task.type === 'role' && (
              <Controller
                name="role"
                control={control}
                rules={{ required: task.type === 'role' }}
                render={(props) => (
                  <TextField
                    select
                    {...props}
                    size={'small'}
                    fullWidth
                    name="role"
                    variant="outlined"
                    error={!!errors.role}
                    label="Выберите должность"
                    SelectProps={{
                      /*@ts-ignore*/
                      renderValue: (value: typeof task.role) => value.join(', '),
                      multiple: true,
                      style: { width: '70%' },
                      value: task.role as string[],
                      onChange: (e) => {
                        const { name, value } = e.target;
                        setTask({ ...task, [name as string]: value as string, assignee: null });
                        setValue(name as 'role', value);
                      },
                    }}
                  >
                    {employeePosition.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <ListItemIcon>
                          <Checkbox checked={task.role.indexOf(option.value) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.title} />
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
          </Grid>
        </Fade>
        <Grid item xs={12}>
          <Controller
            name="goal"
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Цель (сумма у.е)"
                fullWidth
                required
                type={'number'}
                error={!!errors.goal}
                size={'small'}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'10px'} flexWrap={'nowrap'}>
            <Controller
              name="percent.before"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Процент до"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  type={'number'}
                  error={!!errors.percent?.before}
                  required
                  size={'small'}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="percent.in"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Процент при"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  required
                  type={'number'}
                  error={!!errors.percent?.in}
                  size={'small'}
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'10px'} flexWrap={'nowrap'}>
            <Controller
              name="rewardBefore"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Премия до"
                  required
                  fullWidth
                  type={'number'}
                  error={!!errors.rewardBefore}
                  size={'small'}
                  variant="outlined"
                />
              )}
            />

            <Controller
              name="reward"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Премия при"
                  required
                  fullWidth
                  type={'number'}
                  error={!!errors.reward}
                  size={'small'}
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>

        {planData && (
          <Grid item xs={12}>
            <Controller
              name="amount"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Всего пополнений"
                  fullWidth
                  required
                  type={'number'}
                  error={!!errors.amount}
                  size={'small'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <Box style={{ justifyContent: 'center', display: 'flex', padding: '10px 0' }}>
        <Button disabled={isCreating} onClick={handleSubmit(submit)} variant={'contained'} fullWidth color={'primary'}>
          Сохранить
        </Button>
      </Box>
    </form>
  );
};

export default CreatePlan;
