export const MANAGEMENT_COLLECT_NEW_MEMBER_DATA = 'MANAGEMENT_COLLECT_NEW_MEMBER_DATA';
export const MANAGEMENT_UPDATE_CURRENT_EMPLOYER = 'MANAGEMENT_UPDATE_CURRENT_EMPLOYER';
export const MANAGEMENT_GET_EMPLOYER_DATA_SUCCESS = 'MANAGEMENT_GET_EMPLOYER_DATA_SUCCESS';
export const MANAGEMENT_CLEAR_EMPLOYER_DATA = 'MANAGEMENT_CLEAR_EMPLOYER_DATA';

export interface ViewAccessSelectorTypes {
  isAllowAccessToUsersProfile: boolean;
  isAllowAccessToRoleList: boolean;
  isAllowAccessToOfficeList: boolean;
  isAllowAccessToClientList: boolean;
  isAllowCreateBrokerId: boolean;
  isAllowToBrokerClients: boolean;
  isAdminAccess: boolean;
  globalAccess: boolean;
  highAccess: boolean;
}

export interface IFullOfficeAccessByRole {
  fullAccess: boolean;
}
export interface ICreationAccessByRole {
  createUser: boolean;
  createOffice: boolean;
  createClient: boolean;
  createEvent: boolean;
  createMessage: boolean;
}
