import React, { useState } from 'react';

import { Box, Button, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';

import { LoadingStatus } from '@/types/common';
import overlay from 'components/common/Overlay';
import Overlay from 'components/common/Overlay';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleOfficeActive } from 'redux/office/office.actions';
import { officeLoadingStatusSelector } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';

const SwitchOffOfficeModal = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { _id } = useAppSelector<Partial<IOffice>>(modalDataSelector);
  const { errors, control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      password: '',
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const submit = (data: Record<string, string>) => {
    const newData = {
      _id: _id as string,
      password: data.password,
      isActive: false,
    };
    setIsLoading(true);
    dispatch(toggleOfficeActive(newData))
      .unwrap()
      .then(() => {
        dispatch(closeModal());
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isLoading && <Overlay />}
          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Новый пароль"
                required
                fullWidth
                type={'password'}
                error={!!errors.password}
                size={'small'}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      <Box style={{ justifyContent: 'center', display: 'flex', padding: '10px 0' }}>
        <Button onClick={handleSubmit(submit)} size={'small'} variant={'contained'}>
          Выключить
        </Button>
      </Box>
    </form>
  );
};

export default SwitchOffOfficeModal;
