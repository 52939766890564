import { createSelector } from 'reselect';

import { IFetchingStatuses, OptionTypes } from '@/types/common';
import { ICategory } from 'redux/categories/categories.types';
import { myIdSelector } from 'redux/profile/profile.selectors';
import { RootState } from 'redux/store';

export const categoriesListSelector = (state: RootState): Array<ICategory> => state.categories.data;
export const categoriesStateStatusSelector = (state: RootState): IFetchingStatuses => state.categories.status;
export const categoryToUpdateIdSelector = (state: RootState): string => state.categories.newCategoryToUpdateId;
export const categoryOptionsSelector = createSelector(
  myIdSelector,
  categoriesListSelector,
  (myId, categories): Array<OptionTypes> => {
    return categories
      .filter((category) => category.owner === myId)
      .map((category) => ({ value: category._id, label: category.name }));
  },
);
