import { RawDraftContentState } from 'draft-js';

import { IFetchingStatuses, ISorting } from '@/types/common';
import { IStorageItemData } from '@/utils/fileStorage';
import { LogItem } from 'redux/log/log.types';
import { IUser, IUserRoles } from 'redux/user/user.types';

export enum TopicStatuses {
  NOT_STARTED = 'Не начато',
  IN_PROGRESS = 'В работе',
  BLOCKED = 'Требует внимания',
  DISCUSSION = 'Обсуждение',
  COMPLETED = 'Выполнено',
  OVERDUE = 'Просрочено',
}

export interface ITopic {
  attachments: Array<IStorageItemData>;
  assignees: Array<string> | Array<IUser>;
  author: IUser;
  changes: Array<LogItem>;
  comments: Array<IComment>;
  createdAt: Date;
  deadline: Date;
  description: RawDraftContentState;
  disableComments: boolean;
  _id: string;
  personalAccess: Array<string>;
  roleAccess: Array<IUserRoles>;
  status: TopicStatuses;
  title: string;
  updatedAt: Date;
}

export interface IComment
  extends Pick<ITopic, '_id' | 'attachments' | 'author' | 'createdAt' | 'updatedAt' | 'description' | 'comments'> {
  topicId: string;
  parentCommentId: string | null;
  replyFor?: string;
}

export interface TopicsState extends Pick<GetTopicsRequest, 'totalCount'> {
  data: Array<ITopic>;
  status: IFetchingStatuses;
  currentTopic: ITopic | null;
  currentTopicComments: Array<IComment>;
  currentTopicCommentsCount: number;
  topicFilters: Record<string, string | number> | null;
  sort: ISorting;
  clearNestedCommentId: string;
}

export interface GetTopicsRequest {
  result: Array<ITopic>;
  totalCount: number;
}

export interface GetTopicRequest extends GetCommentsRequest {
  topic: ITopic;
}

export interface UpdateTopicRequest {
  topic: ITopic;
  message: string;
}

export interface GetCommentsRequest {
  comments: Array<IComment>;
  commentsCount: number;
}

export interface CreateCommentResponse {
  message: string;
  comment: IComment;
}

export const TOPICS_GET_ALL = 'TOPICS_GET_ALL';
export const TOPIC_CREATE = 'TOPICS_CREATE';
export const TOPIC_UPDATE = 'TOPIC_UPDATE';
export const TOPIC_DELETE = 'TOPIC_DELETE';
export const TOPIC_GET_CURRENT = 'TOPIC_GET_CURRENT';
export const TOPICS_SEND_TOPIC_REMIND = 'TOPICS_SEND_TOPIC_REMIND';

export const COMMENTS_GET_CURRENT = 'COMMENTS_GET_CURRENT';
export const COMMENT_CREATE = 'COMMENT_CREATE';
export const COMMENT_UPDATE = 'COMMENT_UPDATE';
export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_GET_NESTED = 'COMMENT_GET_NESTED';

export const TOPIC_FILTERS = 'TOPIC_FILTERS';
export const TOPIC_SET_FILTERS = 'TOPIC_SET_FILTERS';
