import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { getDefaultFilterValues } from '@/utils/utils';
import { createTopic, deleteTopic, getTopic, getTopicComments, getTopics, updateTopic } from 'redux/topics/topics.actions';
import {
  GetCommentsRequest,
  GetTopicRequest,
  GetTopicsRequest,
  IComment,
  ITopic,
  TOPIC_FILTERS,
  TopicsState,
} from 'redux/topics/topics.types';

const initialState: TopicsState = {
  data: [],
  totalCount: 0,
  status: IFetchingStatuses.pending,
  currentTopic: null,
  currentTopicComments: [],
  currentTopicCommentsCount: 0,
  topicFilters: getDefaultFilterValues(TOPIC_FILTERS),
  sort: null,
  clearNestedCommentId: '',
};

const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    addCurrentTopicComment: (state, { payload }) => {
      state.currentTopicComments = [payload as IComment, ...state.currentTopicComments];
    },
    setCurrentTopicComments: (state, { payload }) => {
      state.currentTopicComments = payload;
    },
    setCurrentTopicCommentsCount: (state, { payload }) => {
      state.currentTopicCommentsCount = payload;
    },
    setCurrentTopic: (state, { payload }) => {
      state.currentTopic = payload;
    },
    setTopicFilters: (state, { payload }) => {
      state.topicFilters = payload;
    },
    setTopicSorting: (state, { payload }) => {
      state.sort = payload?.length ? { [payload[0].id]: payload[0].desc ? '-1' : '1' } : null;
    },
    clearTopicFilters: (state) => {
      state.topicFilters = null;
    },
    setClearNestedComments: (state, { payload }) => {
      state.clearNestedCommentId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopics.fulfilled, (state, { payload }) => {
      const { result, totalCount } = payload as GetTopicsRequest;
      state.data = result;
      state.totalCount = totalCount;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(getTopic.fulfilled, (state, { payload }) => {
      const { topic, comments, commentsCount } = payload as GetTopicRequest;
      state.currentTopic = topic;
      state.currentTopicComments = comments;
      state.currentTopicCommentsCount = commentsCount;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(getTopicComments.fulfilled, (state, { payload }) => {
      const { comments, commentsCount } = payload as GetCommentsRequest;
      state.currentTopicComments = comments;
      state.currentTopicCommentsCount = commentsCount;
    });
    builder.addCase(updateTopic.fulfilled, (state, { payload }) => {
      state.currentTopic = payload.topic as ITopic;
    });
    builder.addMatcher(
      isAnyOf(getTopic.pending, getTopics.pending, createTopic.pending, deleteTopic.pending, updateTopic.pending),
      (state) => {
        state.status = IFetchingStatuses.pending;
      },
    );
    builder.addMatcher(
      isAnyOf(getTopic.rejected, getTopics.rejected, createTopic.rejected, deleteTopic.rejected, updateTopic.rejected),
      (state) => {
        state.status = IFetchingStatuses.error;
      },
    );
  },
});

export const {
  addCurrentTopicComment,
  setCurrentTopicComments,
  setCurrentTopic,
  setTopicFilters,
  clearTopicFilters,
  setTopicSorting,
  setClearNestedComments,
} = topicsSlice.actions;
export default topicsSlice.reducer;
