import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Fade,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { CHROME_LIGHT_SHOT_URL, FIREFOX_LIGHT_SHOT_URL, LIGHT_SHOT_URL } from '@/utils/constants';
import { IStorageItemData } from '@/utils/fileStorage';
import Overlay from 'components/common/Overlay';
import AttachmentsView from 'components/Topics/AttachmentsView';
import { closeModal } from 'redux/app/app.actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { profileSelector } from 'redux/profile/profile.selectors';
import { useCreateSupportTicketMutation } from 'redux/support/support.service';
import { SupportSubject, supportSubjectOptions, supportThemeValues } from 'redux/support/support.types';

export const SupportModal = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [createTicket, { isLoading, isSuccess, isError }] = useCreateSupportTicketMutation();
  const profile = useAppSelector(profileSelector);
  const [attachments, setAttachments] = useState<Array<IStorageItemData>>([]);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState<Array<string>>([]);
  const [isAttachmentsLoading, setIsAttachmentsIsLoading] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);

  const defaultValues = {
    subject: '',
    theme: '',
    description: '',
  };

  const toggleDropZone = () => {
    setHasAttachments((prevState) => !prevState);
  };
  const close = () => dispatch(closeModal());
  const submit = (data: typeof defaultValues) => {
    const payload: Record<string, any> = {
      ...data,
      userId: profile._id,
      officeId: profile.office,
      router: router.asPath,
    };
    if (hasAttachments) {
      payload.attachments = attachments;
    }
    createTicket(payload);
  };

  const { errors, control, handleSubmit, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const isButtonDisable = hasAttachments && !attachments.length;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Обращение отправлено');
      close();
    } else if (isError) {
      toast.error('Возникли трудности');
    }
  }, [isError, isSuccess]);

  const renderInfo = () => {
    return (
      <>
        <Accordion variant={'outlined'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Как сделать скриншот ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={'flex'} flexDirection={'column'} width={'100%'} gridGap={10}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>MacOS</Typography>
                <Typography>Command + Shift + 4</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>Windows</Typography>
                <Typography>Print Screen (PS)</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>Расширения браузера</Typography>
                <Box display={'flex'} alignItems={'center'} gridGap={10}>
                  <Link target={'_blank'} href={CHROME_LIGHT_SHOT_URL}>
                    Chrome
                  </Link>
                  <Link target={'_blank'} href={FIREFOX_LIGHT_SHOT_URL}>
                    Firefox
                  </Link>
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>Программы</Typography>
                <Link href={LIGHT_SHOT_URL} target={'_blank'}>
                  LightShot
                </Link>
              </Box>
              <Typography align={'center'} color={'error'} variant={'caption'}>
                При использовании сторонних решений важно сохранять картинку, а не ссылку!!!
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Typography align={'center'} variant={'caption'} color={'error'}>
          Скриншоты объязательны если причина обращения <strong style={{ color: '#fff' }}>Oшибка</strong>
        </Typography>
      </>
    );
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={10}>
      {(isAttachmentsLoading || isLoading) && <Overlay />}
      <Typography variant={'h6'} align={'center'}>
        Обращение в тех.поддержку
      </Typography>
      <form
        onSubmit={handleSubmit(submit)}
        style={{ display: 'flex', flexDirection: 'column', gap: 10, maxHeight: 'auto', transition: 'max-height 0.25s easy' }}
      >
        <Controller
          name="subject"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <TextField
              value={value ?? ''}
              select
              size={'small'}
              label="Причина обращения"
              error={!!errors.subject}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setValue('subject', e.target.value);
                if (e.target.value === SupportSubject.REPORT && !hasAttachments) {
                  setHasAttachments(true);
                } else if (!attachments.length) {
                  setHasAttachments(false);
                }
              }}
            >
              {supportSubjectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="theme"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <TextField
              value={value ?? ''}
              select
              size={'small'}
              label="Тема обращения"
              error={!!errors.theme}
              variant="outlined"
              fullWidth
              onChange={(e) => setValue('theme', e.target.value)}
            >
              {supportThemeValues.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <TextField
              value={value ?? ''}
              multiline
              size={'small'}
              minRows={7}
              label="Описание"
              error={!!errors.description}
              variant="outlined"
              fullWidth
              onChange={(e) => setValue('description', e.target.value)}
            />
          )}
        />

        <Fade in={hasAttachments} unmountOnExit>
          <Box display={'flex'} flexDirection={'column'} gridGap={10}>
            {renderInfo()}
            <Box maxHeight={120}>
              <AttachmentsView
                setIsLoading={setIsAttachmentsIsLoading}
                entityId={undefined}
                entityName={'support'}
                attachments={attachments}
                setAttachmentsToRemove={setAttachmentsToRemove}
                setAttachments={setAttachments}
              />
            </Box>
          </Box>
        </Fade>
      </form>
      <Box display={'flex'} flexWrap={'nowrap'} gridGap={10} marginBottom={1} marginTop={1}>
        <Button
          disabled={isButtonDisable}
          onClick={handleSubmit(submit)}
          fullWidth
          size={'small'}
          color={'primary'}
          variant={'contained'}
        >
          Отправить
        </Button>
        <Tooltip title={'Прикрепить скриншоты'}>
          <IconButton onClick={toggleDropZone} color={'secondary'} style={{ margin: '0 15px' }} size={'small'}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
