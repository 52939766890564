import React from 'react';

import { Typography } from '@material-ui/core';

import { ClientsEvents } from 'components/common/ClientsList/ClientsList';
import CustomPin from 'components/CustomComponents/CustomPin';
import SnippetWrapper from 'components/CustomComponents/CustomSnippetWrapper/SnippetWrapper';
import ScrollableBox from 'components/CustomComponents/ScrollableBox';

import useStyles from './EventsList.styles';

interface EventsListProps {
  events: Array<ClientsEvents>;
}

const EventsList = ({ events }: EventsListProps) => {
  const classes = useStyles();

  return (
    <SnippetWrapper>
      <Typography variant={'body2'} align={'center'} style={{ margin: '10px 0' }}>
        События клиента
      </Typography>
      <div className={classes.mainContainer}>
        <ScrollableBox>
          {events?.map((event) => (
            <div key={event?._id} className={classes.eventWrapper}>
              <CustomPin status={event.status} />
              <span>{event?.title}</span>
            </div>
          ))}
        </ScrollableBox>
        {!events.length && (
          <Typography variant={'caption'} align={'center'}>
            события отсутствуют
          </Typography>
        )}
      </div>
    </SnippetWrapper>
  );
};

export default EventsList;
