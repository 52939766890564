import axios from 'api';
import { IClient } from 'redux/client/client.types';

const url = '/api/clients';

const ClientAPI = {
  getAll: (params: Record<string, string>) => axios.post(`${url}/get`, params),
  getOne: (id: string) => axios.get(`${url}/${id}`),
  getInBlacklist: (params: Record<string, unknown>) => axios.get(`${url}/blacklist/deleted`, { params }),
  getBrokerClients: (params: Record<string, unknown>) => axios.get(`${url}/brokerClients/all`, { params }),
  create: (data: Partial<IClient>) => axios.post(url, data),
  update: (data: Partial<IClient>) => axios.patch(`${url}/${data._id}`, data),
  delete: (id: string) => axios.delete(`${url}/${id}`),
};

export default ClientAPI;
