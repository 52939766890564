import { createTheme } from '@material-ui/core';

import { warningColor, dangerColor, grayColor } from 'assets/jss/material-dashboard-react';

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#7270FF',
    },
    secondary: {
      main: '#53DD16',
      light: '#5cb860',
    },
    background: {
      default: '#28292E',
      paper: '#28292E',
    },
    common: {
      black: '#161616',
    },
    text: {
      primary: grayColor[0],
      secondary: '#ffffff',
    },
    warning: {
      main: warningColor[0],
    },
    error: {
      main: dangerColor[0],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['"Manrope"', 'sans-serif'].join(','),
    body1: {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 700,
    },
    body2: {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 500,
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: '49px',
      color: '#ffffff',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: '33px',
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});

export const lightTheme = createTheme({});
