import { HTTP_METHODS } from '@/types/common';
import { removeDuplicateItemById } from '@/utils/utils';
import { commonApi } from 'redux/commonApi';
import { ClosePlanDto, CreateFineDto, CreatePaymentDto, CreatePlanDto, IPlan, UpdatePlanDto } from 'redux/plans/plans.types';

const url = 'api/plans';

const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['Plans'] });

export const planApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlans: builder.query<IPlan[], Record<string, string>>({
      query: (params) => ({
        url,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: (result) => {
        return result && result
          ? [
              ...result.map(({ _id }) => ({
                type: 'Plans' as const,
                id: _id,
              })),
              'Plans',
            ]
          : ['Plans'];
      },
      transformResponse: (response) => {
        const plansList = response as unknown as IPlan[];
        return plansList && plansList.length ? removeDuplicateItemById<IPlan>(plansList) : [];
      },
    }),
    getUserPlan: builder.query<unknown, Record<string, unknown>>({
      query: ({ userId, ...params }) => ({
        url: `${url}/user/${userId}`,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: ['Plans'],
      transformResponse: (res: IPlan[]) => {
        return res && res.length ? res[0] : {};
      },
    }),
    createPlan: builder.mutation<IPlan, CreatePlanDto>({
      query: (data) => {
        return {
          url,
          method: HTTP_METHODS.post,
          data,
        };
      },
      invalidatesTags: ['Plans'],
    }),
    updatePlan: builder.mutation<IPlan, UpdatePlanDto>({
      query: ({ _id, ...data }) => ({
        url: `${url}/${_id}`,
        method: HTTP_METHODS.patch,
        data,
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Plans', id: arg?._id },
              { type: 'Plans', id: 'LIST' },
            ]
          : [],
    }),
    createFine: builder.mutation<IPlan, CreateFineDto>({
      query: (data) => ({
        url: `${url}/create-fine`,
        method: HTTP_METHODS.post,
        data,
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Plans', id: arg?.planId },
              { type: 'Plans', id: 'LIST' },
            ]
          : [],
    }),
    closePlans: builder.mutation<void, ClosePlanDto>({
      query: ({ officeId, period }) => ({
        url: `${url}/complete/${officeId}`,
        method: HTTP_METHODS.patch,
        data: { officeId, period },
      }),
      invalidatesTags: ['Plans'],
    }),
    makePayments: builder.mutation<void, Array<CreatePaymentDto>>({
      query: (data) => ({
        url: `${url}/payments/create`,
        method: HTTP_METHODS.post,
        data,
      }),
      invalidatesTags: ['Plans'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllPlansQuery,
  useGetUserPlanQuery,
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useClosePlansMutation,
  useCreateFineMutation,
  useMakePaymentsMutation,
} = planApi;
