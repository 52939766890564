import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { uploadAvatar } from '@/utils/fileStorage';
import ProfileAPI from 'api/profile';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { CLOSE_APP_MODAL } from 'redux/app/app.types';

import {
  PROFILE_CHANGE_PASSWORD,
  PROFILE_CLEAR_DATA,
  PROFILE_GET_DATA,
  PROFILE_SET_AVATAR,
  PROFILE_SET_DATA,
  UploadAvatarRequest,
} from './profile.types';

export const clearProfileData = createAction(PROFILE_CLEAR_DATA);
export const setProfileData = createAction(PROFILE_SET_DATA, (payload) => ({ payload }));

export const changeProfilePassword = createAsyncThunk(
  PROFILE_CHANGE_PASSWORD,
  async (data: Record<string, unknown>, { rejectWithValue, dispatch }) => {
    return ProfileAPI.update(data)
      .then(() => {
        dispatch(showSuccessAlert('Пароль успешно изменен!'));
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      })
      .finally(() => {
        dispatch({ type: CLOSE_APP_MODAL });
      });
  },
);

export const getProfileData = createAsyncThunk(PROFILE_GET_DATA, async (props, { rejectWithValue }) => {
  return ProfileAPI.get()
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const changeAvatar = createAsyncThunk(
  PROFILE_SET_AVATAR,
  async (data: UploadAvatarRequest, { rejectWithValue, dispatch }) => {
    return uploadAvatar(data)
      .then((res) => res)
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);
