import React from 'react';

import Box from '@material-ui/core/Box';

import { MOCK_STATUS_IMG } from '@/utils/constants';
import { ImageModal } from 'components/CustomComponents/ImageModal';

import { useStyles } from './styles';

const StatusBadge = ({ url = MOCK_STATUS_IMG, allowScale = true }) => {
  const { root, box } = useStyles();
  return (
    <span className={root}>
      <div style={{ position: 'relative', zIndex: 1 }}>
        {url && (
          <Box className={box}>
            <ImageModal imageUrl={url} previewImage={url} allowScale={allowScale} />
          </Box>
        )}
      </div>
    </span>
  );
};

export default StatusBadge;
