import { useMemo } from 'react';

import { useAppSelector } from 'redux/hooks';
import { viewingAccessByRole } from 'redux/managment/management.selectors';
import { userListSelector } from 'redux/user/user.selectors';

type UseAccessUserResult = { canVisitProfile: boolean; canUseFilterUsers: boolean };

export const useAccessUsers = (userId: string | string[] | undefined): UseAccessUserResult => {
  const userList = useAppSelector(userListSelector);
  const role = useAppSelector(viewingAccessByRole);
  return useMemo(() => {
    const idsTargets = typeof userId === 'undefined' ? [] : typeof userId === 'string' ? [userId] : userId;
    return {
      canVisitProfile: role.isAdminAccess ? true : !!userList.find((user) => idsTargets.includes(user._id)),
      canUseFilterUsers: userList.length > 1,
    };
  }, [userList, userId]);
};
