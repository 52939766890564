import React from 'react';

import { Paper } from '@material-ui/core';
import classNames from 'clsx';

import { useStyles } from './SnippetWrapper.styles';

interface SnippetWrapperProps {
  children: React.ReactNode | React.ReactElement | React.ReactChildren | any;
  padding?: boolean;
  allowOverflow?: boolean;
  width?: string;
  height?: string;
  background?: string;
}

const SnippetWrapper: React.FC<SnippetWrapperProps> = ({
  children,
  padding = true,
  allowOverflow = false,
  width = 'auto',
  height = 'auto',
  background = '',
}) => {
  const classes = useStyles({ width, background, height });
  const rootClasses = classNames(classes.paperBackground, {
    [classes['noPadding']]: !padding,
    [classes['root']]: padding,
    [classes['disableOverflow']]: !allowOverflow,
  });
  return (
    <div className={rootClasses}>
      <Paper className={classes.paperBackground} elevation={0}>
        {children}
      </Paper>
    </div>
  );
};

export default React.memo(SnippetWrapper);
