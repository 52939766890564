import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { EntityParsedResponse, IFetchingStatuses } from '@/types/common';
import { getDefaultFilterValues } from '@/utils/utils';
import {
  clearBrokerClientFilters,
  clearBrokerClientSorting,
  clearClientFilters,
  clearClientSorting,
  clearCurrentClient,
  createClient,
  findInBlackList,
  getBrokerClients,
  getClientById,
  getClients,
  setBrokerClientFilters,
  setBrokerClientSorting,
  setClientFailedEvent,
  setClientFilters,
  setClientSorting,
  updateClient,
} from 'redux/client/client.actions';

import { BROKER_CLIENT_FILTERS, CLIENT_FILTERS, IClient, IClientState } from './client.types';

const initialState: IClientState = {
  data: [],
  status: null,
  totalClients: 0,
  totalBrokerClients: 0,
  currentClient: null,
  brokerClients: [],
  blacklist: [],
  filters: getDefaultFilterValues(CLIENT_FILTERS),
  brokerClientFilters: getDefaultFilterValues(BROKER_CLIENT_FILTERS),
  sort: null,
  sortBrokerClients: null,
  failedEvent: '',
};

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setClientFilters, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(clearClientFilters, (state) => {
      state.filters = null;
      window.localStorage.setItem(CLIENT_FILTERS, '');
    });
    builder.addCase(setBrokerClientFilters, (state, { payload }) => {
      state.brokerClientFilters = payload;
    });
    builder.addCase(clearBrokerClientFilters, (state) => {
      state.brokerClientFilters = null;
      window.localStorage.setItem(BROKER_CLIENT_FILTERS, '');
    });
    builder.addCase(clearCurrentClient, (state) => {
      state.currentClient = null;
    });
    builder.addCase(getClients.fulfilled, (state, { payload }) => {
      const { total, result } = payload as EntityParsedResponse<IClient>;
      state.status = IFetchingStatuses.success;
      state.data = result;
      state.totalClients = total;
    });
    builder.addCase(createClient.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      state.data.push(payload);
    });
    builder.addCase(updateClient.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      const index = state.data.findIndex((client) => client._id === payload._id);
      if (index !== -1) {
        state.data[index] = payload;
      }
      if (state.currentClient) {
        state.currentClient = payload;
      }
    });
    builder.addCase(getClientById.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      state.currentClient = payload;
    });
    builder.addCase(findInBlackList.fulfilled, (state, { payload }) => {
      const data = payload as EntityParsedResponse<IClient>;
      state.status = IFetchingStatuses.success;
      state.blacklist = data?.result || [];
      state.totalClients = data?.total || 0;
    });
    builder.addCase(setClientSorting, (state, { payload }) => {
      state.sort = payload;
    });
    builder.addCase(clearClientSorting, (state) => {
      state.sort = null;
    });
    builder.addCase(setBrokerClientSorting, (state, { payload }) => {
      state.sortBrokerClients = payload;
    });
    builder.addCase(clearBrokerClientSorting, (state) => {
      state.sortBrokerClients = null;
    });
    builder.addCase(setClientFailedEvent, (state, { payload }) => {
      state.failedEvent = payload;
    });
    builder.addCase(getBrokerClients.fulfilled, (state, { payload }) => {
      state.brokerClients = payload.result;
      state.totalBrokerClients = payload.total;
      state.status = IFetchingStatuses.success;
    });
    builder.addMatcher(
      isAnyOf(getClients.pending, createClient.pending, getClientById.pending, findInBlackList.pending, getBrokerClients.pending),
      (state) => {
        state.status = IFetchingStatuses.pending;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getClients.rejected,
        createClient.rejected,
        updateClient.rejected,
        getClientById.rejected,
        findInBlackList.rejected,
        getBrokerClients.rejected,
      ),
      (state) => {
        state.status = IFetchingStatuses.error;
      },
    );
  },
});

export default clientSlice.reducer;
