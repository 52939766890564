import { HTTP_METHODS } from '@/types/common';
import { commonApi } from 'redux/commonApi';
import { GetAllTicketsResponse, ISupportItem, SendTicketMessagePayload } from 'redux/support/support.types';

const url = 'api/support';

export const supportApi = commonApi.enhanceEndpoints({ addTagTypes: ['Support', 'CurrentTicket'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAllSupportTickets: builder.query<GetAllTicketsResponse, Record<string, unknown>>({
      query: (params) => ({
        url,
        params,
        method: HTTP_METHODS.get,
      }),
      providesTags: (result) => {
        return result && result.items
          ? [
              ...result.items.map(({ _id }) => ({
                type: 'Support' as const,
                id: _id,
              })),
              'Support',
            ]
          : ['Support'];
      },
    }),
    getSingleSupportTicket: builder.query<ISupportItem, { id: string }>({
      query: ({ id }) => ({
        url: `${url}/${id}`,
        method: HTTP_METHODS.get,
      }),
      providesTags: () => {
        return ['CurrentTicket'];
      },
    }),
    updateTicket: builder.mutation({
      query: ({ _id, ...data }) => ({
        url: `${url}/${_id}`,
        data,
        method: HTTP_METHODS.patch,
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Support', id: arg?._id },
              { type: 'Support', id: 'LIST' },
            ]
          : [],
    }),
    sendTicketMessage: builder.mutation<ISupportItem, SendTicketMessagePayload>({
      query: ({ ticketId, ...data }) => ({
        url: `${url}/message/${ticketId}`,
        data,
        method: HTTP_METHODS.patch,
      }),
      invalidatesTags: ['CurrentTicket'],
    }),
    createSupportTicket: builder.mutation<ISupportItem, Partial<ISupportItem>>({
      query: (data) => ({
        url,
        data,
        method: HTTP_METHODS.post,
      }),
      invalidatesTags: ['Support'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllSupportTicketsQuery,
  useCreateSupportTicketMutation,
  useGetSingleSupportTicketQuery,
  useSendTicketMessageMutation,
  useUpdateTicketMutation,
} = supportApi;
