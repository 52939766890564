import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import {
  changeAvatar,
  changeProfilePassword,
  clearProfileData,
  getProfileData,
  setProfileData,
} from 'redux/profile/profile.actions';

import { IUser } from '../user/user.types';
import { IProfileState } from './profile.types';

const initialState: IProfileState = {
  data: {} as IUser,
  status: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearProfileData, (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
    });
    builder.addCase(changeProfilePassword.fulfilled, (state) => {
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(setProfileData, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(changeAvatar.fulfilled, (state, { payload }) => {
      state.data = { ...state.data, avatar: payload.avatar };
    });
    builder.addCase(getProfileData.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      state.data = payload;
    });
    builder.addMatcher(isAnyOf(changeProfilePassword.pending, getProfileData.pending), (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addMatcher(isAnyOf(changeProfilePassword.rejected, getProfileData.rejected), (state) => {
      state.status = IFetchingStatuses.error;
    });
  },
});

export default profileSlice.reducer;
