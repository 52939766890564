import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import axios from 'api';
// todo: update user
import { closeModal, resetPasswordModal, showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { clearProfileData, setProfileData } from 'redux/profile/profile.actions';

import { setCookies, clearAuthTokensFromCookies } from './auth.helpers';
import {
  CHANGE_EMPLOYEE_PASSWORD,
  CHANGE_PASSWORD,
  ChangeEmployeePasswordDto,
  ChangePasswordDto,
  LOGIN_REQUEST,
  LoginRequest,
  LoginResponse,
  LOGOUT_REQUEST,
} from './auth.types';

export const login = createAsyncThunk(LOGIN_REQUEST, (data: LoginRequest, { rejectWithValue, dispatch }) => {
  return axios.post<LoginResponse>('/auth/login', data).then(
    (response) => {
      const { access_token: accessToken, refresh_token, user: profile, isPasswordExpired } = response.data;
      setCookies({ access_token: accessToken, refresh_token });
      dispatch(showSuccessAlert('Вы успешно вошли'));
      dispatch(setProfileData(profile));
      isPasswordExpired && dispatch(resetPasswordModal(profile._id));
    },
    (error) => {
      const errorMessage = error?.response?.data?.message || 'Что-то пошло не так';
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(errorMessage);
    },
  );
});

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async (data: ChangePasswordDto, { rejectWithValue, dispatch }) => {
    try {
      await axios.patch('/auth/change-password', data).then(() => {
        dispatch(closeModal());
        toast.success('Пароль успешно изменен');
      });
    } catch (err: any) {
      toast.error(err.response?.data?.message);
      return rejectWithValue('');
    }
  },
);

export const changeEmployeePassword = createAsyncThunk(
  CHANGE_EMPLOYEE_PASSWORD,
  async (data: ChangeEmployeePasswordDto, { rejectWithValue, dispatch }) => {
    try {
      await axios.patch('/auth/change-password', data).then(() => {
        dispatch(closeModal());
        toast.success('Пароль сотрудника успешно изменен');
      });
    } catch (err: any) {
      toast.error(err.response?.data?.message);
      return rejectWithValue('');
    }
  },
);

export const logout = createAsyncThunk(LOGOUT_REQUEST, (_, { rejectWithValue, dispatch }) => {
  return axios.post('/auth/logout').then(
    () => {
      clearAuthTokensFromCookies();
      dispatch(clearProfileData());
    },
    (error) => {
      return rejectWithValue(error?.response?.data?.message);
    },
  );
});
