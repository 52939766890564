import { RawDraftContentState } from 'react-draft-wysiwyg';

import { IStatuses } from '@/types/common';
import { IClient } from 'redux/client/client.types';
import { IEvent } from 'redux/event/event.types';
import { ITopic } from 'redux/topics/topics.types';
import { IUser } from 'redux/user/user.types';

export const LOGS_GET_ALL = 'LOGS_GET_ALL';
export const LOGS_GET_CLIENTS_LOG = 'LOGS_GET_CLIENTS_LOG';

export interface ParsedLogItem {
  clarification: string;
  newValue: Date;
  oldValue: Date;
  type: string;
  updatedAt: Date;
  user: string;
}

export interface ParsedTopicLogItem {
  newValue: RawDraftContentState | string | Date | Array<any>;
  oldValue: RawDraftContentState | string | Date | Array<any>;
  type: string;
  updatedAt: string;
  user: string;
}

export interface LogItem {
  action: string;
  entity: string;
  entity_id: string;
  entityName: string;
  timestamp: string;
  user: string | Partial<IUser>;
  userName: string;
  changes?: Record<string, any>;
  values_new: IClient | IEvent | IUser | ITopic;
  values_old: IClient | IEvent | IUser | ITopic;
  _id: string;
}

export interface LogsState {
  data: LogItem[];
  status: IStatuses;
  total: number;
}
