import { IComment, IFilters, ISorting, IStatuses } from '@/types/common';
import { IEvent } from 'redux/event/event.types';
import { IOffice } from 'redux/office/office.types';
import { IUserAssignee } from 'redux/user/user.types';

export const CLIENT_GET_ALL = 'CLIENT_GET_ALL';
export const CLIENT_SET_FILTERS = 'CLIENT_SET_FILTERS';
export const BROKER_CLIENT_SET_FILTERS = 'BROKER_CLIENT_SET_FILTERS';
export const CLIENT_CLEAR_FILTERS = 'CLIENT_CLEAR_FILTERS';
export const BROKER_CLIENT_CLEAR_FILTERS = 'BROKER_CLIENT_CLEAR_FILTERS';
export const CLIENT_CLEAR_SORTING = 'CLIENT_CLEAR_SORTING';
export const CLIENT_SET_SORTING = 'CLIENT_SET_SORTING';
export const CLIENT_GET_CURRENT = 'CLIENT_GET_CURRENT';
export const CLIENT_GET_BLACKLIST = 'CLIENT_GET_BLACKLIST';
export const CLIENT_CREATE = 'CLIENT_CREATE';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_DELETE = 'CLIENT_DELETE';
export const CLEAR_CURRENT_CLIENT = 'CLEAR_CURRENT_CLIENT';
export const CLIENT_GET_BROKER_CLIENTS = 'CLIENT_GET_BROKER_CLIENTS';
export const CLIENT_BROKER_CLIENTS_SET_SORTING = 'CLIENT_BROKER_CLIENTS_SET_SORTING';
export const CLIENT_BROKER_CLIENTS_CLEAR_SORTING = 'CLIENT_BROKER_CLIENTS_CLEAR_SORTING';

export const CLIENT_SET_FAILED_EVENT = 'CLIENT_SET_FAILED_EVENT';

export interface IClientStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface IBaseClient {
  _id: string;
  name: string;
  surname: string;
  patronymic: string;
}

export interface IClientPotential {
  min: number;
  max: number;
}
export interface IClient extends IBaseClient {
  _id: string;
  citizenship: string;
  mobile: string;
  email: string;
  gender: string;
  info: string;
  status: string;
  potential: IClientPotential;
  comments: Array<IComment>;
  brokerClientId: string;
  tags: string[];
  office: IOffice;
  assignee: IUserAssignee | string;
  list_assignees?: Array<string>;
  analytic: Partial<IUserAssignee>;
  notepad: string;
  notification: string;
  deleted: boolean;
  registered_by: IUserAssignee | string;
  createdAt: Date;
  updatedAt: Date;
  advertiseSource: string;
}

export interface IClientState {
  data: Array<IClient>;
  status: IStatuses;
  totalClients: number;
  totalBrokerClients: number;
  currentClient: IClient | null;
  brokerClients: Array<IBrokerClient>;
  blacklist: Array<IClient>;
  filters: IFilters;
  brokerClientFilters: IFilters;
  sort: ISorting;
  sortBrokerClients: ISorting;
  failedEvent: string;
}

export interface IBrokerClient extends Partial<IClient> {
  totalProfit: number;
  totalSwap: number;
  totalCommission: number;
}

export interface ILostClientStatisticItem extends IClient {
  events: Array<IEvent>;
}
export interface ILostClientsResponse {
  result: Array<ILostClientStatisticItem>;
  total: number;
}

export interface IClientsResponse {
  result: Array<IClient>;
  total: number;
}

export interface IUpdateClientActionPayload {
  isShowToast?: boolean;
  client: Partial<IClient>;
}

export interface IUpdateClientsEvents {
  assignee: string;
  clientsIds: string[];
}

export const CLIENT_FILTERS = 'CLIENT_FILTERS';
export const BROKER_CLIENT_FILTERS = 'BROKER_CLIENT_FILTERS';
