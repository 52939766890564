import * as types from './management.types';

const initialState = {
  createUser: {},
  currentEmployer: undefined,
};

const managementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MANAGEMENT_COLLECT_NEW_MEMBER_DATA: {
      const personalData = { ...state.createUser.personalData, ...action.payload.personalData };
      const userData = { ...state.createUser, ...action.payload };
      userData.personalData = personalData;

      return {
        ...state,
        createUser: { ...userData },
      };
    }
    case types.MANAGEMENT_GET_EMPLOYER_DATA_SUCCESS: {
      return {
        ...state,
        currentEmployer: action.payload,
      };
    }
    case types.MANAGEMENT_CLEAR_EMPLOYER_DATA: {
      return {
        ...state,
        currentEmployer: initialState.currentEmployer,
      };
    }
    case types.MANAGEMENT_UPDATE_CURRENT_EMPLOYER: {
      return {
        ...state,
        currentEmployer: { ...state.currentEmployer, ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
export default managementReducer;
