import { createSlice } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { getNotifications } from 'redux/notifications/notifications.actions';
import { INotification, NotificationsState } from 'redux/notifications/notifications.types';

const initialState: NotificationsState = {
  unreadNotifications: [],
  readNotifications: [],
  unreadCount: 0,
  readCount: 0,
  status: IFetchingStatuses.pending,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadNotifications: (state, { payload }) => {
      state.unreadNotifications = payload;
      state.unreadCount = payload.length;
    },
    setReadNotifications: (state, { payload }) => {
      state.readNotifications = payload;
      state.readCount = payload.length;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      if (!payload.result) {
        state.unreadNotifications = payload.unreadNotifications as Array<INotification>;
        state.readNotifications = payload.readNotifications as Array<INotification>;
        state.unreadCount = payload.unreadCount as number;
        state.readCount = payload.readCount as number;
      } else {
        if (payload.type === 'read') {
          state.readNotifications = [...state.readNotifications, ...payload.result];
        } else {
          state.unreadNotifications = [...state.unreadNotifications, ...payload.result];
        }
      }
    });
    builder.addCase(getNotifications.pending, (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.status = IFetchingStatuses.error;
    });
  },
});

export const { setUnreadNotifications, setReadNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
