import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& > ul': {
      '& > li': {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
  },
}));
