import axios from 'api';
import { CreateEventRequest } from 'redux/event/event.types';

const url = '/api/events';

const EventAPI = {
  getAll: (params: Record<string, any>) => axios.get(url, { params }),
  getOne: (id: string) => axios.get(`${url}/${id}`),
  create: (data: CreateEventRequest) => axios.post(url, data),
  update: (data: Partial<CreateEventRequest>) => axios.patch(`${url}/${data?.event?._id}`, data),
  delete: (id: string) => axios.delete(`${url}/${id}`),
};

export default EventAPI;
