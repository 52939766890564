import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { IStorageItemData } from '@/utils/fileStorage';
import { showErrorToast, showSuccessToast } from '@/utils/notification';
import { compareRatingFileUpdate } from '@/utils/utils';
import { ProfileAvatar } from 'components/common/ProfileAvatar';
import { Spinner } from 'components/common/Spinner/Spinner';
import StatusBadge from 'components/common/StatusBadge/StatusBadge';
import AttachmentsView from 'components/Topics/AttachmentsView';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { ModalActionTypes } from 'redux/app/app.types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { avatarSelector } from 'redux/profile/profile.selectors';
import { useCreateRatingFileMutation, useUpdateRatingFileMutation } from 'redux/ratings/rating.service';
import { CreateRatingFileDto, RatingFile } from 'redux/ratings/rating.types';

interface CreateRatingItemProps extends RatingFile {
  action: ModalActionTypes;
}

export const CreateRatingItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector<CreateRatingItemProps>(modalDataSelector);
  const [values, setValues] = useState({
    target: data.target || '',
    name: data.name || '',
    description: data.description || '',
  });
  const avatar = useAppSelector(avatarSelector);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState<Array<string>>([]);
  const [isAttachmentsLoading, setIsAttachmentsIsLoading] = useState(false);
  const [attachments, setAttachments] = useState<Array<IStorageItemData>>(data?.attachment ? [data.attachment] : []);
  const [preview, setPreview] = useState(false);

  const [create, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateRatingFileMutation();
  const [update, { isLoading, isSuccess, isError }] = useUpdateRatingFileMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const targetInputLabelText = 'Количество продаж $';
  const previewUrl = attachments[0]?.preview || '';
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const payload: CreateRatingFileDto = {
      ...values,
      attachment: attachments[0],
      target: +values.target,
    };
    if (data.action === ModalActionTypes.CREATE) {
      create(payload);
    } else {
      const objectDiff = compareRatingFileUpdate(data, payload);
      if (!Object.keys(objectDiff).length) {
        showErrorToast('Нет изменений');
      } else {
        update({ ...objectDiff, _id: data._id });
      }
    }
  };

  useEffect(() => {
    if (isCreateSuccess || isSuccess) {
      showSuccessToast('Успешно создано');
      dispatch(closeModal());
    } else if (isCreateError || isError) {
      showErrorToast('Возникла ошибка');
    }
  }, [isCreateSuccess, isCreateError, isSuccess, isError, dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <Box display={'flex'} flexDirection={'column'} gridGap={10}>
        <TextField
          label={'Имя'}
          color={'primary'}
          size={'small'}
          value={values.name}
          fullWidth
          name={'name'}
          onChange={handleChange}
          variant={'outlined'}
        />
        <TextField
          required={true}
          color={'primary'}
          label={targetInputLabelText}
          onChange={handleChange}
          variant={'outlined'}
          fullWidth
          size={'small'}
          name="target"
          value={values.target}
          type={'number'}
        />
        <TextField
          required={true}
          color={'primary'}
          multiline
          minRows={2}
          label={'Описание'}
          onChange={handleChange}
          variant={'outlined'}
          fullWidth
          size={'small'}
          name="description"
          value={values.description}
        />
        <Box maxHeight={250}>
          <>
            {isAttachmentsLoading && <Spinner />}
            {!preview ? (
              <AttachmentsView
                setIsLoading={setIsAttachmentsIsLoading}
                entityId={undefined}
                entityName={'support'}
                attachments={attachments}
                setAttachmentsToRemove={setAttachmentsToRemove}
                setAttachments={setAttachments}
              />
            ) : (
              <ProfileAvatar size={75} imgUrl={avatar}>
                <StatusBadge url={previewUrl} />
              </ProfileAvatar>
            )}
          </>
        </Box>
        <Box paddingY={1} justifyContent={'flex-end'} display={'flex'} gridGap={10}>
          <Button
            disabled={!previewUrl}
            onClick={() => setPreview((preview) => !preview)}
            color={'secondary'}
            variant={'outlined'}
            size={'small'}
          >
            {preview ? 'Вернуть' : 'Превью'}
          </Button>
          <Button disabled={isCreateLoading || isLoading} type={'submit'} variant={'contained'} size={'small'} color={'primary'}>
            {data.action === ModalActionTypes.CREATE ? 'Создать' : 'Изменить'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
