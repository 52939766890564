import { IStorageItemData } from '@/utils/fileStorage';
import { IOffice } from 'redux/office/office.types';
import { IBaseUser, IUser, IUserAssignee } from 'redux/user/user.types';

export enum SupportStatus {
  CREATED = 'создан',
  PROCESS = 'в процессе',
  COMPLETED = 'завершен',
  REJECTED = 'отменен',
  AWAIT = 'в ожидании',
}
export enum SupportSubject {
  QUESTION = 'вопрос',
  REPORT = 'ошибка',
}

export const supportSubjectTranslation: Record<string, string> = {
  QUESTION: 'Вопрос',
  REPORT: 'Ошибка',
};
export const supportSubjectOptions = Object.keys(SupportSubject).map((key) => ({
  label: supportSubjectTranslation[key],
  value: SupportSubject[key as keyof typeof SupportSubject],
}));

export const supportThemeValues = ['Событие', 'Клиент', 'Задача', 'Другое'];

export interface ISupportMessage {
  userId: IUser;
  message: string;
  createdAt: Date;
  attachments: Array<IStorageItemData>;
}
export type SendTicketMessagePayload = ISupportMessage & { ticketId: string };

export type GetAllTicketsResponse = { items: Array<ISupportItem>; total: number };

export interface ISupportItem {
  _id: string;
  status: SupportStatus;
  createdAt: Date;
  theme: string;
  officeId: Partial<IOffice>;
  updatedAt: Date;
  userId: IUser;
  subject: SupportSubject;
  description: string;
  logValues: Array<any>;
  errorValues: Array<any>;
  router: string;
  attachments: Array<IStorageItemData>;
  messages: Array<ISupportMessage>;
}
export interface SupportFiltersTypes {
  subject: string;
  theme: string;
  userId: string;
  officeId: string;
  status: string;
  deadlineStart: Date | null;
  deadlineEnd: Date | null;
}
