import { FC, useEffect, useState } from 'react';

import { CircularProgress, Backdrop, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backdropFilter: 'blur(1px)',
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  spinner: {
    zIndex: 10001,
  },
}));

export const Spinner: FC<{ title?: string }> = ({ title }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    setOpen(true);
    return () => {
      window.document.body.style.overflow = 'auto';
      setOpen(false);
    };
  }, []);

  return (
    <Backdrop open={open} className={classes.root}>
      <CircularProgress className={classes.spinner} />
      {title && <Typography>{title}</Typography>}
    </Backdrop>
  );
};
