import { HTTP_METHODS } from '@/types/common';
import { IClients } from 'components/common/ClientsList/ClientsList';
import { IClient, ILostClientsResponse, IUpdateClientsEvents } from 'redux/client/client.types';
import { commonApi } from 'redux/commonApi';

const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['EventsClients', 'LostClients'] });
const clientUrl = 'api/clients';

const clientApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    updateClientsEvents: builder.mutation<IClient, IUpdateClientsEvents>({
      query: (data) => ({
        url: `${clientUrl}/events/update-assignee`,
        method: HTTP_METHODS.patch,
        data,
      }),
    }),
    getAllClientsWithEvents: builder.query<Array<IClients>, string>({
      query: (id: string) => ({
        url: `${clientUrl}/events/all`,
        method: HTTP_METHODS.get,
        params: { id: id },
      }),
      providesTags: (res) => {
        return res && res && res.length
          ? [
              ...res.map(({ _id }) => ({
                type: 'EventsClients' as const,
                id: _id,
              })),
              'EventsClients',
            ]
          : ['EventsClients'];
      },
    }),
    getLostClients: builder.query<ILostClientsResponse, Record<string, unknown>>({
      query: (params) => ({
        url: `${clientUrl}/statistics/lost-clients`,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: (response) => {
        return response && response.result
          ? [...response.result.map(({ _id }) => ({ type: 'LostClients' as const, id: _id })), 'LostClients']
          : ['LostClients'];
      },
      transformResponse: (response: ILostClientsResponse) => {
        const result = response.result.length
          ? response.result.map((client) => ({
              ...client,
              events: client.events.length
                ? client.events.sort((a, b) => {
                    if (a.deadline > b.deadline) {
                      return -1;
                    } else if (a.deadline < b.deadline) {
                      return 1;
                    } else return 0;
                  })
                : client.events,
            }))
          : response.result;
        return {
          result,
          total: response.total,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetLostClientsQuery, useGetAllClientsWithEventsQuery, useUpdateClientsEventsMutation } = clientApi;
