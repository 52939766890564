import { makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

export const sidebarStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    transform: 'translateX(-100%)',
    bottom: 0,
    top: 60,
    width: 96,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.5)',
    transition: 'all 0.2s ease',
    zIndex: 120,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 50,
      transform: 'translateX(0)',
    },
  },
  open: {
    transform: 'translateX(0)',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 120,
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    padding: 0,
    backgroundColor: lighten(theme.palette.background.default, 0.08),
  },
  sidebarPopover: {
    paddingLeft: '8px',
  },
  nestedLinkActive: {
    background: 'rgba(255, 255, 255, 0.18)',
  },
}));
