import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import TopicsAPI from '@/api/topics';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import {
  IComment,
  COMMENT_CREATE,
  COMMENT_DELETE,
  COMMENT_UPDATE,
  GetTopicsRequest,
  ITopic,
  TOPIC_CREATE,
  TOPIC_DELETE,
  TOPIC_GET_CURRENT,
  TOPIC_UPDATE,
  TOPICS_GET_ALL,
  GetTopicRequest,
  COMMENT_GET_NESTED,
  COMMENTS_GET_CURRENT,
  GetCommentsRequest,
  CreateCommentResponse,
  UpdateTopicRequest,
  TOPICS_SEND_TOPIC_REMIND,
} from 'redux/topics/topics.types';

// оставил все в одном месте потому что сущность комментариев отдельно от сущности топиков не существует

export const getTopics = createAsyncThunk(
  TOPICS_GET_ALL,
  async (params: Record<string, string | number>, { rejectWithValue }) => {
    return TopicsAPI.getAll(params)
      .then((response: AxiosResponse<GetTopicsRequest>): GetTopicsRequest => response.data)
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);

export const getTopic = createAsyncThunk(TOPIC_GET_CURRENT, async (id: string, { rejectWithValue, dispatch }) => {
  return TopicsAPI.getOne(id)
    .then((response: AxiosResponse<GetTopicRequest>): GetTopicRequest => response.data)
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const createTopic = createAsyncThunk(TOPIC_CREATE, async (topic: Partial<ITopic>, { dispatch, rejectWithValue }) => {
  return TopicsAPI.create(topic)
    .then((response: AxiosResponse<ITopic>): ITopic => {
      dispatch(showSuccessAlert('Топик успешно создан'));
      return response.data;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const updateTopic = createAsyncThunk(TOPIC_UPDATE, async (topic: Partial<ITopic>, { rejectWithValue, dispatch }) => {
  return TopicsAPI.update(topic)
    .then((response: AxiosResponse<UpdateTopicRequest>): UpdateTopicRequest => {
      dispatch(showSuccessAlert('Топик успешно обновлен!'));
      return response.data;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const deleteTopic = createAsyncThunk(TOPIC_DELETE, async (id: string, { rejectWithValue, dispatch }) => {
  return TopicsAPI.delete(id)
    .then(() => {
      dispatch(showSuccessAlert('Топик удален!'));
      return id;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const createComment = createAsyncThunk(
  COMMENT_CREATE,
  async (comment: Partial<IComment>, { rejectWithValue, dispatch }) => {
    return TopicsAPI.createComment(comment)
      .then((response: AxiosResponse<CreateCommentResponse>): CreateCommentResponse => {
        dispatch(showSuccessAlert('Комментарий успешно добавлен'));
        return response.data;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const getTopicComments = createAsyncThunk(
  COMMENTS_GET_CURRENT,
  async (params: Record<string, string | number>, { rejectWithValue }) => {
    const { id, ...queryParams } = params;
    return TopicsAPI.getTopicComments(id as string, queryParams)
      .then((response: AxiosResponse<GetCommentsRequest>): GetCommentsRequest => response.data)
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);

export const getNestedComments = createAsyncThunk(COMMENT_GET_NESTED, async (id: string, { rejectWithValue }) => {
  return TopicsAPI.getNestedComments(id)
    .then((response: AxiosResponse<Array<IComment>>): Array<IComment> => {
      return response.data;
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const updateComment = createAsyncThunk(
  COMMENT_UPDATE,
  async (comment: Partial<IComment>, { rejectWithValue, dispatch }) => {
    return TopicsAPI.updateComment(comment)
      .then((response: AxiosResponse<IComment>): IComment => {
        dispatch(showSuccessAlert('Комментарий успешно изменен'));
        return response.data;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const deleteComment = createAsyncThunk(COMMENT_DELETE, async (id: string, { rejectWithValue, dispatch }) => {
  return TopicsAPI.deleteComment(id)
    .then(() => {
      dispatch(showSuccessAlert('Комментарий удален!'));
      return id;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const sendTopicRemindNotifications = createAsyncThunk(
  TOPICS_SEND_TOPIC_REMIND,
  async (id: string, { rejectWithValue, dispatch }) => {
    return TopicsAPI.sendTopicRemindNotifications(id)
      .then((res) => {
        dispatch(showSuccessAlert('Уведомления успешно отправлены'));
        return res.data;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);
