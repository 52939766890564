import { createSlice } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { getClientLog, getLogs } from 'redux/log/log.actions';

import { LogsState } from './log.types';

const initialState: LogsState = {
  data: [],
  status: null,
  total: 0,
};

const logSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = [];
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLogs.fulfilled, (state, { payload }) => {
      state.data = payload.reverse();
    });
    builder.addCase(getLogs.rejected, (state) => {
      state.status = IFetchingStatuses.error;
    });
    builder.addCase(getLogs.pending, (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addCase(getClientLog.fulfilled, (state, { payload }) => {
      state.data = payload.result;
      state.total = payload.total;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(getClientLog.rejected, (state) => {
      state.status = IFetchingStatuses.error;
    });
    builder.addCase(getClientLog.pending, (state) => {
      state.status = IFetchingStatuses.pending;
    });
  },
});

export const { clearState } = logSlice.actions;

export default logSlice.reducer;
