import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '15px',
  },
  icon: {
    color: '#C6C8D6',
    cursor: 'pointer',
    transition: '0.3s color ease',
    '&:hover': {
      transition: '0.3s color ease',
      color: darken('#C6C8D6', 0.2),
    },
  },
  notifications: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: '350px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    gap: 10,
    marginTop: '40px',
    '& > div': {
      padding: '10px',
      border: '1px #C6C8D6 solid',
      cursor: 'pointer',
      transition: 'all 0.25s ease',
      '&:hover': {
        transition: 'all 0.25s ease',
        background: darken(theme.palette.background.default, 0.2),
      },
    },
    '& > div:first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '& > div:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  notificationsContainer: {
    display: 'flex',
    height: 'calc(100vh - 125px)',
    flexDirection: 'column',
    gap: '1rem',
    padding: '10px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#4b49ac',
      outline: '1px solid #4b49ac',
    },
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontWeight: 700,
    fontSize: '1rem',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
}));
