import { lighten } from '@material-ui/core';
import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  attachmentContainer: {
    display: 'flex',
    width: '80%',
    maxHeight: '210px',
    overflowY: 'auto',
    flexWrap: 'wrap',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  attachmentPreview: {
    position: 'relative',
    width: '100px',
    height: '100px',
    // [theme.breakpoints.down('sm')]: {
    //   width: '48%',
    //   height: 'auto',
    // },
  },
  attachmentImage: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  removeAttachmentButton: {
    position: 'absolute',
    borderRadius: '50px',
    right: 4,
    top: 4,
    cursor: 'pointer',
    background: darken(theme.palette.background.default, 0.1),
    '&:hover': {
      background: lighten(theme.palette.background.default, 0.1),
    },
    '& > svg': {
      height: '12px',
      width: '18px',
    },
  },
  progressBar: {
    width: '100%',
    height: '7px',
    borderRadius: '5px',
    margin: 'auto 0',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    minWidth: '200px',
    height: '100%',
    maxHeight: '200px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    borderRadius: '5px',
    border: '1px white solid',
    cursor: 'pointer',
    transition: 'all 0.25s ease',
    '&:hover': {
      transition: 'all 0.25s ease',
      background: darken(theme.palette.background.default, 0.1),
    },
    '& > svg': {
      width: '70px',
      height: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
