import { IFilters, IStatuses } from '@/types/common';
import { IStorageItemData } from '@/utils/fileStorage';
import { IOffice } from 'redux/office/office.types';

export const USER_GET_ALL = 'USER_GET_ALL';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_GET_ONE = 'USER_GET_ONE';
export const USER_SET_FILTERS = 'USER_SET_FILTERS';
export const USER_CLEAR_FILTERS = 'USER_CLEAR_FILTERS';

export interface IPersonalData {
  name: string;
  surname: string;
  nickname: string;
  patronymic: string;
  passportSeries: string;
  passportNumber: string;
  passportAdditional: string;
  passportDate: Date | null;
  documentScan: IStorageItemData[];
  birthday: Date | null;
  gender: string;
}

export interface IUserIndicators {
  period: string;
  plan: number;
  done: number;
  totalEarned: number;
  efficiency: number;
  ratingPlace: number;
}

export enum IUserRoles {
  applicant = 'applicant',
  trainee = 'trainee',
  manager = 'manager',
  seniorManager = 'seniorManager',
  topManager = 'topManager',
  salesHeadManager = 'salesHeadManager',
  regionalDirector = 'regionalDirector',
  groupOfRegionDirector = 'groupOfRegionDirector',
  developmentDirectorDeputy = 'developmentDirectorDeputy',
  developmentDirector = 'developmentDirector',
  admin = 'admin',
  accountant = 'accountant',
  attractionManager = 'attractionManager',
  attractionHeadManager = 'attractionHeadManager',
  HR = 'HR',
  operator = 'operator',
  headOfAttractionDepartment = 'headOfAttractionDepartment',

  analytic = 'analytic',
}

export interface IUserAssignee {
  _id: string;
  name: string;
  surname: string;
  patronymic: string;
  role: string;
  nickname?: string;
  avatar: string;
}

export interface IBaseUser {
  mobile: string;
  password: string;
  email: string;
  mtLoginNumber: string;
  personalData: IPersonalData;
  role: IUserRoles;
  status: IOffice | string;
  office: IOffice;
  date_registered: Date | null;
  registered_by: IUserAssignee;
}

export interface IUser extends IBaseUser {
  _id: string;
  password: string;
  avatar: string;
  indicators: Array<IUserIndicators>;
  statistics: any;
  date_retired: Date;
  fired: boolean;
  isDeleted: boolean;
  region: string;
  regionGroup: string;
  allowedOfficeIds: Array<string>;
  rating?: Record<string, any>;
}

export interface IUserState {
  data: Array<IUser>;
  status: IStatuses;
  currentUser: IUser | null;
  totalUsers: number;
  filters: IFilters;
}

export interface IUserResponse {
  result: IUser[];
  totalCount: number;
}

export interface ITableDataUser extends IUser {
  fullName: string;
  roleTitle: string;
  dateRegistered: string;
}
