import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    padding: '15px',
  },
  date: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    fontFamily: 'inherit',
    transition: 'all 0.25s ease',
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      transition: 'all 0.25s ease',
      color: darken('#C6C8D6', 0.2),
    },
  },
}));
