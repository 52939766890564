import { IComment } from '@/types/common';

export const SHOW_APP_MODAL = 'SHOW_APP_MODAL';
export const CLOSE_APP_MODAL = 'CLOSE_APP_MODAL';

export enum ModalTypes {
  modal = 'modal',
}

// Roles
export const APP_GET_ROLES = 'APP_GET_ROLES';

// Alert
export const SHOW_ALERT_NOTIFICATION = 'SHOW_ALERT_NOTIFICATION';
export const CLOSE_ALERT_NOTIFICATION = 'CLOSE_ALERT_NOTIFICATION';

export enum AlertNotifyTypes {
  success = 'success',
  error = 'error',
}

export type ModalSizes = 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
export interface IModalProps {
  showModal: boolean;
  modalName: string | null;
  modalType: string | null;
  title?: string;
  size?: ModalSizes;
  modalData: Record<string, unknown> | null;
}

export interface AppState {
  modal: IModalProps;
  alertNotify: {
    show: boolean;
    type: AlertNotifyTypes | undefined;
    message: string | undefined;
  };
  roles: Record<string, string>;
}

export interface ShowModalProps {
  title?: string;
  size?: ModalSizes;
  name: string;
  type: string;
  data?: any;
}

export interface ICreateCommentModal {
  comments: Array<IComment>;
  userId: string;
  eventId: string;
  edit: boolean;
}

export enum ModalActionTypes {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface IBaseModalProps {}
