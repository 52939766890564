import { IStorageItemData } from '@/utils/fileStorage';
import { IOffice } from 'redux/office/office.types';
import { IUserRoles } from 'redux/user/user.types';

export interface IRatingItem {
  _id: string;
  assignee: {
    name: string;
    surname: string;
    role: IUserRoles;
    date_registered: string;
    nickname?: string;
    avatar?: string;
    _id: string;
  };
  amount: number;
  officeId: IOffice;
}

export interface IGroupedRatingItem {
  avatar: string;
  period: string;
  title: string;
  subtitle: string;
  info: string;
  confirmedDeposit: string | number;
  amount: number;
  itemId: string;
  officeGroup: string;
  officeRegion: string;
  userRole: IUserRoles;
}

export type RatingState = { data: Array<IRatingItem> };

export type GroupRatingBy = 'user' | 'office';

export enum RatesTypes {
  PLACE = 'place',
  SALES = 'sales',
  START = 'start',
}

export interface RatingFile {
  _id: string;
  type: RatesTypes;
  target: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  attachment: IStorageItemData;
  isActive: boolean;
  description: string;
}
export type CreateRatingFileDto = Pick<RatingFile, 'target' | 'attachment' | 'description' | 'name'>;
