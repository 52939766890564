import { createSlice } from '@reduxjs/toolkit';

import {
  clearLostClientsFilters,
  clearStatisticsFilters,
  setLostClientsFilters,
  setStatisticsFilters,
} from 'redux/statistic/statistic.actions';

import { ParsedEventStatisticItem, StatisticState } from './statistic.types';

const initialState: StatisticState = {
  data: [],
  currentUserStatistic: {} as ParsedEventStatisticItem,
  status: null,
  filters: null,
  clientsFilters: {},
};

const statisticSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setStatisticsFilters, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(clearStatisticsFilters, (state) => {
      state.filters = initialState.filters;
    });
    builder.addCase(setLostClientsFilters, (state, { payload }) => {
      state.clientsFilters = payload;
    });
    builder.addCase(clearLostClientsFilters, (state) => {
      state.clientsFilters = initialState.filters;
    });
  },
});

export default statisticSlice.reducer;
