import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: 600,
    overflow: 'auto',
  },
  filterWrapper: {
    width: '100%',
    margin: '10px 0',
    cursor: 'pointer',
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    gap: 10,
    justifyContent: 'space-between',
  },
}));

export default useStyles;
