import React, { createRef, FC, SetStateAction } from 'react';

import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import { IStorageItemData, removeFileFromCloudSpace, removeUserDoc, uploadFiles } from '@/utils/fileStorage';
import { useStyles } from 'components/Topics/AttachmentsView/AttachmentsView.styles';

interface IAttachmentsViewProps {
  entityName: string;
  entityId?: string;
  attachments: Array<IStorageItemData>;
  setIsLoading: (value: SetStateAction<boolean>) => void;
  setAttachments: (value: SetStateAction<Array<IStorageItemData>>) => void;
  setAttachmentsToRemove: (value: SetStateAction<Array<string>>) => void;
  isUploadUserDocs?: boolean;
}

const AttachmentsView: FC<IAttachmentsViewProps> = ({
  attachments,
  setAttachments,
  setIsLoading,
  entityId,
  setAttachmentsToRemove,
  entityName,
  isUploadUserDocs,
}) => {
  const classes = useStyles();
  const imageInput = createRef<HTMLInputElement>();

  const openFileInput = (e: React.MouseEvent): void => {
    e.preventDefault();
    imageInput?.current?.click();
  };

  // если топик еще не создан, то удаляем аттачмент из временной папки сразу
  // если создан, то сначала кидаем аттачмент во временный массив, и удаляем в стейте
  // а из топика и спейса удаляем только если юзер нажмет кнопку сохранить
  const removeAttachment = (key: string): void => {
    if (!entityId) {
      removeFileFromCloudSpace(key).then(() => {
        setAttachments([...attachments.filter((attachment) => attachment.Key !== key)]);
      });
    } else {
      if (isUploadUserDocs) {
        removeUserDoc(key, entityId);
      }
      setAttachments([...attachments.filter((attachment) => attachment.Key !== key)]);
      setAttachmentsToRemove((prevState) => [...prevState, key]);
    }
  };

  const handleInputChange = (): void => {
    const files = imageInput?.current?.files;

    if (!files?.length) {
      return;
    }
    const fileList = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file.type.startsWith('image/')) {
        // тут я драгндроп хотел сделать, но не сделал
        continue;
      }
      fileList.push(file);
    }
    setIsLoading(true);
    uploadFiles(fileList, entityId ? `${entityName}/${entityId}` : 'temp')
      .then((result) => {
        if (isUploadUserDocs) {
          setAttachments([...result]);
        } else {
          setAttachments([...attachments, ...result]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const decodeImage = (data: string) => {
    return `data:image/jpeg;base64,${data}`;
  };
  return (
    <div className={classes.root}>
      <div className={classes.attachmentContainer}>
        {attachments.length > 0 &&
          attachments.map((attachment) => (
            <div className={classes.attachmentPreview} key={attachment.Key}>
              <div className={classes.removeAttachmentButton} onClick={() => removeAttachment(attachment.Key)}>
                <ClearIcon />
              </div>
              <img
                className={classes.attachmentImage}
                src={isUploadUserDocs ? decodeImage(attachment.preview) : attachment.preview}
                alt={attachment.Key}
              />
            </div>
          ))}
      </div>
      <div className={classes.addButton} onClick={openFileInput}>
        <AddIcon />
        <Typography variant={'body2'}>Добавить изображения</Typography>
      </div>
      <input
        type={'file'}
        multiple
        style={{ display: 'none' }}
        ref={imageInput}
        accept={'image/*'}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default AttachmentsView;
