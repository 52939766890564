import { UploadAvatarRequest } from 'redux/profile/profile.types';
import { IUser } from 'redux/user/user.types';

import storageAPI from '../api/upload';

export const uploadFiles = async (data: Array<File>, path: string): Promise<Array<IStorageItemData>> => {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append('files', file);
  });
  return storageAPI.uploadFiles(formData, path);
};

export const uploadAvatar = async (data: UploadAvatarRequest): Promise<IUser> => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('id', data._id);
  return storageAPI.uploadAvatar(formData);
};

export const removeUserDoc = async (fileKey: string, id: string) => {
  return storageAPI.deleteUserDoc(fileKey, id);
};

export const removeFileFromCloudSpace = async (fileKey: string): Promise<unknown> => {
  return storageAPI.deleteFile(fileKey);
};

export interface IStorageItemData {
  Bucket: string;
  ETag: string;
  Key: string;
  Location: string;
  preview: string;
}

export enum UploadFolders {
  TOPICS = 'topics',
}
