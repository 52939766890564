import React, { useEffect, useState } from 'react';

import { Grid, Button, TextField, Box, CircularProgress, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { da } from 'date-fns/locale';
import { useRouter } from 'next/router';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';

import { BaseModalProps, IUserAutocompleteOptionItem, OptionItem } from '@/types/common';
import { CLIENT_PAGE_PATH } from '@/utils/constants';
import { getListAssignees } from '@/utils/helper';
import { _officeListForClientAutocomplete, _userListForClientAutocomplete } from '@/utils/selectVariants';
import { getUserDataForActionModals } from '@/utils/utils';
import { ANALYTIC } from '@/variables/management';
import { closeModal, showConfirmationModal, showModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { postClient, updateClient } from 'redux/client/client.actions';
import { clientLoaderStatusSelector } from 'redux/client/client.selectors';
import { IClient } from 'redux/client/client.types';
import { useAppSelector } from 'redux/hooks';
import { canAssignClientAnalytic, viewingAccessByRole } from 'redux/managment/management.selectors';
import { getOfficeListByRole } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';
import { isHeadOfAttractionDepartmentRole, profileSelector } from 'redux/profile/profile.selectors';
import { userListSelector } from 'redux/user/user.selectors';
import { useGetUsersByRoleQuery } from 'redux/user/user.service';
import { IUserAssignee, IUserRoles } from 'redux/user/user.types';

const CreateClient = (props: BaseModalProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useAppSelector(clientLoaderStatusSelector);
  const modalData = useAppSelector<{ clientData: IClient }>(modalDataSelector);
  const isHeadOfAttractionDepartment = useAppSelector(isHeadOfAttractionDepartmentRole);
  const assignAnalyticAccess = useAppSelector(canAssignClientAnalytic);
  const clientData = modalData ? modalData.clientData : null;
  const profile = useAppSelector(profileSelector);
  const { _id: currentUserId, office: currentOffice } = profile;
  const usersInOfficeListData = useAppSelector(userListSelector);
  const officeListData = useAppSelector(getOfficeListByRole);
  const { isAllowCreateBrokerId } = useAppSelector(viewingAccessByRole);

  const { data: managersList } = useGetUsersByRoleQuery(IUserRoles.manager, { skip: !isHeadOfAttractionDepartment });
  const defaultValues: any = {
    office: clientData?.office || currentOffice,
    assignee: clientData?.assignee || getUserDataForActionModals(profile),
    analytic: clientData?.analytic || '',
    surname: clientData?.surname || '',
    name: clientData?.name || '',
    patronymic: clientData?.patronymic || '',
    brokerClientId: clientData?.brokerClientId || null,
    mobile: clientData?.mobile || '',
    email: clientData?.email || '',
    gender: clientData?.gender || '',
    advertiseSource: clientData?.advertiseSource || 0, // todo: ads to BE client model
    info: clientData?.info || '',
  };
  const { register, errors, setValue, handleSubmit, control } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'all',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  console.log({ isAllowCreateBrokerId });
  const initialData: any = {
    office: '',
    assignee: '',
    surname: '',
    name: '',
    patronymic: '',
    mobile: '',
    email: '',
    gender: '',
    analytic: '',
    advertiseSource: 0,
    info: '',
  };
  const { genderTypes, advertiseSource } = props;
  const [data, setData] = useState(initialData);
  useEffect(() => {
    setData({
      ...data,
      office: clientData?.office || currentOffice,
      assignee: clientData?.assignee || getUserDataForActionModals(profile),
      analytic: clientData?.analytic || '',
      surname: clientData?.surname || '',
      name: clientData?.name || '',
      patronymic: clientData?.patronymic || '',
      mobile: clientData?.mobile || '',
      brokerClientId: clientData?.brokerClientId || null,
      email: clientData?.email || '',
      gender: clientData?.gender || '',
      advertiseSource: clientData?.advertiseSource || 0,
      info: clientData?.info || '',
    });
  }, [clientData]);
  const offices = _officeListForClientAutocomplete(officeListData);
  const responsePersons = _userListForClientAutocomplete(usersInOfficeListData);
  const hasAnalyticsRole = responsePersons.filter((user) => user.value.role === ANALYTIC);

  if (managersList && isHeadOfAttractionDepartment) {
    const managers = _userListForClientAutocomplete(managersList);
    responsePersons.push(...managers);
  }

  function changeHandler<T>(e: React.ChangeEvent<{}>, option: OptionItem<T> | null) {
    if (!option?.type) return;
    if ((option.value as IUserAssignee)?.role === ANALYTIC) {
      setData({ ...data, ['analytic']: option.value });
      setValue('analytic', option.value);
    } else {
      setData({ ...data, [option.type]: option.value });
      setValue(option.type, option.value);
    }
  }

  const handleDeleteBrokerId = () => {
    if (clientData?.brokerClientId) {
      dispatch(showConfirmationModal({ id: clientData?.brokerClientId, type: 'deleteBrokerId', data: clientData }));
    }
  };

  const submit = (data: Partial<IClient>) => {
    const formattedData = {
      ...data,
      name: data.name?.trim(),
      surname: data.surname?.trim(),
      list_assignees: getListAssignees(data, clientData),
    };
    if (!!clientData) {
      formattedData._id = clientData._id;
      formattedData.updatedAt = new Date();
      if (router.pathname === CLIENT_PAGE_PATH) {
        dispatch(updateClient({ client: formattedData, isShowToast: true }));
      } else {
        dispatch(updateClient({ client: formattedData, isShowToast: true }));
      }
      dispatch(closeModal());
    } else {
      formattedData.createdAt = new Date();
      dispatch(postClient(formattedData));
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="office"
            size={'small'}
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Autocomplete
                {...props}
                size={'small'}
                options={offices}
                getOptionLabel={(option) => (option ? option.title : '')}
                getOptionSelected={(option) => option.value._id === data[option.type]._id}
                onChange={(e, newValue: OptionItem<IOffice> | null) => {
                  newValue && changeHandler(e, newValue);
                }}
                value={offices.find((office) => office.value._id === data.office._id) || null}
                renderInput={(params) => (
                  <TextField {...params} required label="Офис" error={!!errors.office} variant="outlined" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="assignee"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Autocomplete
                {...props}
                size={'small'}
                options={responsePersons.filter((user) => user.value.role !== ANALYTIC)}
                getOptionLabel={(option) => (option ? option.title : '')}
                getOptionSelected={(option) => option.value._id === data[option.type]._id}
                onChange={(e, newValue: OptionItem<IUserAutocompleteOptionItem> | null) => {
                  newValue && changeHandler(e, newValue);
                }}
                value={responsePersons.find((person) => person.value._id === data.assignee._id) || null}
                renderInput={(params) => (
                  <TextField {...params} label={'Ответственный'} required error={!!errors.assignee} variant="outlined" />
                )}
              />
            )}
          />
        </Grid>
        {assignAnalyticAccess && hasAnalyticsRole.length ? (
          <Grid item xs={12}>
            <Box
              display={'flex'}
              alignItems={'center'}
              gridGap={10}
              flexWrap={'nowrap'}
              justifyContent={'flex-end'}
              width={'100%'}
            >
              <Controller
                name="analytic"
                control={control}
                render={(props) => (
                  <Autocomplete
                    {...props}
                    size={'small'}
                    fullWidth
                    options={responsePersons.filter((user) => user.value.role === ANALYTIC)}
                    getOptionLabel={(option) => (option ? option.title : '')}
                    getOptionSelected={(option) => option.value._id === data[option.type]._id}
                    onChange={(e, newValue: OptionItem<IUserAutocompleteOptionItem> | null) => {
                      changeHandler(e, newValue);
                    }}
                    value={responsePersons.find((person) => person.value._id === data.analytic._id) || null}
                    renderInput={(params) => (
                      <TextField {...params} label="Аналитик" fullWidth error={!!errors.analytic} variant="outlined" />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {isAllowCreateBrokerId && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="brokerClientId"
              control={control}
              rules={{
                minLength: {
                  value: 24,
                  message: 'Введите валидный ID',
                },
              }}
              render={(props) => (
                <TextField
                  {...props}
                  label={'Broker ID'}
                  error={!!errors.brokerClientId}
                  fullWidth
                  size={'small'}
                  variant={'outlined'}
                  InputProps={{
                    endAdornment: props.value && (
                      <IconButton onClick={handleDeleteBrokerId} size="small">
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            {errors.brokerClientId && <p>{errors.brokerClientId.message}</p>}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="surname"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <TextField
                {...props}
                label={'Фамилия'}
                error={!!errors.surname}
                required
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <TextField {...props} label={'Имя'} error={!!errors.name} required fullWidth size={'small'} variant={'outlined'} />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="patronymic"
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label={'Отчество'}
                error={!!errors.patronymic}
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name={'mobile'}
            rules={{
              required: 'Это поле необходимо к заполнению!',
              minLength: {
                value: 11,
                message: 'Введите корректный номер телефона!',
              },
            }}
            control={control}
            render={({ value, onChange }) => (
              <>
                <PhoneInput
                  countryCodeEditable={false}
                  country={'ru'}
                  value={value}
                  onChange={onChange}
                  inputStyle={{
                    width: '100%',
                    backgroundColor: '#28292E',
                    color: '#ffffff',
                    borderColor: '#696A6D',
                  }}
                />
                {errors.mobile && <span style={{ color: 'tomato' }}>{errors.mobile.message}</span>}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <TextField
                {...props}
                label={'E-mail'}
                type={'email'}
                required
                error={!!errors.email}
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="gender"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Autocomplete
                {...props}
                size={'small'}
                options={genderTypes}
                getOptionLabel={(option) => (option ? option.title : '')} // todo: type definition
                getOptionSelected={(option) => option.value === data[option.type]}
                onChange={(e, newValue: OptionItem<string> | null) => {
                  newValue && changeHandler(e, newValue);
                }}
                value={genderTypes.find((gender) => gender.value === data.gender) || null}
                renderInput={(params) => (
                  <TextField {...params} label="Пол" required error={!!errors.gender} variant="outlined" />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="info"
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label={'Комментарий'}
                multiline
                minRows={3}
                maxRows={6}
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
        <Button
          variant={'contained'}
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} color="secondary" />}
          color={'primary'}
          type="submit"
          fullWidth
          onClick={handleSubmit(submit)}
        >
          Сохранить
        </Button>
      </Box>
    </form>
  );
};

export default React.memo(CreateClient);
