import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import format from 'date-fns/format';

import { EVENT_REFILL } from '@/utils/constants';
import { createEvent, updateEvent } from 'redux/event/event.actions';
import { changeCurrentPeriod } from 'redux/plans/plans.actions';
import { planApi } from 'redux/plans/plans.service';
import { PlansState } from 'redux/plans/plans.types';

const initialState: PlansState = {
  shouldRefresh: false,
  plansPeriod: [],
  currentPeriod: format(new Date(), 'M/yyyy'),
  currentOfficePlanAmount: 0,
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeCurrentPeriod, (state, { payload }) => {
      state.currentPeriod = payload;
    });
    builder.addMatcher(isAnyOf(createEvent.fulfilled, updateEvent.fulfilled), (state, { payload }) => {
      if (payload?.title && payload.title === EVENT_REFILL) {
        state.shouldRefresh = true;
      }
    });
    builder.addMatcher(planApi.endpoints.getUserPlan.matchFulfilled, (state) => {
      state.shouldRefresh = false;
    });
    builder.addMatcher(planApi.endpoints.getAllPlans.matchFulfilled, (state, { payload }) => {
      state.plansPeriod = Array.from(new Set(payload.map((plan) => plan.period)));
      state.currentOfficePlanAmount = payload.reduce((acc, item) => {
        if (item.amount) {
          acc += item.amount;
          return acc;
        }
        return acc;
      }, 0);
    });
  },
});

export default plansSlice.reducer;
