import React, { useEffect } from 'react';

import { Button, Tooltip, Box, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { BaseModalProps } from '@/types/common';
import { showErrorToast, showSuccessToast } from '@/utils/notification';
import { countTotalEmployeePlanRewards, sumOnValue } from '@/utils/utils';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { useMakePaymentsMutation } from 'redux/plans/plans.service';
import { CreatePaymentDto, IPlan } from 'redux/plans/plans.types';
import { IUser } from 'redux/user/user.types';

const SalaryPaymentsModal = (props: BaseModalProps) => {
  const { users } = useAppSelector<{ users: Array<IUser & { plan: IPlan | undefined }> }>(modalDataSelector);
  const [makePayment, { isLoading, isSuccess, isError }] = useMakePaymentsMutation();
  const dispatch = useAppDispatch();
  const countUserSalary = (plan: IPlan) => {
    const { withReward } = countTotalEmployeePlanRewards(plan as IPlan);
    const userFines = sumOnValue(plan.fines, 'amount');
    return (withReward - userFines).toFixed(0);
  };
  const hasUnpaidPlans = users.find((user) => !user.plan?.isPayed);
  const handleCloseModal = () => dispatch(closeModal());

  const handleSubmit = () => {
    const unPayedUsers = users.filter((item) => !item.plan?.isPayed);
    if (unPayedUsers.length) {
      const payments = unPayedUsers.map(
        (user) =>
          ({
            userId: user._id,
            planId: user.plan?._id,
            salary: countUserSalary(user.plan as IPlan),
          } as CreatePaymentDto),
      );
      makePayment(payments);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Выполнено успешно');
      handleCloseModal();
    }
    if (isError) {
      showErrorToast('Возникла ошибка');
    }
  }, [isSuccess, isError]);
  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} padding={'10px'}>
        {users &&
          users.length &&
          users.map((user) => (
            <Box
              key={user._id}
              display={'flex'}
              alignItems={'center'}
              gridGap={'15px'}
              padding={'10px 0'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} alignItems={'center'} gridGap={'10px'}>
                <Typography variant={'body2'}>
                  {user.personalData.name}
                  {user.personalData.surname}
                </Typography>

                <Tooltip title={user.plan?.isPayed ? 'Оплачено' : 'Неоплачено'} color={'#ffffff'}>
                  <Box width={20} height={20}>
                    {user.plan?.isPayed ? (
                      <CheckCircleOutlineRoundedIcon fontSize={'small'} color={'secondary'} />
                    ) : (
                      <ErrorRoundedIcon fontSize={'small'} color={'error'} />
                    )}
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant={'body2'}>{countUserSalary(user.plan as IPlan)}</Typography>
            </Box>
          ))}
      </Box>
      <Box display={'flex'} alignItems={'center'} gridGap={'15px'} padding={'10px'} justifyContent={'space-between'}>
        <Button
          disabled={isLoading || !hasUnpaidPlans}
          onClick={handleSubmit}
          variant={'contained'}
          size={'small'}
          endIcon={isLoading && <CircularProgress size={16} color={'secondary'} />}
          fullWidth
          color={'primary'}
        >
          Сделать выплаты
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(SalaryPaymentsModal);
