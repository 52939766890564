// @ts-nocheck
import {
  differenceInMonths,
  endOfDay as endOfDayFNS,
  endOfMonth as endOfMonthFNS,
  startOfDay as startOfDayFNS,
  startOfMonth as startOfMonthFNS,
} from 'date-fns';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import moment from 'moment';

import { roles } from '@/MOCK/quickCreationMockData';
import { EmployeeRewards, IFormatLogs, IUserAutocompleteOptionItem } from '@/types/common';
import { TRANSLATE_CLIENT_CHANGES } from '@/utils/constants';
import { LogItem } from 'redux/log/log.types';
import { IOffice } from 'redux/office/office.types';
import { IPlan, ParsedPlans } from 'redux/plans/plans.types';
import { CreateRatingFileDto, GroupRatingBy, IGroupedRatingItem, IRatingItem, RatingFile } from 'redux/ratings/rating.types';
import { IUser, IUserIndicators, IUserRoles } from 'redux/user/user.types';

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!/^\d/.test(phoneNumber) && !['+', ' ', '-'].some((symbol) => phoneNumber.startsWith(symbol))) {
    return '';
  }
  const match = phoneNumber
    .replace(/\D+/g, '')
    .replace(/^1/, '')
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
  return `+7 ${part1}${part2}${part3}`;
};

export const formatDateShort = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd.MM.yyyy', ru);
};

export const formatDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd.MM.yyyy HH:mm', ru);
};

const monthList = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const formatPeriod = (period) => {
  const year = format(period, 'yyyy');
  const month = +format(period, 'MM');

  return `${monthList[month - 1]} ${year}`;
};
export const endOfDay = (date) => endOfDayFNS(new Date(date));
export const startOfDay = (date) => startOfDayFNS(new Date(date));

export const startOfMonth = (date = '') =>
  date ? Date.parse(startOfMonthFNS(new Date(date))) : Date.parse(startOfMonthFNS(new Date()));
export const endOfMonth = (date = '') => Date.parse(endOfMonthFNS(new Date(date)));

export const getTodayDayNumber = (dayNumber: string | undefined): number => {
  if (!dayNumber) return 0;
  return new Date().getDate();
};

export const getPayDayDate = (dayNumber) => {
  return moment().set('date', dayNumber).format('YYYY-MM-DD');
};

export const daysInThisMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

export const createMonthRange = (dateObject) => {
  const date = dateObject.toISOString();
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return !!start && end ? `${start}+${end}` : '';
};

export const createDeadlineRange = (dateString) => {
  if (!dateString) return;
  const searchValue = dateString.split(' ');

  const start = new Date(startOfDay(searchValue.length === 1 ? dateString : searchValue[0])).toISOString();
  const end = new Date(endOfDay(searchValue.length === 1 ? dateString : searchValue[1])).toISOString();
  return `${start}+${end}`;
};

export const startEndCurrentMonth = () => {
  return `${new Date(startOfCurrentMonth('')).toISOString()}+${new Date(endOfCurrentMonth('')).toISOString()}`;
};

export const handleFiltersDeadline = (filtersValue) => {
  const values: any = { ...filtersValue };
  if (values.deadlineStart) {
    values.deadlineStart = new Date(values.deadlineStart as string).toISOString();
  }
  if (values.deadlineEnd) {
    values.deadlineEnd = new Date(values.deadlineEnd as string).toISOString();
  }
  if (values.deadlineStart || values.deadlineEnd) {
    const deadline = `${values.deadlineStart || ''} ${values.deadlineEnd || ''}`;
    values.deadline = createDeadlineRange(deadline.trim());
  }
  delete values.deadlineEnd;
  delete values.deadlineStart;
  return values;
};

export const startOfCurrentMonth = (format) => moment().startOf('month').format(format);
export const endOfCurrentMonth = (format) => moment().endOf('month').format(format);

export const getDateRangeFromMonthPeriod = (period: string): { start: string; end: string } => {
  const [month, year] = period.split('/');
  const date = moment()
    .set('month', +month - 1)
    .set('year', +year)
    .toDate();
  return {
    start: new Date(startOfMonth(date)).toISOString(),
    end: new Date(endOfMonth(date)).toISOString(),
  };
};
export const monthRangeInMilliseconds = (period: string): string => {
  if (!period) return '';
  const { end, start } = getDateRangeFromMonthPeriod(period);
  const startMonth = Date.parse(start);
  const endMonth = Date.parse(end);
  return `${startMonth}+${endMonth}`;
};

export const monthDiffs = (date) => {
  if (!date) return '';
  return differenceInMonths(new Date(), new Date(date));
};

export const countAllWorkTime = (date) => {
  const months = monthDiffs(date);

  if (+months === 0) return '1й месяц';

  if (+months >= 12) {
    const total = Math.round(+months / 12).toFixed(1);
    const [year, month] = total.toString().split('.');
    return `${year}г ${month}м`;
  } else {
    return `${months}м`;
  }
};

export const countPercentage = (total = 0, sum = 0): number => {
  const percent = (sum * 100) / total;
  const fraction = Number.isInteger(percent) ? 0 : 1;
  return percent ? +percent.toFixed(fraction) : 0;
};

export const getUserIndicators = (user: IUser): IUserIndicators => {
  const lastIndicatorIndex = user?.indicators?.length - 1;
  return lastIndicatorIndex >= 0 ? user?.indicators[lastIndicatorIndex] : ({} as IUserIndicators);
};

export const getUserDataForActionModals = (user: IUser): IUserAutocompleteOptionItem => {
  return {
    _id: user._id,
    name: user.personalData?.name,
    surname: user.personalData?.surname,
    patronymic: user.personalData?.patronymic,
    role: user.role,
    avatar: user?.avatar || '',
    registered_by: user?.registered_by._id,
  };
};

export const getEventType = (eventName: string) => {
  const eventTypes: Record<string, string> = {
    ['Регистрация']: 'registration',
    registration: 'Регистрация',
    ['Консультация']: 'consultation',
    consultation: 'Консультация',
    ['Лекция']: 'lecture',
    lecture: 'Лекция',
    ['Практика']: 'practice',
    practice: 'Практика',
    ['Последняя практика']: 'lastPractice',
    lastPractice: 'Последняя практика',
    ['Открытие счета']: 'accountOpening',
    accountOpening: 'Открытие счета',
    ['Пополнение счета']: 'refill',
    refill: 'Пополнение счета',
  };

  return eventTypes[eventName];
};

export const filterObjectEmptyValues = (obj: { [key: string]: unknown }): { [key: string]: unknown } => {
  const values: { [key: string]: unknown } = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      values[key] = obj[key];
    }
  });
  return values;
};

export const countTotalEmployeePlanRewards = (plan: IPlan): EmployeeRewards => {
  const rewards: EmployeeRewards = { withReward: 0, amount: 0 };
  if (!plan) return rewards;
  const currentPercent = plan && plan.amount >= plan.goal ? plan.percent.in : plan?.percent.before || 0;
  const currentReward = plan ? (currentPercent / 100) * plan.amount : 0;
  return {
    withReward:
      plan && plan.amount >= plan.goal
        ? currentReward + (plan && plan.amount >= plan.goal ? plan.reward : plan.rewardBefore)
        : currentReward,
    amount: currentReward,
  };
};

export const splitPlans = (plansList: IPlan[]): Record<string, Array<IPlan>> | null => {
  if (!plansList.length) return null;
  const currentPeriodValue = moment().format('M/YYYY');
  return plansList.reduce(
    (acc, plan) => {
      if (plan.period === currentPeriodValue) {
        acc.currentPeriod.push(plan);
        return acc;
      } else {
        acc.archived.push(plan);
        return acc;
      }
    },
    { currentPeriod: [], archived: [] },
  );
};

export const parsePlans = (plansList: IPlan[]): ParsedPlans => {
  const plans: ParsedPlans = { individual: [], office: [], role: [] };
  return plansList.length
    ? plansList.reduce((acc, item) => {
        acc[item.type].push(item);
        return acc;
      }, plans)
    : plans;
};

function isNumber(x: unknown): x is number {
  return typeof x === 'number';
}

export function sumOnValue<T, K extends keyof T>(collection: Array<T>, key: string): number {
  const result = 0;
  if (!collection?.length) return result;
  return collection.reduce((acc, item) => {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      if (typeof item[key] === 'number') {
        acc += item[key];
        return acc;
      }
    }
    return acc;
  }, result);
}

export const removeDuplicateItemById = <T>(plansList: T[]): T[] => {
  if (!plansList && !plansList.length) return [] as T[];
  return Object.values(
    plansList.reduce((acc, item) => {
      if (!acc[item._id]) {
        acc[item._id] = item;
        return acc;
      }
      return acc;
    }, {}),
  );
};

export const groupRatingsItem = (ratingList: IRatingItem[], groupBy: GroupRatingBy): IGroupedRatingItem[] => {
  if (!ratingList) return [];
  return Object.values(
    ratingList.reduce((acc, item) => {
      const { assignee, amount, confirmedDeposit, officeId } = item;
      const userId = assignee._id;
      const office = officeId._id;
      const isOfficeGrouped = groupBy === 'office';
      const groupByField = isOfficeGrouped ? office : userId;
      const ratingAssignee = assignee?.nickname ? assignee.nickname : `${assignee.name} ${assignee.surname.slice(0, 1)}.`;
      const userAvatarUrl = assignee?.avatar && assignee.avatar.startsWith('https') ? assignee.avatar : '/profile.svg';
      const officeAvatarUrl = '/profile.svg';

      if (!acc[groupByField]) {
        acc[groupByField] = {
          avatar: isOfficeGrouped ? officeAvatarUrl : userAvatarUrl,
          title: isOfficeGrouped ? officeId.name : ratingAssignee,
          subtitle: isOfficeGrouped ? officeId.group : roles[assignee.role],
          info: isOfficeGrouped ? officeId.region : officeId.name,
          amount,
          confirmedDeposit: confirmedDeposit || 0,
          itemId: isOfficeGrouped ? officeId._id : assignee._id,
          officeGroup: officeId.group,
          officeRegion: officeId.region,
          userRole: !isOfficeGrouped && assignee.role,
        };
        return acc;
      } else {
        acc[groupByField] = { ...acc[groupByField], amount: (acc[groupByField].amount += amount) };
        return acc;
      }
    }, {}),
  ).sort((a, b) => {
    if (a.amount > b.amount) return -1;
    else if (a.amount < b.amount) return 1;
    else return 0;
  });
};

export function getDefaultFilterValues(name: string) {
  if (typeof window === 'undefined') return null;
  const filters = window.localStorage.getItem(name);
  if (!filters) return null;
  return JSON.parse(filters);
}

interface IRatingIsVisibleProps {
  item: IGroupedRatingItem;
  role: IUserRoles;
  isAllow: boolean;
  office: IOffice;
  fullAccess: boolean;
}

export const isRatingVisible = ({ isAllow, role, office, item, fullAccess }: IRatingIsVisibleProps): boolean => {
  if (fullAccess) {
    if (role === IUserRoles.groupOfRegionDirector) {
      return item.officeGroup === office.group;
    } else if (role === IUserRoles.regionalDirector) {
      return item.officeRegion === office.region;
    } else {
      return true;
    }
  } else return isAllow;
};

export const compareRatingFileUpdate = (obj1: RatingFile, obj2: CreateRatingFileDto) => {
  return Object.keys(obj2).reduce((acc, key) => {
    if (typeof obj1[key] !== 'object') {
      if (obj1[key] !== obj2[key]) {
        acc[key] = obj2[key];
      }
    } else if (key === 'attachment') {
      if (obj1[key].Key !== obj2[key].Key) {
        acc[key] = obj2[key];
      }
    }
    return acc;
  }, {});
};

export const filteredUsersField = (requiredFields: string[], obj: IUser | undefined) => {
  if (obj) {
    return Object.keys(obj).reduce((acc, key) => {
      if (requiredFields.includes(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }
};

export const formatChanges = (changes: Record<string, any>) => {
  if (typeof changes !== 'object') {
    return;
  }
  const allChanges = Array.isArray(changes) ? changes[0] : changes;

  return Object.entries(allChanges).map(([fieldName, fieldValue]) => {
    switch (fieldName) {
      case 'assignee':
        return `Изменен ответственный менеджер. C ${fieldValue.name.old} ${fieldValue.surname.old} на ${fieldValue.name.new} ${fieldValue.surname.new}`;
      case 'office':
        return `Изменен офис. C ${fieldValue.name.old} на ${fieldValue.name.new}`;
      case 'create':
        return `Регистрация нового клиента`;
      default:
        if (fieldValue.hasOwnProperty('old') && fieldValue.hasOwnProperty('new')) {
          return `Изменено: ${fieldName}, Старое значение: ${fieldValue.old || ''},  Новое значение: ${fieldValue.new}`;
        } else if (fieldValue.hasOwnProperty('new')) {
          return `Изменено: ${fieldName}, Новое значение: ${fieldValue.new}`;
        } else if (fieldValue.hasOwnProperty('old')) {
          return `Изменено: ${fieldName}, Старое значение: ${fieldValue.old}`;
        } else {
          return `Был изменен: ${fieldName}, перечень измененных внутренних полей ${formatChanges(fieldValue)}`;
        }
    }
  });
};

export const translateFields = (names: string[]) => {
  return names.map((item) => {
    return TRANSLATE_CLIENT_CHANGES[item] ? TRANSLATE_CLIENT_CHANGES[item] : item;
  });
};

export const getChangesField = (log: Record<string, any>) => {
  const fieldsName = translateFields(Object.keys(log));
  return fieldsName.map((name, index) => {
    if (index < fieldsName.length - 1) {
      return `${name}, `;
    } else {
      return name;
    }
  });
};
