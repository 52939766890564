export const REQUEST_STATUS_PENDING = 'pending';
export const REQUEST_STATUS_SUCCESS = 'success';
export const REQUEST_STATUS_ERROR = 'error';

export const CLIENT_PAGE_PATH = '/clients/[pid]';

export const CHANGE_PASSWORD_MODAL = 'changePassword';
export const CREATE_MESSAGE_MODAL = 'createMessage';
export const RESET_PASSWORD_MODAL = 'resetPassword';
export const COMMENT_MODAL = 'createComment';
export const FIRE_EMPLOYEE_MODAL = 'fireEmployee';
export const EMPLOYEE_MODAL = 'createEmployee';
export const TASK_MODAL = 'createTask';
export const EVENT_MODAL = 'createEvent';
export const CLIENT_MODAL = 'createClient';
export const OFFICE_MODAL = 'createOffice';
export const PLAN_MODAL = 'createPlan';
export const CONFIRMATION_MODAL = 'confirmation';
export const FINE_MODAL = 'FINE_MODAL';
export const SALARY_MODAL = 'SALARY_MODAL';
export const TOPIC_MODAL = 'TOPIC_MODAL';
export const CATEGORY_MODAL = 'CATEGORY_MODAL';
export const SWITCH_OFF_OFFICE_MODAL = 'SWITCH_OFF_OFFICE_MODAL';
export const SUPPORT_MODAL = 'SUPPORT_MODAL';
export const CREATE_RATING_MODAL = 'CREATE_RATING_MODAL';
export const WELCOME_MODAL = 'WELCOME_MODAL';
export const CLIENT_LOG_MODAL = 'CLIENT_LOG_MODAL';

export const EVENT_STATUS_PENDING = 'Запланировано';
export const EVENT_STATUS_PROCESSED = 'Обработано';
export const EVENT_STATUS_COMPETE = 'Выполнено';
export const EVENT_STATUS_OVERDUE = 'Просрочено';

export const EVENT_REFILL = 'Пополнение счета';

export const TAB_STATISTIC = 'Статистика';
export const TAB_RATES = 'Рейтинг';
export const TAB_GRAPHS = 'Графики';

export const CLIENT_PAGE_PATHNAME = '/clients/[pid]';
export const SUPPORT_PAGE = '/support';

export const STATISTIC_BUILDER_TARGET_PROFILE = 'profile';
export const STATISTIC_BUILDER_TARGET_TABLE = 'table';
export const DATA_TRANSFER_TOPIC = 'topic';

export const SERVICE_EXECUTE_QUERY_NAME = 'commonApi/executeQuery/fulfilled';

export const CHROME_LIGHT_SHOT_URL =
  'https://chrome.google.com/webstore/detail/lightshot-screenshot-tool/mbniclmhobmnbdlbpiphghaielnnpgdp';

export const FIREFOX_LIGHT_SHOT_URL = 'https://addons.mozilla.org/ru/firefox/addon/lightshot/';
export const LIGHT_SHOT_URL = 'https://app.prntscr.com/';

export const MOCK_STATUS_IMG = '';

export const LAST_LOGIN_DATE = 'last_login_date';

export const EVENTS_FOR_FILTERS = [
  'Регистрация',
  'Консультация',
  'Лекция',
  'Практика',
  'Последняя практика',
  'Открытие счета',
  'Пополнение счета',
  'Встреча',
  'Вопрос о счете',
  'Cервис',
  'Повышение лояльности',
  'Собеседование',
  'Прием на работу',
  'Стажировка',
  'Первая продажа',
];

export const TRANSLATE_CLIENT_CHANGES = {
  _id: 'id',
  mobile: 'Телефон',
  email: 'Емайл',
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  gender: 'Пол',
  info: 'Инфо',
  status: 'Статус',
  tags: 'Тэги',
  comments: 'Комментарии',
  assignee: 'Ответственный',
  list_assignees: 'Список ответственных',
  analytic: 'Аналитик',
  office: 'Офис',
  registered_by: 'Дата регистрации',
  notepad: 'Заметки',
  notification: 'Уведомления',
  deleted: 'Удален',
  createdAt: 'Дата создания',
  updatedAt: 'Дата обновления',
};
