import React, { useState } from 'react';

import { Fade } from '@material-ui/core';

interface ImageModalProps {
  imageUrl: string;
  previewImage: string;
  allowScale: boolean;
}
export const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, previewImage, allowScale }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    allowScale && setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <img
        src={previewImage}
        alt={'img'}
        onClick={toggleModal}
        style={{ width: '100%', cursor: allowScale ? 'zoom-in' : 'initial' }}
      />
      <Fade in={isModalOpen} unmountOnExit>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#28292E',
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={toggleModal}
        >
          <img
            src={imageUrl}
            alt={'img'}
            style={{ maxHeight: '80%', maxWidth: '80%', borderRadius: 4, zIndex: 10, cursor: 'not-allowed' }}
          />
        </div>
      </Fade>
    </>
  );
};
