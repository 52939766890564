import { BaseOptionItem, IStatuses } from '@/types/common';
import { IUser } from 'redux/user/user.types';

export const OFFICE_GET_ALL = 'OFFICE_GET_ALL';
export const OFFICE_CREATE = 'OFFICE_CREATE';
export const OFFICE_UPDATE = 'OFFICE_UPDATE';
export const OFFICE_DELETE = 'OFFICE_DELETE';
export const TOGGLE_OFFICE_ACTIVE = 'TOGGLE_OFFICE_ACTIVE';

export interface IOffice {
  _id: string;
  name: string;
  address: string;
  city: string;
  region: string;
  group: string;
  payDate: string;
  isActive: boolean;
}

export type GetAllOfficesQueries = Partial<keyof IOffice> | undefined;

export interface IOfficeWithUsers {
  office: IOffice[];
  users: Array<IUser>;
}

export interface IOfficeState {
  data: Array<IOffice>;
  isLoading: boolean;
  filters: Record<string, string>;
  status: IStatuses;
}

export interface OfficeOptions {
  regionOptions: Array<BaseOptionItem<string>>;
  groupOptions: Array<BaseOptionItem<string>>;
}

export interface SwitchOffOfficeRequest extends Pick<IOffice, '_id' | 'isActive'> {
  password?: string;
}

export interface OfficeActionResponse {
  office: IOffice;
  message: string;
}
