import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '25px 0',
  },
  clusterContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(0 0 0 / 50%)',
    flex: '1 1 auto',
    padding: '10px 0',
    borderRadius: 7,
    overflow: 'hidden',
    width: '100%',
  },
  groupContainer: { display: 'flex', flexDirection: 'column', padding: 10, gap: 10, overflowY: 'auto' },
  officeContainer: { display: 'flex', flexDirection: 'column', width: '100%' },
}));

export default useStyles;
