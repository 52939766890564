//@ts-nocheck
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLayoutEffect } from 'react';

import { useSelector } from 'react-redux';
import io, { Socket } from 'socket.io-client';

import { getSocket } from '@/utils/getSocket';
import { WebsocketEvents } from '@/utils/websocketEvents';
import { getCategories } from 'redux/categories/categories.actions';
import { categoriesListSelector, categoryToUpdateIdSelector } from 'redux/categories/categories.selector';
import { setCategories, setNewCategoryToUpdateId } from 'redux/categories/categories.slice';
import { ICategory } from 'redux/categories/categories.types';
import {
  currentClientAssigneeIdSelector,
  currentClientBrokerIdSelector,
  currentClientIdSelector,
} from 'redux/client/client.selectors';
import { getEventListByRole } from 'redux/event/event.selector';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { logout } from 'redux/login/auth.actions';
import { accessViewSelector } from 'redux/managment/management.selectors';
import { unreadNotificationSelector } from 'redux/notifications/notifications.selectors';
import { setUnreadNotifications } from 'redux/notifications/notifications.slice';
import { INotification } from 'redux/notifications/notifications.types';
import { setOperations } from 'redux/operations/operations.slice';
import { myIdSelector, profileSelector } from 'redux/profile/profile.selectors';
import { getUserListByRole } from 'redux/user/user.selectors';

export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export const useViewAccessByRole = (entity) => {
  const instanceAccess = useSelector(accessViewSelector);
  const myProfile = useSelector(profileSelector);
  const [state, setState] = useState(false);

  const { assignee } = entity;

  useEffect(() => {
    if (instanceAccess || assignee._id === myProfile._id) {
      setState(true);
    } else {
      setState(false);
    }
  }, [instanceAccess, entity]);
  return state;
};

export enum viewAccessType {
  event = 'event',
  user = 'user',
}

export const ViewAccessByRole = (id: string, type: viewAccessType): boolean => {
  let listByRole;
  const events = useSelector(getEventListByRole);
  const users = useSelector(getUserListByRole);

  if (type === viewAccessType.event) listByRole = events;
  if (type === viewAccessType.user) listByRole = users;

  const [state, setState] = useState(false);

  const idsByRole = useMemo(() => listByRole.map((item) => item._id), [listByRole]);

  useEffect(() => {
    if (idsByRole.includes(id) !== state) setState(!state);
  }, [id, idsByRole]);

  return state;
};

export const eventViewAccessByRole = (eventId) => ViewAccessByRole.bind(eventId, viewAccessType.event);

export const userViewAccessByRole = (userId) => ViewAccessByRole.bind(userId, viewAccessType.user);

// при увеличении использования разделить на модули
export const useWebsocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const HOST = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:5001';
  const dispatch = useAppDispatch();
  const id = useAppSelector<string>(myIdSelector);
  const categoryToUpdate = useAppSelector<string>(categoryToUpdateIdSelector);
  const categories = useAppSelector<Array<ICategory>>(categoriesListSelector);
  const unreadNotifications = useSelector(unreadNotificationSelector);

  const pushNewNotification = useCallback(
    (payload: INotification) => {
      dispatch(setUnreadNotifications([payload, ...unreadNotifications]));
    },
    [unreadNotifications],
  );

  useEffect(() => {
    if (!socket && id) {
      const socketOptions = {
        transports: ['websocket'],
        auth: { id },
      };
      const websocket = io(`${HOST}`, socketOptions);
      websocket.on(WebsocketEvents.NEW_NOTIFICATION, (notification: INotification) => {
        pushNewNotification(notification);
      });
      websocket.on(WebsocketEvents.UPDATE_NEW_CATEGORY, () => {
        dispatch(getCategories());
      });
      websocket.on(WebsocketEvents.LOGOUT, () => {
        dispatch(logout());
      });
      setSocket(websocket);
    }
  }, [id, pushNewNotification]);

  useEffect(() => {
    if (categoryToUpdate) {
      socket?.emit(WebsocketEvents.SET_HAS_NEW_TOPICS, categoryToUpdate);
      const updatedCategories = categories.map((cat) => {
        return cat._id === categoryToUpdate ? { ...cat, hasNewTopics: false } : cat;
      });
      dispatch(setCategories(updatedCategories));
      dispatch(setNewCategoryToUpdateId(''));
    }
  }, [categoryToUpdate]);

  return null;
};

export const useWebSocketOperations = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(currentClientAssigneeIdSelector);
  const id = useAppSelector(myIdSelector);
  const clientBrokerId = useAppSelector(currentClientBrokerIdSelector);
  const clientId = useAppSelector(currentClientIdSelector);
  const [socket, setSocket] = useState<null | Socket>(null);

  useEffect(() => {
    const socket = getSocket(id, null);
    setSocket(socket as Socket);
  }, [id]);

  useEffect(() => {
    if (socket && clientBrokerId) {
      socket.emit('broker.operations.get', { clientBrokerId, userId, clientId });
      socket.on('broker.operations.fulfilled', (data: any) => {
        dispatch(setOperations(data));
      });
    }
  }, [socket, dispatch, clientBrokerId, userId, clientId]);

  return null;
};
