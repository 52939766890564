import axios from 'axios';

import { getAccessToken, clearAuthTokensFromCookies, getRefreshToken, setCookies } from 'redux/login/auth.helpers';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
instance.interceptors.request.use(
  (config) => {
    const state = { ...config };
    if (getAccessToken()) {
      state.headers.Authorization = `Bearer ${getAccessToken()}`;
    }

    return state;
  },
  (error) => Promise.reject(error),
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axios.defaults.headers = {
          ...instance.defaults.headers,
          common: { ...instance.defaults.headers.common, Authorization: `Bearer ${getRefreshToken()}` },
        };
        axios
          .post(
            baseURL + '/auth/token/refresh',
            {
              access_token: getAccessToken(),
              refresh_token: getRefreshToken(),
            },
            { headers: { ...originalRequest.headers, Authorization: `Bearer ${getRefreshToken()}` } },
          )
          .then(({ data }) => {
            setCookies({ refresh_token: data.refresh_token, access_token: data.access_token });
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
            processQueue(null, data.access_token);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            clearAuthTokensFromCookies();
            window.location.href = '/login';
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default instance;
