import { FC, ReactNode, ReactElement, memo } from 'react';

import Box from '@material-ui/core/Box';
import { BoxProps } from '@material-ui/core/Box/Box';

import { useStyles } from './styles';

interface ScrollableBoxProps extends BoxProps {
  children: ReactElement | ReactNode;
  maxHeight?: number | string;
}

const ScrollableBox: FC<ScrollableBoxProps> = ({ children, maxHeight, ...rest }) => {
  const classes = useStyles();
  return (
    <Box {...rest} style={{ maxHeight }} className={`${classes.root} custom-scrollbar`}>
      {children}
    </Box>
  );
};

export default memo(ScrollableBox);
