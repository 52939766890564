import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';

import { useStyles } from 'components/common/Overlay/Overlay.styles';

const Overlay = () => {
  const classes = useStyles();
  return (
    <Backdrop open={true} className={classes.root}>
      <CircularProgress color={'inherit'} />
    </Backdrop>
  );
};

export default Overlay;
