import React, { useEffect } from 'react';

import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

import { IFetchingStatuses } from '@/types/common';
import { idOfficeListForAutocomplete } from '@/utils/selectVariants';
import { ADMIN, DEVELOPMENTDIRECTOR, REGIONALDIRECTOR } from '@/variables/management';
import Overlay from 'components/common/Overlay';
import { useStyles } from 'components/Topics/CreateCategoryModal/CreateCategoryModal.styles';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { createCategory, updateCategory } from 'redux/categories/categories.actions';
import { categoriesStateStatusSelector } from 'redux/categories/categories.selector';
import { ICategory } from 'redux/categories/categories.types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { officeListSelector } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';
import { roleSelector } from 'redux/profile/profile.selectors';
import { IUserRoles } from 'redux/user/user.types';

const CreateCategoryModal = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentRole = useAppSelector<IUserRoles | null>(roleSelector);
  const categoryData = useAppSelector<ICategory | null>(modalDataSelector);
  const stateStatus = useAppSelector<IFetchingStatuses>(categoriesStateStatusSelector);
  const officeListData = useAppSelector<Array<IOffice>>(officeListSelector);

  const isLoading = stateStatus === IFetchingStatuses.pending;
  const offices = idOfficeListForAutocomplete(officeListData);

  const defaultValues: Partial<ICategory> = {
    name: '',
    publicAccess: false,
    officeAccess: [],
  };

  const { errors, control, handleSubmit, setValue, register, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    ...defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    const defaults = {
      name: categoryData?.name ?? '',
      publicAccess: categoryData?.publicAccess ?? false,
      officeAccess: categoryData?.officeAccess ?? [],
    };
    reset(defaults);
  }, [reset]);

  const submit = (data: Partial<ICategory>): void => {
    if (!categoryData) {
      dispatch(createCategory(data))
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        });
    } else {
      dispatch(updateCategory({ ...data, _id: categoryData._id }))
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        });
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(submit)}>
      {isLoading && <Overlay />}
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ value }) => (
          <TextField
            value={value ?? ''}
            label="Название"
            error={!!errors.name}
            variant="outlined"
            fullWidth
            onChange={(e) => setValue('name', e.target.value)}
          />
        )}
      />
      {currentRole && [REGIONALDIRECTOR, DEVELOPMENTDIRECTOR, ADMIN].includes(currentRole) && (
        <>
          <Controller
            name="publicAccess"
            control={control}
            render={({ value, onChange }) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      defaultChecked={categoryData?.publicAccess ?? false}
                      ref={register}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label={<Typography variant={'caption'}>Публичный доступ</Typography>}
                />
              );
            }}
          />
          <Controller
            name="officeAccess"
            control={control}
            render={(props) => (
              <Autocomplete
                {...props}
                options={offices}
                size={'small'}
                disableCloseOnSelect
                multiple
                getOptionLabel={(option) => (option ? option.title : '')}
                value={props.value}
                onChange={(_, newValue) =>
                  setValue(
                    'officeAccess',
                    newValue.map((val) => val.value),
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Доступ для офисов" error={!!errors.officeAccess} variant="outlined" />
                )}
              />
            )}
          />
        </>
      )}
      {!categoryData ? <Button type={'submit'}>Создать</Button> : <Button type={'submit'}>Обновить</Button>}
    </form>
  );
};

export default CreateCategoryModal;
