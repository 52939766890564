import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontsize: 11,
    marginTop: 10,
    fontWeight: 500,
    lineHeight: '14px',
  },
  menuItem: {
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  withoutPadding: {
    padding: 0,
  },
  menuActive: {
    background: 'rgba(255, 255, 255, 0.18)',
  },
}));

export default useStyles;
