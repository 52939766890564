import { HTTP_METHODS } from '@/types/common';
import { commonApi } from 'redux/commonApi';
import { TransferEventsRequest } from 'redux/event/event.types';

const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['TransferEvent'] });
const eventsUrl = 'api/events';

const eventsApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    transferEvent: builder.mutation<string, TransferEventsRequest>({
      query: (data) => {
        return {
          url: `${eventsUrl}/transfer-events`,
          method: HTTP_METHODS.post,
          data,
        };
      },
      invalidatesTags: ['TransferEvent'],
    }),
  }),
  overrideExisting: true,
});

export const { useTransferEventMutation } = eventsApi;
