import { useEffect } from 'react';

import { Box, Button, Fade, Typography } from '@material-ui/core';
import { useRouter } from 'next/router';

import { modalDataSelector } from 'redux/app/app.selectors';
import { deleteCategory, getCategories } from 'redux/categories/categories.actions';
import { updateClient } from 'redux/client/client.actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { deleteOffice } from 'redux/office/office.actions';
import { clearOperationsState, deleteClientOperations } from 'redux/operations/operations.actions';
import { useClosePlansMutation } from 'redux/plans/plans.service';
import { deleteComment, deleteTopic } from 'redux/topics/topics.actions';
import { setClearNestedComments } from 'redux/topics/topics.slice';
import { fireUser } from 'redux/user/user.actions';

const ConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    id,
    type,
    planPeriod = '',
    reinstate,
    data,
  } = useAppSelector<Record<string, string | boolean>>(modalDataSelector) as {
    id: string;
    type: string;
    planPeriod: string;
    reinstate: boolean;
    data: any;
  };
  const [closePlan, { isSuccess }] = useClosePlansMutation();
  const isFireUser = type === 'user';
  const isDeleteClient = type === 'client';
  const isOfficeDelete = type === 'office';
  const isPlan = type === 'plan';
  const isDeleteTopic = type === 'topic';
  const isDeleteComment = type === 'comment';
  const isDeleteCategory = type === 'category';
  const isDeleteBrokerId = type === 'deleteBrokerId';
  const closeModal = () => {
    dispatch({ type: 'CLOSE_APP_MODAL' });
  };
  const confirm = (): void => {
    if (isFireUser) dispatch(fireUser({ _id: id, fired: !reinstate }));
    if (isDeleteClient) dispatch(updateClient({ client: { _id: id, deleted: true }, isShowToast: true }));
    if (isOfficeDelete) dispatch(deleteOffice(id));
    if (isDeleteBrokerId) {
      dispatch(clearOperationsState());
      dispatch(deleteClientOperations(id));
      dispatch(updateClient({ client: { ...data, brokerClientId: null } }))
        .unwrap()
        .then(() => closeModal());
    }
    if (isPlan) {
      closePlan({ period: planPeriod, officeId: id });
    }
    if (isDeleteTopic) {
      dispatch(deleteTopic(id))
        .unwrap()
        .then(() => {
          router.push('/topics');
          closeModal();
        });
    }
    if (isDeleteComment) {
      dispatch(deleteComment(id))
        .unwrap()
        .then(() => {
          dispatch(setClearNestedComments(id));
          closeModal();
        });
    }
    if (isDeleteCategory) {
      dispatch(deleteCategory(id))
        .unwrap()
        .then(() => {
          dispatch(getCategories());
          closeModal();
        });
    }
  };

  useEffect(() => {
    isSuccess && closeModal();
  }, [isSuccess]);

  return (
    <Fade in={!!type}>
      <Box display={'flex'} flexDirection={'column'} padding={'15px'}>
        <Typography variant={'h6'} align={'center'}>
          {isFireUser && `Вы действительно хотите ${reinstate ? 'восстановить' : 'уволить'} сотрудника?`}
          {isDeleteClient && 'Вы действительно хотите удалить клиента?'}
          {isOfficeDelete && 'Вы действительно хотите удалить офис?'}
          {isPlan && 'Завершить текущий план данного офиса'}
          {isDeleteTopic && (
            <>
              Вы действительно хотите удалить топик? <br /> Все комментарии на него также будут удалены
            </>
          )}
          {isDeleteBrokerId && (
            <>
              Вы действительно хотите удалить брокер ID клиента? <br /> Все операции на него также будут удалены
            </>
          )}
          {isDeleteComment && (
            <>
              Вы действительно хотите удалить комментарий? <br /> Все ответы на него также будут удалены
            </>
          )}
          {isDeleteCategory && (
            <>
              Вы действительно хотите удалить категорию? <br /> Все топики из нее будут перенесены в категорию "Новые"
            </>
          )}
        </Typography>

        <Box display={'flex'} justifyContent={'space-around'} padding={'15px 0'}>
          <Button variant={'contained'} color={'secondary'} onClick={confirm}>
            Подтвердить
          </Button>
          <Button variant={'contained'} color={'primary'} onClick={closeModal}>
            Отменить
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default ConfirmationModal;
