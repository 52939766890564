import React from 'react';

import { ListItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { formatChanges } from '@/utils/utils';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppSelector } from 'redux/hooks';
import { logsSelector } from 'redux/log/log.selectors';

const ClientLogModal = () => {
  const id = useAppSelector<string>(modalDataSelector);
  const logs = useAppSelector(logsSelector);
  const log = logs.find((item) => item._id === id);
  const obj = { ...log };
  const arrChanges = formatChanges(obj.changes || {});

  return (
    <Box style={{ overflow: 'auto' }}>
      {arrChanges?.map((item: string) => (
        <ListItem>{item}</ListItem>
      ))}
    </Box>
  );
};

export default ClientLogModal;
