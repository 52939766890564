import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { useRouter } from 'next/router';

import { useStyles } from 'components/common/NotificationItem/NotificationItem.styles';
import { NotificationTypes } from 'redux/notifications/notifications.types';

interface NotificationItemProps {
  id: string;
  description: string;
  metaId: string;
  type: NotificationTypes;
  date: Date | string;
}

const NotificationItem: FC<NotificationItemProps> = ({ date, description, metaId, type, id }) => {
  const classes = useStyles();
  const router = useRouter();
  return (
    <div id={id} className={`${classes.root} notification`}>
      <Typography className={classes.date}>{new Date(date).toLocaleString()}</Typography>
      {type !== NotificationTypes.OTHER ? (
        <Typography
          className={classes.link}
          onClick={() => router.push(router.pathname.includes(type.toLowerCase()) ? metaId : `${type.toLowerCase()}/${metaId}`)}
        >
          {description}
        </Typography>
      ) : (
        <Typography variant={'body2'}>{description}</Typography>
      )}
    </div>
  );
};

export default NotificationItem;
