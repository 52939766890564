import { IUserAutocompleteOptionItem, OptionItem, OptionTypes } from '@/types/common';
import { IClient } from 'redux/client/client.types';
import { IOffice } from 'redux/office/office.types';
import { RatesTypes } from 'redux/ratings/rating.types';
import { IUser, IUserRoles } from 'redux/user/user.types';

export const RateTypesValues: Record<string, string> = {
  [RatesTypes.PLACE]: 'Место',
  [RatesTypes.SALES]: 'Продажи',
  [RatesTypes.START]: 'Начальный',
};

export const ratesTypesOptions: Array<OptionTypes> = Object.entries(RateTypesValues).map((item) => ({
  value: item[0],
  label: item[1],
}));

export const officeListForAutocomplete = (officeList: Array<IOffice>) =>
  !!officeList
    ? officeList.map((office) => ({
        title: `${office.city} ${office.address}`,
        value: office.name,
        type: 'office',
      }))
    : [
        {
          title: '',
          value: '',
          type: 'office',
        },
      ];

export const _officeListForClientAutocomplete = (officeList: Array<IOffice>): Array<OptionItem<IOffice>> =>
  !!officeList.length
    ? officeList.map((office) => ({
        title: `${office.name} ${office.address}`,
        value: office,
        type: 'office',
      }))
    : [];

export const idOfficeListForAutocomplete = (officeList: Array<IOffice>): Array<OptionItem<string>> =>
  !!officeList
    ? officeList.map((office) => ({
        title: `${office.name}  (${office.address}) `,
        value: office._id,
        type: 'office',
      }))
    : [
        {
          title: '',
          value: '',
          type: 'office',
        },
      ];

export const rolesListForAutocomplete = (rolesObject: Record<string, string>): Array<OptionItem<IUserRoles>> => {
  return !!rolesObject
    ? Object.keys(rolesObject).map((key) => ({
        title: rolesObject[key],
        value: key as IUserRoles,
        type: 'role',
      }))
    : [
        {
          title: '',
          value: '' as IUserRoles,
          type: 'role',
        },
      ];
};

export const userListForAutocomplete = (userList: Array<IUser>, type = 'assignee') =>
  !!userList
    ? userList.map((user) => ({
        title: user.personalData.name + ' ' + user.personalData.surname,
        value: user._id,
        type,
      }))
    : [
        {
          title: '',
          value: '',
          type,
        },
      ];

export const _userListForClientAutocomplete = (userList: Array<IUser>): Array<OptionItem<IUserAutocompleteOptionItem>> =>
  !!userList
    ? userList.map((user) => ({
        title: user.personalData.name + ' ' + user.personalData.surname,
        value: {
          _id: user._id,
          name: user.personalData?.name,
          surname: user.personalData?.surname,
          patronymic: user.personalData?.patronymic,
          role: user.role,
          avatar: user?.avatar || '',
          registered_by: user?.registered_by?._id,
        },
        type: 'assignee',
      }))
    : [
        {
          title: '',
          value: {} as IUserAutocompleteOptionItem,
          type: 'assignee',
        },
      ];

export const clientListForAutocomplete = (clientList: Array<IClient>) =>
  clientList.map((client) => ({
    title: client.name + ' ' + client.surname,
    value: client._id,
    type: 'client',
  }));

export const getOfficeByUserId = (id: string, userList: Array<IUser>): IOffice | '' => {
  return userList.find((user) => user._id === id)?.office || '';
};
export const _clientListForAutoComplete = (clientList: Array<IClient>) => {
  return clientList.map((client) => ({
    title: client.name + ' ' + client.surname,
    value: {
      _id: client?._id,
      name: client?.name || '',
      surname: client?.surname || '',
      patronymic: client?.patronymic || '',
      registered_by: client?.registered_by,
      analytic: {
        _id: client?.analytic?._id,
      },
    },
    type: 'client',
  }));
};

export const getClientDataForActionModal = (client: IClient) => {
  return {
    _id: client?._id,
    name: client?.name || '',
    surname: client?.surname || '',
    patronymic: client?.patronymic || '',
    registered_by: client?.registered_by,
    analytic: {
      _id: client?.analytic?._id,
    },
  };
};
