import axios from 'api';
import { ICategory, UpdateCategoryRequest } from 'redux/categories/categories.types';

const url = '/api/categories';

const CategoriesAPI = {
  getAll: () => axios.get(url),
  getOne: (id: string) => axios.get(`${url}/${id}`),
  create: (data: Partial<ICategory>) => axios.post(url, data),
  update: (data: UpdateCategoryRequest) => axios.patch(`${url}/${data._id}`, data),
  delete: (id: string) => axios.delete(`${url}/${id}`),
};
export default CategoriesAPI;
