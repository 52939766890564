import axios from 'api';

const url = '/api/profile';

const ProfileAPI = {
  get: () => axios.get(url),
  update: (data: Record<string, unknown>) => axios.patch(`${url}/${data._id}`, data),
};

export default ProfileAPI;
