import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { EntityParsedResponse, IFetchingStatuses } from '@/types/common';
import { clearUserFilters, createUser, getSingleUser, getUsers, patchUser, setUserFilters } from 'redux/user/user.actions';

import { IUser, IUserState } from './user.types';

const initialState: IUserState = {
  data: [],
  status: null,
  currentUser: null,
  totalUsers: 0,
  filters: {},
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUserFilters, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(clearUserFilters, (state) => {
      state.filters = initialState.filters;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      const { total, result } = payload as EntityParsedResponse<IUser>;
      state.status = IFetchingStatuses.success;
      state.data = result;
      state.totalUsers = total;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      state.data.push(payload);
    });
    builder.addCase(patchUser.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      const index = state.data.findIndex((user) => user._id === payload?._id);
      if (index !== -1) {
        state.data[index] = payload;
      }
      state.currentUser = payload;
    });
    builder.addCase(getSingleUser.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.status = IFetchingStatuses.success;
    });
    builder.addMatcher(isAnyOf(getUsers.pending, createUser.pending, patchUser.pending, getSingleUser.pending), (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addMatcher(isAnyOf(getUsers.rejected, createUser.rejected, patchUser.rejected, getSingleUser.rejected), (state) => {
      state.status = IFetchingStatuses.error;
    });
  },
});

export default userSlice.reducer;
