import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@rtk-incubator/rtk-query';

import { commonApi } from 'redux/commonApi';
import messageReducer from 'redux/message/message.slice';
import plansReducer from 'redux/plans/plans.slice';

import apiReducer from './api/api.slice';
import appReducer from './app/app.reducer';
import categoriesReducer from './categories/categories.slice';
import clientReducer from './client/client.reducer';
import eventReducer from './event/event.reducer';
import filterReducer from './filter/filter.reducer';
import logReducer from './log/log.reducer';
import authReducer from './login/auth.slice';
import managementReducer from './managment/management.reducer';
import notificationsReducer from './notifications/notifications.slice';
import officeReducer from './office/office.slice';
import operationsReducer from './operations/operations.slice';
import ordersReducer from './orders/orders.slice';
import profileReducer from './profile/profile.reducer';
import ratingReducer from './ratings/rating.slice';
import statisticReducer from './statistic/statistic.slice';
import topicReducer from './topics/topics.slice';
import userReducer from './user/user.reducer';

export const store = configureStore({
  reducer: {
    api: apiReducer,
    app: appReducer,
    auth: authReducer,
    profile: profileReducer,
    management: managementReducer,
    message: messageReducer,
    filter: filterReducer,
    users: userReducer,
    orders: ordersReducer,
    clients: clientReducer,
    offices: officeReducer,
    events: eventReducer,
    topics: topicReducer,
    statistic: statisticReducer,
    rating: ratingReducer,
    logs: logReducer,
    notifications: notificationsReducer,
    categories: categoriesReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    plans: plansReducer,
    operations: operationsReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(commonApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
