import React, { useEffect, useState } from 'react';

import { Box, Button, Typography, TextField, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ru from 'date-fns/locale/ru';
import ReactDatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { BaseModalProps, OptionItem } from '@/types/common';
import { IStorageItemData } from '@/utils/fileStorage';
import { _officeListForClientAutocomplete, rolesListForAutocomplete } from '@/utils/selectVariants';
import { getUserDataForActionModals } from '@/utils/utils';
import { filterEmptyObjectField } from '@/utils/validators';
import Loader from 'components/common/Loader';
import UploadImageBox from 'components/common/UploadImageBox/UploadImageBox';
import AttachmentsView from 'components/Topics/AttachmentsView';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { officeListSelector } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';
import { isHeadOfAttractionDepartmentRole, profileSelector } from 'redux/profile/profile.selectors';
import { patchUser, postUser } from 'redux/user/user.actions';
import { usersStatusSelector } from 'redux/user/user.selectors';
import { useGetUserDocsAsFileQuery } from 'redux/user/user.service';
import { IBaseUser, IPersonalData, IUser, IUserAssignee, IUserRoles } from 'redux/user/user.types';

const CreateUser = (props: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector<{ currentEmployee: IUser }>(modalDataSelector);
  const currentEmployee = modalData ? modalData.currentEmployee : null;
  const currentUser = useAppSelector(profileSelector);
  const { _id: currentUserId, office: currentOffice } = currentUser;
  const officeListData = useAppSelector(officeListSelector);
  const [isRussianPassport, setIsRussianPassport] = useState(true);
  const { isLoading } = useAppSelector(usersStatusSelector);
  const isHeadOfAttractionDepartment = useAppSelector(isHeadOfAttractionDepartmentRole);
  const { data: userDocs, isFetching } = useGetUserDocsAsFileQuery(currentEmployee?._id || '');
  // TODO пока оставил так ,надо будет разобраться почему не меняются правила ,скорей всего из за того что это реф,у него сохраняются данные предыдущие, надо либу смотреть
  // const passportNumberValidation = isRussianPassport ? { minLength: 6, maxLength: 6, required: true } : { required: true };
  // const passportSeriesValidation = isRussianPassport ? { minLength: 4, maxLength: 4, required: true } : { required: true };

  const togglePassportValidation = () => {
    setIsRussianPassport(!isRussianPassport);
  };

  const defaultValues: Omit<IBaseUser, 'password' | 'date_registered' | 'registered_by'> = {
    mobile: currentEmployee?.mobile || '',
    email: currentEmployee?.email || '',
    mtLoginNumber: currentEmployee?.mtLoginNumber || '',
    personalData: {
      name: currentEmployee?.personalData?.name || '',
      surname: currentEmployee?.personalData?.surname || '',
      patronymic: currentEmployee?.personalData?.patronymic || '',
      passportSeries: currentEmployee?.personalData?.passportSeries || '',
      passportNumber: currentEmployee?.personalData?.passportNumber || '',
      passportAdditional: currentEmployee?.personalData?.passportAdditional || '',
      passportDate: currentEmployee?.personalData?.passportDate ? new Date(currentEmployee?.personalData?.passportDate) : null,
      documentScan: userDocs || [],
      birthday: currentEmployee?.personalData?.birthday || null,
      gender: currentEmployee?.personalData?.gender || '',
      nickname: currentEmployee?.personalData?.nickname || '',
    },
    role: currentEmployee?.role || ('' as IUserRoles),
    status: currentEmployee?.status || '',
    office: currentEmployee?.office || currentOffice,
  };

  const { control, errors, watch, handleSubmit, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'all',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const initialData: Partial<IBaseUser> = {
    mobile: '',
    email: '',
    mtLoginNumber: '',
    personalData: {
      name: '',
      surname: '',
      patronymic: '',
      passportSeries: '',
      passportNumber: '',
      passportAdditional: '',
      passportDate: null,
      documentScan: [],
      birthday: null,
      gender: '',
      nickname: '',
    },
    role: '' as IUserRoles,
    status: 'active',
    office: {} as IOffice,
    date_registered: null,
    registered_by: {} as IUserAssignee,
  };
  const initialUser = {
    personalData: {} as IPersonalData,
    _id: currentEmployee?._id || '',
    date_registered: currentEmployee?.date_registered || null,
    registered_by: currentEmployee?.registered_by || '',
    role: '',
  };

  const { roles } = props;
  const employeePosition = rolesListForAutocomplete(roles);
  const [data, setData] = useState<Partial<IBaseUser>>(initialData);
  const [user, setUser] = useState(initialUser);
  const [step, setStep] = useState(1);
  const [images, setImages] = useState<Array<IStorageItemData>>([]);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState<Array<string>>([]);

  useEffect(() => {
    if (userDocs?.length) {
      setImages(userDocs);
    }
    setData({
      ...data,
      ...defaultValues,
    });
  }, [currentOffice, userDocs]);

  const offices = _officeListForClientAutocomplete(officeListData);

  const deleteImage = (index: number) => {
    setImages((prevState) => prevState.filter((item, i) => i !== index));
  };

  function changeHandler<T>(option: OptionItem<T>) {
    if (!option?.type) return;
    setData({ ...data, [option.type]: option.value });
    setValue(option.type, option.value);
  }

  const nextStep = (data: Partial<IBaseUser>) => {
    if (step === 2) {
      setUser({
        ...user,
        ...{
          personalData: {
            ...user.personalData,
            ...data.personalData,
          },
        },
      });
    } else {
      setUser({ ...user, ...data });
    }
    setStep(step + 1);
  };

  const submit = () => {
    user.personalData.documentScan = images;
    if (isHeadOfAttractionDepartment) {
      user.role = 'operator';
    }
    if (currentEmployee?._id) {
      const { password, ...userData } = user as Partial<IUser>;
      dispatch(patchUser(filterEmptyObjectField(userData as Record<string, unknown>)));
      dispatch(closeModal());
    } else {
      const { _id, ...userData } = user as Partial<IUser>;
      userData.registered_by = getUserDataForActionModals(currentUser);
      // @ts-ignore
      userData['personalData'] = { ...userData.personalData, passportDate: userData.personalData?.passportDate };
      dispatch(postUser(filterEmptyObjectField(userData as Record<string, unknown>)));
    }
  };

  return (
    <Grid container spacing={1} style={{ minHeight: 235 }} alignContent={'center'}>
      {isLoading && <Loader />}
      <Grid item xs={12}>
        {step === 1 && (
          <form onSubmit={handleSubmit(nextStep)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'personalData.surname'}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Фамилия'}
                      required
                      error={!!errors.personalData?.surname}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'personalData.name'}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Имя'}
                      required
                      error={!!errors.personalData?.name}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'personalData.patronymic'}
                  rules={{ required: false }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Отчество'}
                      error={!!errors.personalData?.patronymic}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'mtLoginNumber'}
                  rules={{ required: false }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Счет МТ5'}
                      error={!!errors.mtLoginNumber}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name={'email'}
                  rules={{ required: false }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'E-mail'}
                      type={'email'}
                      error={!!errors.email}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: !isHeadOfAttractionDepartment }}
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      disabled={isHeadOfAttractionDepartment}
                      options={employeePosition}
                      size={'small'}
                      getOptionLabel={(option) => (option ? option.title : '')}
                      getOptionSelected={(option) => option.value === data['role']}
                      onChange={(_, data: OptionItem<IUserRoles> | null) => data && changeHandler(data)}
                      value={employeePosition.find((position) => position.value === data.role) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={isHeadOfAttractionDepartment ? 'Оператор' : 'Должность'}
                          error={!!errors.role}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name={'mobile'}
                  rules={{
                    required: 'Это поле необходимо к заполнению!',
                    minLength: {
                      value: 11,
                      message: 'Введите корректный номер телефона!',
                    },
                  }}
                  control={control}
                  render={({ value, onChange }) => (
                    <>
                      <PhoneInput
                        countryCodeEditable={false}
                        country={'ru'}
                        value={value}
                        onChange={onChange}
                        inputStyle={{
                          width: '100%',
                          backgroundColor: '#28292E',
                          color: '#ffffff',
                          borderColor: '#696A6D',
                        }}
                      />
                      {errors.mobile && <span style={{ color: 'tomato' }}>{errors.mobile.message}</span>}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="office"
                  control={control}
                  rules={{ required: true }}
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      options={offices}
                      size={'small'}
                      getOptionLabel={(option) => (option ? option.title : '')}
                      getOptionSelected={(option) => option.value._id === data['office']?._id}
                      onChange={(e, newValue: OptionItem<IOffice> | null) => {
                        newValue && changeHandler(newValue);
                      }}
                      //@ts-ignore
                      value={offices.find((office) => office.value._id === data.office._id) || null}
                      renderInput={(params) => (
                        <TextField {...params} label="Офис" error={!!errors.office} required variant="outlined" />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name={'personalData.nickname'}
                  rules={{ required: false }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Никнейм'}
                      error={!!errors.personalData?.nickname}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
              <Button type="submit" onClick={handleSubmit(nextStep)} variant={'contained'}>
                Далее
              </Button>
            </Box>
          </form>
        )}
        {step === 2 && (
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={12} md={4}>
                <Controller
                  name={'personalData.passportSeries'}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Серия'}
                      required
                      type={'number'}
                      error={!!errors.personalData?.passportSeries}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={12} md={4}>
                <Controller
                  name={'personalData.passportNumber'}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Номер'}
                      required
                      type={'number'}
                      error={!!errors.personalData?.passportNumber}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="personalData.passportDate"
                  render={(props) => (
                    <ReactDatePicker
                      locale={ru}
                      withPortal
                      portalId="create-user-date-portal"
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(e) => {
                        setValue('personalData.passportDate', e);
                      }}
                      selected={props.value}
                      dateFormat="MM/dd/yyyy"
                      customInput={
                        <TextField
                          label="Дата выдачи"
                          size={'small'}
                          required
                          error={!!errors?.personalData?.passportDate}
                          fullWidth={true}
                          variant="outlined"
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={9} sm={9} md={9}>
                <Controller
                  name={'personalData.passportAdditional'}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextField
                      {...props}
                      label={'Кем выдан'}
                      multiline
                      minRows={2}
                      maxRows={5}
                      required
                      error={!!errors.personalData?.passportAdditional}
                      fullWidth
                      size={'small'}
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
                <Box display={'flex'} alignItems={'center'}>
                  <FormControlLabel
                    control={<Checkbox checked={isRussianPassport} onChange={togglePassportValidation} />}
                    label="Паспорт РФ"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
              <Button variant={'contained'} type="submit" onClick={handleSubmit(nextStep)}>
                Далее
              </Button>
            </Box>
          </form>
        )}

        {step === 3 && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography style={{ margin: '10px 0  15px' }} className={props.classes.cardTitleWhite}>
                  Приложите фото документа c обеих сторон
                </Typography>
              </Box>
              <AttachmentsView
                attachments={images}
                entityId={currentEmployee?._id}
                entityName={'documents'}
                setAttachments={setImages}
                setAttachmentsToRemove={setAttachmentsToRemove}
                setIsLoading={() => null}
                isUploadUserDocs
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
                <Button
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={15} color="secondary" />}
                  type="submit"
                  variant={'contained'}
                  fullWidth
                  onClick={handleSubmit(submit)}
                  color={'primary'}
                >
                  Добавить
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(CreateUser);
