import { createSlice } from '@reduxjs/toolkit';

import { changePassword, login, logout } from 'redux/login/auth.actions';
import { AuthState } from 'redux/login/auth.types';

import { getAccessToken } from './auth.helpers';

const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: !!getAccessToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state) => {
      state.isLoggedIn = true;
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoggedIn = false;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default authSlice.reducer;
