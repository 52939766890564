import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import OrdersApi from '@/api/orders';
import { ISortPayload } from '@/types/common';
import {
  ORDERS_GET_CLIENT,
  ORDERS_CLEAR_FILTERS,
  ORDERS_CLEAR_SORTING,
  ORDERS_FILTERS,
  ORDERS_SET_FILTERS,
  ORDERS_SET_SORTING,
} from 'redux/orders/orders.types';

export const clearOrderFilter = createAction(ORDERS_CLEAR_FILTERS);

export const setOrdersFilters = createAction(ORDERS_SET_FILTERS, (payload) => {
  window.localStorage.setItem(ORDERS_FILTERS, JSON.stringify(payload));
  return { payload };
});

export const setOrdersSorting = createAction(ORDERS_SET_SORTING, (payload: Array<ISortPayload>) => {
  return {
    payload: {
      [payload[0].id]: payload[0].desc ? '-1' : '1',
    },
  };
});

export const clearOrdersSorting = createAction(ORDERS_CLEAR_SORTING);

export const getClientOrders = createAsyncThunk(
  ORDERS_GET_CLIENT,
  async ({ id, params }: { id: string; params: Record<string, any> }, { rejectWithValue }) => {
    return OrdersApi.getClientOrders(id, params)
      .then((response) => {
        return {
          total: response.data?.totalCount || 0,
          result: response?.data.result || [],
        };
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);
