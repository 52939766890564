import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { createCategory, deleteCategory, getCategories, updateCategory } from 'redux/categories/categories.actions';

import { ICategory, ICategoryState } from './categories.types';

const initialState: ICategoryState = {
  data: [],
  status: IFetchingStatuses.success,
  newCategoryToUpdateId: '',
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setNewCategoryToUpdateId: (state, { payload }) => {
      state.newCategoryToUpdateId = payload;
    },
    setCategories: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.data = payload.data;
    });
    builder.addCase(createCategory.fulfilled, (state, { payload }) => {
      state.data = [...state.data, payload.category as ICategory];
    });
    builder.addCase(updateCategory.fulfilled, (state, { meta: { arg } }) => {
      // if (payload) {
      //   state.data = [
      //     ...state.data.map((cat) => {
      //       return cat._id === payload._id ? { ...payload } : cat;
      //     }),
      //   ];
      // }
    });
    builder.addMatcher(isAnyOf(createCategory.fulfilled, updateCategory.fulfilled), (state) => {
      state.status = IFetchingStatuses.success;
    });
    builder.addMatcher(isAnyOf(createCategory.pending, updateCategory.pending), (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addMatcher(
      isAnyOf(createCategory.rejected, updateCategory.rejected, getCategories.rejected, deleteCategory.rejected),
      (state) => {
        state.status = IFetchingStatuses.error;
      },
    );
  },
});

export const { setNewCategoryToUpdateId, setCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
