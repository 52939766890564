import { IFilters, IStatuses } from '@/types/common';
import { IOffice } from 'redux/office/office.types';
import { IUserAssignee } from 'redux/user/user.types';

export const STATISTICS_SET_FILTERS = 'STATISTICS_SET_FILTERS';
export const STATISTICS_CLEAR_FILTERS = 'STATISTICS_CLEAR_FILTERS';

export const LOST_CLIENTS_SET_FILTERS = 'LOST_CLIENTS_SET_FILTERS';
export const LOST_CLIENTS_CLEAR_FILTERS = 'LOST_CLIENTS_CLEAR_FILTERS';

export interface SalesStatistic {
  refill: number;
  date: string;
}

export interface WorkloadStatistic {
  date: string;
  load: number;
}

export interface EfficiencyStatistic {
  date: string;
  total: number;
  success: number;
}

export interface IStatsEventTypes {
  accountQuestion: Array<string>;
  meeting: Array<string>;
  consultation: Array<string>;
  lastPractice: Array<string>;
  lecture: Array<string>;
  practice: Array<string>;
  refill: Array<string>;
  registration: Array<string>;
  interview: Array<string>;
  internship: Array<string>;
  firstSale: Array<string>;
  recruitment: Array<string>;
  service: Array<string>;
  loyaltyPromotion: Array<string>;
}

export interface PlanStatistic {
  goal: number;
  period: string;
}

export type ParsedEventStatisticItem = {
  events: IStatsEventTypes;
  sales: Array<SalesStatistic>;
  workload: Array<WorkloadStatistic>;
  plans: Array<PlanStatistic>;
  efficiency: Array<EfficiencyStatistic>;
  _id: string;
  assignee: IUserAssignee & Pick<IOffice, '_id' | 'name'>;
};

export type UserStats = {
  [key in keyof IStatsEventTypes]: number;
};

export interface StatisticState {
  data: ParsedEventStatisticItem[];
  currentUserStatistic: ParsedEventStatisticItem;
  status: IStatuses;
  filters: IFilters;
  clientsFilters: IFilters;
}
