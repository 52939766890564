import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: 250,
    display: 'flex',
    gap: 10,
    flexWrap: 'nowrap',
    padding: 10,
  },
  popover: {
    marginTop: theme.spacing(2),
  },
  title: {
    padding: '10px',
    fontSize: 14,
    fontWeight: 400,
  },
}));
