import React, { useState } from 'react';

import { Button, Checkbox, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { EVENTS_FOR_FILTERS } from '@/utils/constants';
import { IClients } from 'components/common/ClientsList/ClientsList';
import SnippetWrapper from 'components/CustomComponents/CustomSnippetWrapper';

import useStyles from './FilterClients.styles';

interface FilterClientProps {
  clients: Array<IClients>;
  handleFiltersClient: (eventName: string[] | null, allClients: Array<IClients>) => void;
  handleRemoveFilters: () => void;
}

const FilterClients = ({ clients, handleFiltersClient, handleRemoveFilters }: FilterClientProps) => {
  const [activeEvent, setActiveEvent] = useState<Array<string>>([]);
  const classes = useStyles();
  const eventsFilters = EVENTS_FOR_FILTERS;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChangeEvent = (value: string[] | null) => {
    if (value) {
      setActiveEvent(value);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Typography>Тип событий</Typography>
      <div className={classes.filterWrapper}>
        <Autocomplete
          id="combo-box-demo"
          size={'small'}
          multiple
          disableClearable
          renderTags={() => null}
          options={eventsFilters}
          fullWidth
          onChange={(_, value) => handleChangeEvent(value)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(option) => (
            <Box>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={activeEvent.includes(option)} />
              {option}
            </Box>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Выбрать событие" placeholder="Найти событие" />
          )}
        />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          fullWidth
          size={'small'}
          onClick={() => handleFiltersClient(activeEvent, clients)}
          variant={'outlined'}
          color={'secondary'}
        >
          Выбрать
        </Button>
        <Button
          fullWidth
          size={'small'}
          onClick={() => {
            handleRemoveFilters();
            setActiveEvent([]);
          }}
          variant={'outlined'}
          color={'secondary'}
        >
          Очистить
        </Button>
      </div>
    </div>
  );
};

export default FilterClients;
