import axios from 'api';

import { _clientListForAutoComplete } from './selectVariants';

export const getClientsForAutocomplete = async (query: string) => {
  try {
    const url = '/api/clients';
    const data = await axios.get(url + query).then((res) => res.data);
    return {
      options: data.result.length ? _clientListForAutoComplete(data.result) : [],
      total: data.totalCount.length ? data.totalCount[0].total : 0,
      isLoading: false,
    };
  } catch (e) {
    console.log(e);
  }
};
