import { IFilters, ISorting, IStatuses } from '@/types/common';

export interface OrdersState {
  orders: IOrders[];
  status: IStatuses;
  totalOrdersCount: number;
  filters: IFilters;
  sort: ISorting;
}

export interface IOrders {
  clientId: string;
  symbol: string;
  dateOpen: string;
  dateClose: string;
  action: string;
  profit: number | string;
  terminal: TerminalProvider;
}

export enum TerminalProvider {
  Metatrader = 'metatrader',
  Mobius = 'mobius',
  Modera = 'modera',
}

export const ORDERS_GET_CLIENT = 'GET_CLIENT_ORDERS';
export const ORDERS_FILTERS = 'ORDERS_FILTERS';
export const ORDERS_CLEAR_FILTERS = 'ORDERS_CLEAR_FILTERS';
export const ORDERS_SET_FILTERS = 'ORDERS_SET_FILTERS';
export const ORDERS_SET_SORTING = 'ORDERS_SET_SORTING';
export const ORDERS_CLEAR_SORTING = 'ORDERS_CLEAR_SORTING';
