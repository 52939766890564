import { useMemo } from 'react';

import { useRouter } from 'next/router';

import { clientFilterSelector } from 'redux/client/client.selectors';
import { useAppSelector } from 'redux/hooks';

export const useClientFilters = () => {
  const router = useRouter();
  const filters = useAppSelector(clientFilterSelector);

  return useMemo(() => {
    if (router.pathname === '/black-list') {
      const { client, mobile, email, tags, office, assignee } = filters ?? {};
      return {
        client,
        mobile,
        email,
        tags,
        office,
        assignee,
      };
    }
    if (router.pathname === '/clients') {
      const { client, clientId, mobile, email, tags, office, assignee } = filters ?? {};
      return {
        client,
        clientId,
        mobile,
        email,
        tags,
        office,
        assignee,
      };
    }
    if (router.pathname === '/lost-clients') {
      const { client, event, office, deadline, assignee } = filters ?? {};
      return { client, event, office, deadline, assignee };
    }
  }, [router.pathname, filters]);
};
