import axios from 'api';
import { UpdateNotificationsRequest } from 'redux/notifications/notifications.types';

const url = '/api/notifications';

const NotificationsAPI = {
  getAll: (params: Record<string, string | number>) => axios.get(url, { params }),
  updateAll: (data: UpdateNotificationsRequest) => axios.post(url, data),
};
export default NotificationsAPI;
