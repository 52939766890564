//@ts-nocheck
import React from 'react';

import classNames from 'clsx';

import useStyles from './CustomPin.styles';

const CustomPin = ({ status, ...props }) => {
  const classes = useStyles();

  const statusTypeStyle = () => {
    const type = status?.trim().toLowerCase();
    switch (type) {
      case 'запланировано': {
        return { type: 'inProgress' };
      }
      case 'обработано': {
        return { type: 'warning' };
      }
      case 'выполнено': {
        return { type: 'success' };
      }
      case 'отменено': {
        return { type: 'error' };
      }
      case 'просрочено': {
        return { type: 'error' };
      }
      case 'в работе': {
        return { type: 'inProgress' };
      }
      case 'запланирован': {
        return { type: 'warning' };
      }
      default:
        return { type: '' };
    }
  };
  const style = statusTypeStyle();

  const badgeClasses = classNames({
    [classes.circle]: true,
    [classes[style.type]]: true,
  });

  return (
    <div className={classes.container}>
      <div className={badgeClasses} />
      <span className={classes.text}>{props.children}</span>
    </div>
  );
};

export default CustomPin;
