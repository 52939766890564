import { IFetchingStatuses } from '@/types/common';
import { ITopic } from 'redux/topics/topics.types';

export const CATEGORIES_GET_ALL = 'CATEGORIES_GET_ALL';
export const CATEGORIES_CREATE = 'CATEGORIES_CREATE';
export const CATEGORIES_UPDATE = 'CATEGORIES_UPDATE';
export const CATEGORIES_MOVE_TOPIC = 'CATEGORIES_MOVE_TOPIC';
export const CATEGORY_DELETE = 'CATEGORY_DELETE';
export const CATEGORY_GET_CURRENT = 'CATEGORY_GET_CURRENT';

export interface ICategory {
  _id: string;
  name: string;
  owner: string;
  publicAccess: boolean;
  topics: Array<Pick<ITopic, '_id' | 'title'>>;
  officeAccess: Array<string>;
  hasNewTopics: boolean;
  createdAt: string | Date;
}

export interface ICategoryState {
  data: Array<ICategory>;
  status: IFetchingStatuses;
  newCategoryToUpdateId: string;
}

export interface GetCategoriesResponse {
  data: Array<ICategory>;
}

export interface GetCategoryResponse {
  topics: Array<Partial<ITopic>>;
}

export interface UpdateCategoryRequest extends Partial<ICategory> {
  newCategoryId?: string;
  topicId?: string;
}

export interface BaseCategoryResponse {
  category: ICategory;
  message: string;
}
export interface MoveTopicCategory {
  _id: string;
  topicId: string;
}

export interface ChangeTopicCategoryProps {
  currentCategoryId: string | null;
  newCategoryId: string;
  topicId: string;
}
