import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { deleteOffice, getOffices, patchOffice, postOffice, toggleOfficeActive } from 'redux/office/office.actions';
import { officeApi } from 'redux/office/office.service';

import { IOfficeState } from './office.types';

const initialState: IOfficeState = {
  data: [],
  isLoading: false,
  filters: {},
  status: null,
};

const officeSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    setOfficeFilters(state, { payload }) {
      state.filters = payload;
    },
    clearOfficeFilters(state) {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(patchOffice.fulfilled, (state, { payload }) => {
        const index = state.data.findIndex((office) => office._id === payload._id);
        if (index !== -1) {
          state.data[index] = payload;
        }
        state.status = IFetchingStatuses.success;
      })
      .addCase(deleteOffice.fulfilled, (state, { payload }) => {
        state.status = IFetchingStatuses.success;
        state.data.filter((office) => office._id !== payload);
      })
      .addCase(toggleOfficeActive.fulfilled, (state, { payload }) => {
        state.status = IFetchingStatuses.success;
        state.data = state.data.map((office) => (office._id === payload._id ? payload : office));
      })
      .addMatcher(officeApi.endpoints.getAllOffices.matchFulfilled, (state, { payload }) => {
        state.status = IFetchingStatuses.success;
        state.data = payload;
      })
      .addMatcher(officeApi.endpoints.getAllOffices.matchPending, (state) => {
        state.status = IFetchingStatuses.pending;
      })
      .addMatcher(isAnyOf(getOffices.pending, postOffice.pending, patchOffice.pending, deleteOffice.pending), (state) => {
        state.status = IFetchingStatuses.pending;
      })
      .addMatcher(isAnyOf(getOffices.rejected, postOffice.rejected, patchOffice.rejected, deleteOffice.rejected), (state) => {
        state.status = IFetchingStatuses.error;
      });
  },
});
export const { setOfficeFilters, clearOfficeFilters } = officeSlice.actions;
export default officeSlice.reducer;
