import React, { ChangeEvent, useEffect, useState } from 'react';

import { Button, TextField, Typography } from '@material-ui/core';

import { CheckBoxAutocompleteOptionItem } from '@/types/common';
import CheckBoxAutocomplete from 'components/common/CheckBoxAutocomplete';
import { closeModal } from 'redux/app/app.actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postMessage } from 'redux/message/message.actions';
import { officeOptionsSelector } from 'redux/office/office.selectors';

import useStyles from './CreateMessageModal.styles';

const CreateMessageModal = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [offices, setOffices] = useState<CheckBoxAutocompleteOptionItem<string>[]>([]);
  const officeOptions = useAppSelector(officeOptionsSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOffices(officeOptions);
  }, []);

  const handleChangeTextInputValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (message && offices.length) {
      const officeIds = offices.filter((office) => office.isActive).map((office) => office.value);
      dispatch(postMessage({ message, offices: officeIds }));
      dispatch(closeModal());
    }
  };

  return (
    <div>
      <Typography variant={'h6'}>Введите текст приветственного сообщения</Typography>
      <TextField
        className={classes.textField}
        onChange={handleChangeTextInputValue}
        fullWidth={true}
        label="Текст сообщения"
        type={'text'}
        size={'small'}
        variant="outlined"
        multiline={true}
        rows={10}
      />
      <CheckBoxAutocomplete
        data={offices}
        setData={setOffices}
        textFieldLabel={'Выберите офис'}
        textFieldPlaceholder={'Найти офис...'}
        isUseSetAll={true}
      />
      <Button
        onClick={handleSubmit}
        type={'button'}
        style={{ margin: '20px 0' }}
        variant={'contained'}
        color={'primary'}
        fullWidth
        disabled={false}
      >
        Отправить
      </Button>
    </div>
  );
};

export default CreateMessageModal;
