import { IStatuses } from '@/types/common';
import { IUser } from 'redux/user/user.types';

export const PROFILE_CLEAR_DATA = 'PROFILE_CLEAR_DATA';
export const PROFILE_GET_DATA = 'PROFILE_GET_DATA';
export const PROFILE_SET_DATA = 'PROFILE_SET_DATA';
export const PROFILE_SET_AVATAR = 'PROFILE_SET_AVATAR';
export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD';

export interface IProfileState {
  data: IUser;
  status: IStatuses;
}

export interface UploadAvatarRequest {
  file: File;
  _id: string;
}
