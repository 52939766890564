import { makeStyles } from '@material-ui/core/styles';

export const mainLayoutStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    height: 60,
    //@ts-ignore
    backgroundColor: theme.palette.common.black,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
    marginLeft: 0,
  },

  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      gridGap: 15,
      maxWidth: 'auto',
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 105,
    },
  },
}));
