import { HTTP_METHODS } from '@/types/common';
import { commonApi } from 'redux/commonApi';
import { IOffice, IOfficeWithUsers } from 'redux/office/office.types';

const url = 'api/offices';
const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['Office', 'Offices'] });

export const officeApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllOffices: builder.query<IOffice[], Record<string, string>>({
      query: (params) => ({
        url,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: ['Offices'],
    }),
    getCurrentOffice: builder.query<IOfficeWithUsers, string>({
      query: (id) => ({
        url: `${url}/${id}`,
        method: HTTP_METHODS.get,
      }),
      providesTags: ['Office'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetCurrentOfficeQuery, useGetAllOfficesQuery } = officeApi;
