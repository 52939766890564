import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import OperationsAPI from '@/api/operations';
import { showSuccessAlert } from 'redux/app/app.actions';
import { CLEAR_OPERATIONS_STATE, GET_OPERATIONS, IOperation } from 'redux/operations/operations.types';

export const getClientOperations = createAsyncThunk(GET_OPERATIONS, async (id: string, { dispatch, rejectWithValue }) => {
  return OperationsAPI.getClientOperations(id)
    .then((response): IOperation[] => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(`Error ${error}`);
    });
});
export const deleteClientOperations = createAsyncThunk(GET_OPERATIONS, async (id: string, { dispatch, rejectWithValue }) => {
  return OperationsAPI.deleteClientOperations(id)
    .then((response): IOperation[] => {
      dispatch(showSuccessAlert('Операции успешно удалены!'));
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(`Error ${error}`);
    });
});

export const clearOperationsState = createAction(CLEAR_OPERATIONS_STATE);
