export interface MessageState {
  message: string;
}

export const MESSAGE_CREATE = 'MESSAGE_CREATE';
export const GET_LAST_MESSAGE = 'GET_LAST_MESSAGE';

export interface IMessage {
  message: string;
  offices: Array<string>;
}

export interface GetLastMessageProps {
  date: string;
}
