import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  frameContainer: {
    height: 500,
    width: '100%',
  },
  appBar: {
    width: '100%',
    height: '50px',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default useStyles;
