import React, { useEffect, useState } from 'react';

import { Button, MenuItem, TextField } from '@material-ui/core';
import ru from 'date-fns/locale/ru';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';

import { roles } from '@/MOCK/quickCreationMockData';
import { IStorageItemData, removeFileFromCloudSpace } from '@/utils/fileStorage';
import AllowedForSelector from 'components/common/AllowedForSelector';
import Overlay from 'components/common/Overlay';
import AttachmentsView from 'components/Topics/AttachmentsView';
import MarkDownField from 'components/Topics/MarkDownField';
import UsersSelector from 'components/Topics/UsersSelector';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { createTopic, getTopics, updateTopic } from 'redux/topics/topics.actions';
import { ITopic, TopicStatuses } from 'redux/topics/topics.types';

import { useStyles } from './CreateTopicModal.styles';

const CreateTopicModal = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const topicData = useAppSelector<ITopic | null>(modalDataSelector);
  const [attachments, setAttachments] = useState<Array<IStorageItemData>>([]);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultValues: Partial<ITopic> = {
    title: '',
    deadline: undefined,
    attachments: [],
    assignees: [],
    roleAccess: [],
    personalAccess: [],
    status: TopicStatuses.NOT_STARTED,
  };

  const { errors, control, handleSubmit, setValue, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    ...defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    const defaults = {
      title: topicData?.title || '',
      deadline: topicData?.deadline ? new Date(topicData?.deadline as Date) : undefined,
      attachments: topicData?.attachments?.length ? topicData.attachments : [],
      assignees: topicData?.assignees?.length ? topicData?.assignees : [],
      description: topicData?.description || null,
      roleAccess: topicData?.roleAccess || [],
      personalAccess: topicData?.personalAccess || [],
      status: topicData?.status || TopicStatuses.NOT_STARTED,
    };
    reset(defaults);
  }, [reset]);

  useEffect(() => {
    if (topicData?.attachments.length) {
      setAttachments(topicData.attachments);
    }
  }, []);

  const submit = async (data: Partial<ITopic>): Promise<void> => {
    const topic = { ...data, attachments };
    if (!topicData) {
      setIsLoading(true);
      dispatch(createTopic(topic))
        .unwrap()
        .then(() => {
          dispatch(getTopics({}));
          dispatch(closeModal());
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      if (attachmentsToRemove.length) {
        const filesToRemove: Promise<void>[] = attachmentsToRemove.map(async (attachmentKey) => {
          await removeFileFromCloudSpace(attachmentKey);
        });
        await Promise.all(filesToRemove);
      }
      dispatch(updateTopic({ ...topic, _id: topicData._id }))
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const filterPassedTime = (time: Date): boolean => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      {isLoading && <Overlay />}
      <div className={classes.flexRow}>
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <TextField
              value={value ?? ''}
              label="Teма"
              error={!!errors.title}
              variant="outlined"
              fullWidth
              onChange={(e) => setValue('title', e.target.value)}
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <TextField
              value={value ?? ''}
              label="Статус"
              error={!!errors.status}
              variant="outlined"
              fullWidth
              select
              onChange={(e) => setValue('status', e.target.value)}
            >
              {Object.values(TopicStatuses).map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      <Controller
        name="description"
        control={control}
        rules={{ validate: (value) => !!value?.blocks[0]?.text || !!value?.entityMap[0] }}
        render={(props) => (
          <MarkDownField
            defaultValue={props.value}
            inputProps={props}
            error={!!errors.description}
            setDescription={(value) => setValue('description', value)}
          />
        )}
      />

      <div className={classes.flexRow}>
        <Controller
          name="assignees"
          control={control}
          rules={{
            validate: (value) => value && !!value.length,
          }}
          render={(props) => (
            <UsersSelector
              inputProps={props}
              error={!!errors.assignees}
              label={'Ответственные'}
              placeholder={'Выберите ответственных'}
              setUsers={(value) => {
                setValue('assignees', value);
              }}
            />
          )}
        />
        <Controller
          name="deadline"
          control={control}
          rules={{ required: true }}
          render={({ value }) => (
            <div>
              <ReactDatePicker
                locale={ru}
                onChange={(e) => {
                  setValue('deadline', e);
                }}
                selected={value}
                showTimeSelect
                minDate={new Date()}
                filterTime={filterPassedTime}
                timeFormat="HH:mm"
                timeIntervals={10}
                dateFormat="dd/MM/yyyy HH:mm "
                customInput={
                  <TextField label="Дедлайн" required fullWidth error={!!errors.deadline} size={'small'} variant="outlined" />
                }
              />
            </div>
          )}
        />
      </div>
      <div className={classes.flexRow}>
        <Controller
          name="personalAccess"
          control={control}
          render={(props) => (
            <UsersSelector
              inputProps={props}
              error={!!errors.personalAccess}
              label={'Персональный доступ'}
              placeholder={'Выберите пользователей'}
              setUsers={(value) => {
                setValue('personalAccess', value);
              }}
            />
          )}
        />
        <Controller
          name="roleAccess"
          control={control}
          render={(props) => (
            <AllowedForSelector
              values={props.value || []}
              setSelectedItems={(e) => setValue('roleAccess', e)}
              label={'Доступ для ролей'}
              targetObject={roles}
            />
          )}
        />
      </div>
      <AttachmentsView
        setIsLoading={setIsLoading}
        entityId={topicData?._id}
        entityName={'topics'}
        attachments={attachments}
        setAttachmentsToRemove={setAttachmentsToRemove}
        setAttachments={setAttachments}
      />
      {!topicData ? (
        <Button type={'submit'} disabled={isLoading}>
          Создать
        </Button>
      ) : (
        <Button type={'submit'} disabled={isLoading}>
          Обновить
        </Button>
      )}
    </form>
  );
};

export default CreateTopicModal;
