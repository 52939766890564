import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '5px',
    padding: '5px',
    transition: 'all 0.3s ease',
    '& > div': {
      height: '100%',
      width: '100%',
    },
  },
}));
