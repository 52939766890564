import { HTTP_METHODS } from '@/types/common';
import { removeDuplicateItemById } from '@/utils/utils';
import { commonApi } from 'redux/commonApi';
import { CreateRatingFileDto, IRatingItem, RatingFile } from 'redux/ratings/rating.types';

const url = 'api/ratings';

const apiWithTags = commonApi.enhanceEndpoints({ addTagTypes: ['Ratings', 'RatingFiles'] });

export const ratingApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllRatings: builder.query<IRatingItem[], Record<string, string>>({
      query: (params) => ({
        url,
        method: HTTP_METHODS.get,
        params,
      }),
      providesTags: (result) => {
        return result && result
          ? [
              ...result.map(({ _id }) => ({
                type: 'Ratings' as const,
                id: _id,
              })),
              'Ratings',
            ]
          : ['Ratings'];
      },
      transformResponse: (response) => {
        const plansList = response as unknown as IRatingItem[];
        return plansList && plansList.length ? removeDuplicateItemById<IRatingItem>(plansList) : [];
      },
    }),
    getAllRatingFiles: builder.query<RatingFile[], void>({
      query: () => ({
        url: `${url}/media`,
        method: HTTP_METHODS.get,
      }),
      providesTags: (result) => {
        return result && result
          ? [
              ...result.map(({ _id }) => ({
                type: 'RatingFiles' as const,
                id: _id,
              })),
              'RatingFiles',
            ]
          : ['RatingFiles'];
      },
    }),
    createRatingFile: builder.mutation<IRatingItem, CreateRatingFileDto>({
      query: (data) => ({
        url: `${url}/media`,
        method: HTTP_METHODS.post,
        data,
      }),
      invalidatesTags: ['RatingFiles'],
    }),
    deleteRatingFile: builder.mutation<void, string>({
      query: (id) => ({
        url: `${url}/media/${id}`,
        method: HTTP_METHODS.delete,
      }),
      invalidatesTags: ['RatingFiles'],
    }),
    updateRatingFile: builder.mutation({
      query: ({ _id, ...data }) => ({
        url: `${url}/media/${_id}`,
        method: HTTP_METHODS.patch,
        data,
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? [
              { type: 'RatingFiles', id: arg?._id },
              { type: 'RatingFiles', id: 'LIST' },
            ]
          : [],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllRatingsQuery,
  useCreateRatingFileMutation,
  useGetAllRatingFilesQuery,
  useUpdateRatingFileMutation,
  useDeleteRatingFileMutation,
} = ratingApi;
