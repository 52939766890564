import React, { useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Head from 'next/head';
import { Provider } from 'react-redux';

import BaseLayout from '../src/layouts/BaseLayout';
import { store } from '../src/redux/store';
import { darkTheme } from '../src/styles/theme';

import 'style/scss/plugins/_plugin-react-datetime.scss';
import '../src/styles/global.css';

export default function MyApp(props) {
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (window.location.protocol === 'http:' && process.env.NODE_ENV !== 'development') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>My page</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <Provider store={store}>
        <ThemeProvider theme={darkTheme}>
          <BaseLayout>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} />
          </BaseLayout>
        </ThemeProvider>
      </Provider>
    </React.Fragment>
  );
}
