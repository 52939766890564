export const APPLICANT = 'applicant';
export const TRAINEE = 'trainee';
export const MANAGER = 'manager';
export const SENIORMANAGER = 'seniorManager';
export const TOPMANAGER = 'topManager';
export const SALESHEADMANAGER = 'salesHeadManager';
export const REGIONALDIRECTOR = 'regionalDirector';
export const GROUPOFREGIONDIRECTOR = 'groupOfRegionDirector';
export const DEVELOPMENTDIRECTORDEPUTY = 'developmentDirectorDeputy';
export const DEVELOPMENTDIRECTOR = 'developmentDirector';
export const ADMIN = 'admin';
export const ACCOUNTANT = 'accountant';
export const ATTRACTIONMANAGER = 'attractionManager';
export const ATTRACTIONHEADMANAGER = 'attractionHeadManager';
export const HR = 'HR';
export const OPERATOR = 'operator';
export const ANALYTIC = 'analytic';
// export const SALESMANAGER = 'salesManager';
// export const SENIORSALESMANAGER = 'seniorSalesManager'
export const HEADOFATTRACTIONDEPARTMENT = 'headOfAttractionDepartment';
