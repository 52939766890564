import React, { FC, useEffect, useState } from 'react';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import dynamic from 'next/dynamic';
import { EditorProps, RawDraftContentState } from 'react-draft-wysiwyg';
import { ControllerRenderProps } from 'react-hook-form';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useStyles } from 'components/Topics/MarkDownField/MarkDownField.styles';

interface IMarkDownFieldProps {
  setDescription: (value: RawDraftContentState) => void;
  error: boolean;
  inputProps: ControllerRenderProps;
  defaultValue?: RawDraftContentState;
}

// обходим ssr
const Editor = dynamic<EditorProps>(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), { ssr: false });

const MarkDownField: FC<IMarkDownFieldProps> = ({ setDescription, defaultValue, error }) => {
  const classes = useStyles();
  // по дефолту надо создать пустое состояние JSON объекта
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue && !isReady) {
      const convertedValue = convertFromRaw(defaultValue);
      setEditorState(EditorState.createWithContent(convertedValue)); // если есть содержимое конвертируем его в формат редактора
      setIsReady(true);
    }
  }, [defaultValue, isReady]);

  const handleChange = (e: EditorState) => {
    setEditorState(e);
    const convertedContent = convertToRaw(editorState.getCurrentContent());
    setDescription(convertedContent); // передаем коллбеком данные для сохранения в базу
  };

  return (
    <div className={classes.root} style={{ borderColor: error ? 'red' : 'white' }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbarStyle={{ borderRadius: '5px 5px 0 0', marginBottom: '0', color: 'black' }}
        localization={{ locale: 'ru' }}
        stripPastedStyles={true}
        editorStyle={{ background: 'white', color: 'black', minHeight: '130px', borderRadius: '0 0 5px 5px', padding: '10px' }}
      />
    </div>
  );
};

export default MarkDownField;
