//@ts-nocheck
import { useEffect, useState } from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAsyncDebounce } from 'react-table';

const LazyAutocomplete = ({ label, fetchData, entity, changeOptions, error, defaultValue = '', size = 'medium' }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalOptions, setTotalOptions] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const loading = open && isLoading;

  const handleDebounceChange = useAsyncDebounce((value) => {
    setOpen(true);
    setSearchValue(value || undefined);
  }, 500);

  useEffect(() => {
    setIsLoading(true);

    async function getOptions(query) {
      const result = await fetchData(query);
      setOptions(result.options);
      setTotalOptions(result.total);
      setIsLoading(result.isLoading);
    }

    if (open && searchValue) {
      const value = searchValue.trim().replace(' ', '+');
      const params = `?${entity}=${value}&limit=1000`;
      getOptions(params);
    }
  }, [searchValue, open]);
  return (
    <>
      {defaultValue ? (
        <TextField label={label} fullWidth variant="outlined" value={defaultValue} />
      ) : (
        <Autocomplete
          open={open}
          onClose={() => {
            setOpen(false);
            setSearchValue('');
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => (option ? option.title : '')}
          options={options}
          size={size}
          loading={loading}
          loadingText="Ищем совпадения"
          onChange={(e, newValue) => {
            changeOptions(e, newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={error}
              InputProps={{
                ...params.InputProps,
                onChange: (e) => {
                  handleDebounceChange(e.target.value);
                },
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="secondary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    </>
  );
};
export default LazyAutocomplete;
