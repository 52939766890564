import { createAsyncThunk } from '@reduxjs/toolkit';

import MessageAPI from '@/api/message';
import { openWelcomeModal, showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { GET_LAST_MESSAGE, GetLastMessageProps, IMessage, MESSAGE_CREATE } from 'redux/message/message.types';

export const postMessage = createAsyncThunk(MESSAGE_CREATE, async (data: IMessage, { dispatch, rejectWithValue }) => {
  return MessageAPI.create(data)
    .then(() => {
      dispatch(showSuccessAlert('Сообщение успешно добавлено!'));
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
    });
});

export const getLastMessage = createAsyncThunk(
  GET_LAST_MESSAGE,
  async (params: GetLastMessageProps, { rejectWithValue, dispatch }) => {
    return MessageAPI.getOne(params)
      .then((res) => {
        dispatch(openWelcomeModal(res.data));
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue(error?.response?.data);
      });
  },
);
