import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 360,
    overflow: 'auto',
    minWidth: 200,
  },
  userWrapper: {
    margin: '10px 0',
    borderRadius: 4,
    cursor: 'pointer',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
