import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  LOST_CLIENTS_CLEAR_FILTERS,
  LOST_CLIENTS_SET_FILTERS,
  STATISTICS_CLEAR_FILTERS,
  STATISTICS_SET_FILTERS,
} from './statistic.types';

export const setStatisticsFilters = createAction(STATISTICS_SET_FILTERS, (payload) => ({ payload }));
export const clearStatisticsFilters = createAction(STATISTICS_CLEAR_FILTERS);

export const getUserStatistic = createAsyncThunk('statistics/singleUserStatistic', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/');
  } catch (err: any) {
    return rejectWithValue(err.data?.message);
  }
});

export const setLostClientsFilters = createAction(LOST_CLIENTS_SET_FILTERS, (payload) => ({ payload }));
export const clearLostClientsFilters = createAction(LOST_CLIENTS_CLEAR_FILTERS);
