import React, { useState } from 'react';

import { Button, Divider, Grid, Popover, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import cx from 'clsx';
import { useDispatch } from 'react-redux';

import { modalNameTranslate } from '@/utils/modalsList';
import { showModal } from 'redux/app/app.actions';
import { ModalSizes } from 'redux/app/app.types';

import { useStyles } from './styled.creation';

interface QuickCreationProps {
  isAllowedCreateUser: boolean;
  isAllowedCreateOffice: boolean;
  isAllowedCreateClient: boolean;
  isAllowedCreateMessage: boolean;
  hiddenElements: any;
}

const QuickCreation: React.FC<QuickCreationProps> = ({
  isAllowedCreateUser,
  isAllowedCreateOffice,
  isAllowedCreateClient,
  isAllowedCreateMessage,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeMenu, setActiveMenu] = useState<HTMLButtonElement | null>(null);

  const handleQuickCreationClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setActiveMenu(event.currentTarget);
  };
  const handleClosePopover = (): void => {
    setActiveMenu(null);
  };

  const dispatchModalName = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const modalName = event.currentTarget.dataset?.name;
    const size = event.currentTarget.dataset?.size as ModalSizes;
    dispatch(
      showModal({
        name: modalName as string,
        type: 'modal',
        data: null,
        title: modalNameTranslate[modalName as string],
        size: size || 'sm',
      }),
    );
    setActiveMenu(null);
    return;
  };

  return (
    <>
      <IconButton size={'medium'} color={'secondary'} onClick={handleQuickCreationClick}>
        <AddCircleOutlineRoundedIcon />
      </IconButton>

      <Popover
        className={classes.popover}
        anchorEl={activeMenu}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!activeMenu}
      >
        <Grid item sm={'auto'} md={'auto'}>
          <Box display={'flex'} className={cx({ [classes.wrapper]: isAllowedCreateUser || isAllowedCreateOffice })}>
            {isAllowedCreateUser && (
              <Button
                data-name="createEmployee"
                fullWidth
                variant={'contained'}
                color={'default'}
                size={'small'}
                onClick={dispatchModalName}
              >
                Сотрудник
              </Button>
            )}
            {isAllowedCreateOffice && (
              <Button
                fullWidth
                data-name="createOffice"
                variant={'contained'}
                color={'default'}
                size={'small'}
                onClick={dispatchModalName}
              >
                Офис
              </Button>
            )}
            {isAllowedCreateMessage && (
              <Button
                fullWidth
                data-name="createMessage"
                variant={'contained'}
                color={'default'}
                size={'small'}
                onClick={dispatchModalName}
              >
                Сообщение
              </Button>
            )}
          </Box>

          {isAllowedCreateUser || (isAllowedCreateOffice && <Divider style={{ margin: 5 }} />)}
          <Box component="div" className={classes.wrapper} display={'flex'} justifyContent={'space-between'}>
            {isAllowedCreateClient && (
              <Button
                fullWidth
                color={'primary'}
                variant={'contained'}
                onClick={dispatchModalName}
                data-name="createClient"
                size={'small'}
              >
                Клиент
              </Button>
            )}

            <Button
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={dispatchModalName}
              data-name="createEvent"
              size={'small'}
            >
              Событие
            </Button>
            <Button
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={dispatchModalName}
              data-name="TOPIC_MODAL"
              data-size="lg"
              size={'small'}
            >
              Топик
            </Button>
          </Box>
        </Grid>
      </Popover>
    </>
  );
};

export default React.memo(QuickCreation);
