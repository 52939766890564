import React from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import SnippetWrapper from 'components/CustomComponents/CustomSnippetWrapper/SnippetWrapper';
import { IUser } from 'redux/user/user.types';

import useStyles from './UserList.styles';
import ScrollableBox from 'components/CustomComponents/ScrollableBox';

interface UserListProps {
  users: Array<IUser>;
  handleRemoveUser: (value: string) => void;
  selected: string;
  setSelectUser: (value: string) => void;
}

const UserList = ({ users, handleRemoveUser, selected, setSelectUser }: UserListProps) => {
  const classes = useStyles();

  const handleSelectUser = (userId: string) => {
    if (selected !== userId) {
      setSelectUser(userId);
    }
  };

  return (
    <SnippetWrapper>
      <Typography variant={'body2'} style={{ margin: '10px 0' }} align={'center'}>
        Выбранные сотрудники
      </Typography>
      <div className={classes.mainContainer}>
        <ScrollableBox>
          {users?.map((user) => (
            <div
              onClick={() => handleSelectUser(user._id)}
              key={user._id}
              className={classes.userWrapper}
              style={{ background: selected === String(user._id) ? '#000' : '#5d6b61' }}
            >
              <span>{`${user.personalData.name} ${user.personalData.surname}`}</span>
              <IconButton size={'small'} onClick={() => handleRemoveUser(user._id)}>
                <CloseRoundedIcon fontSize={'small'} color={'error'} />
              </IconButton>
            </div>
          ))}
        </ScrollableBox>
        {!users.length && (
          <Typography align={'center'} variant={'caption'}>
            Сотрудники не выбраны
          </Typography>
        )}
      </div>
    </SnippetWrapper>
  );
};

export default UserList;
