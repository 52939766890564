import * as types from './filter.types';

const emptyFilters = [
  {
    id: 0,
    addOperator: 'and',
    column: '',
    operator: '',
    value: '',
  },
];

const initialState = {
  filters: [...emptyFilters],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FILTER_ITEM: {
      const { filterItem } = action;
      const filterItemId = action.filterItem.id;
      let newFilters = [...state.filters];

      const filterItemPosition = newFilters.findIndex((elem) => elem.id === filterItemId);
      if (filterItemPosition < 0) {
        newFilters.push(filterItem);
      } else {
        newFilters[filterItemPosition] = filterItem;
      }

      return {
        ...state,
        filters: newFilters,
      };
    }

    case types.REMOVE_FILTER_ITEM: {
      const filterItemId = action.filterId;
      let newFilters = [...state.filters];
      newFilters = newFilters.filter((filterItem) => filterItem.id !== filterItemId);

      newFilters[0].id = 0;
      newFilters[0].addOperator = 'and';

      return {
        ...state,
        filters: newFilters,
      };
    }

    case types.CLEAR_FILTER: {
      return {
        ...state,
        filters: [
          {
            id: 0,
            addOperator: 'and',
            column: '',
            operator: '',
            value: '',
          },
        ],
      };
    }

    default:
      return state;
  }
};

export default filterReducer;
