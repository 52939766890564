import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import DefaultLayout from '@/layouts/DefaultLayout';
import MainLayout from '@/layouts/MainLayout';
import { useAppDispatch } from 'redux/hooks';
import { isLoggedInSelector } from 'redux/login/auth.selectors';

const BaseLayout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isLoggedIn = useSelector(isLoggedInSelector);
  useEffect(() => {
    if (!isLoggedIn) {
      router.push('/login');
    }
  }, [dispatch, isLoggedIn]);

  return (
    <>
      <Toaster />
      {isLoggedIn && <MainLayout>{children}</MainLayout>}
      {!isLoggedIn && <DefaultLayout>{children}</DefaultLayout>}
    </>
  );
};

export default BaseLayout;
