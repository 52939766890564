import React from 'react';

import { ListItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import useStyles from 'components/common/ListItemLink/ListItemLink.styles';

interface ListItemLinkProps {
  href: string;
  icon: string;
  primary: string;
  isActive: boolean;
  withPadding?: boolean;
  isNestedElement?: boolean;
}

const ListItemLink: React.FC<ListItemLinkProps> = ({
  href,
  icon,
  primary,
  isActive,
  withPadding = false,
  isNestedElement = false,
}) => {
  const classes = useStyles();
  return (
    <Link href={href} passHref>
      <ListItem
        button
        component="a"
        className={cx(classes.menuItem, { [classes.menuActive]: isActive, [classes.withoutPadding]: !withPadding })}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          padding={'15px 0'}
        >
          {!isNestedElement && <Image src={icon} width={25} height={25} layout={'fixed'} />}
          <Typography className={classes.text} align={'center'} variant={'body2'}>
            {primary}
          </Typography>
        </Box>
      </ListItem>
    </Link>
  );
};

export default React.memo(ListItemLink);
