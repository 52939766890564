import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import NotificationsAPI from '@/api/notifications';
import { showErrorAlert } from 'redux/app/app.actions';
import {
  GetAllNotificationResponse,
  INotification,
  NOTIFICATIONS_GET_ALL,
  NOTIFICATIONS_UPDATE,
  UpdateNotificationsRequest,
} from 'redux/notifications/notifications.types';

export const getNotifications = createAsyncThunk(
  NOTIFICATIONS_GET_ALL,
  async (params: Record<string, string | number>, { rejectWithValue }) => {
    return NotificationsAPI.getAll(params)
      .then((response: AxiosResponse<GetAllNotificationResponse>): GetAllNotificationResponse => response.data)
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);

export const updateNotifications = createAsyncThunk(
  NOTIFICATIONS_UPDATE,
  async (data: UpdateNotificationsRequest, { rejectWithValue, dispatch }) => {
    return NotificationsAPI.updateAll(data)
      .then((response: AxiosResponse<Array<INotification>>): Array<INotification> => response.data)
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);
