import React, { useState } from 'react';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { CheckBoxAutocompleteOptionItem } from '@/types/common';

interface CheckBoxAutocompleteProps {
  data: CheckBoxAutocompleteOptionItem<string>[];
  setData: (value: React.SetStateAction<CheckBoxAutocompleteOptionItem<string>[]>) => void;
  textFieldLabel: string;
  textFieldPlaceholder: string;
  isUseSetAll?: boolean;
}

const CheckBoxAutocomplete = ({
  data,
  setData,
  textFieldLabel,
  textFieldPlaceholder,
  isUseSetAll,
}: CheckBoxAutocompleteProps) => {
  const [selectAll, setSelectAll] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleCheckAllOffices = () => {
    if (selectAll) {
      setData(data.map((item) => ({ ...item, isActive: false })));
      setSelectAll(!selectAll);
    } else {
      setData(data.map((item) => ({ ...item, isActive: true })));
      setSelectAll(!selectAll);
    }
  };

  const handleCheckOffice = (value: string | undefined) => {
    setData((prevState) => {
      return prevState.map((item) => {
        if (item.value === value) {
          return { ...item, isActive: !item.isActive };
        }
        return item;
      });
    });
  };

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        disableClearable
        options={data}
        renderTags={() => null}
        onChange={(_, value, reason, details) => {
          handleCheckOffice(details?.option.value);
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, selected) => option.value === selected.value}
        renderOption={(option, state) => (
          <div>
            <Checkbox icon={icon} checked={option.isActive} checkedIcon={checkedIcon} style={{ marginRight: 8 }} />
            {option.title}
          </div>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <>
            <TextField {...params} variant={'outlined'} label={textFieldLabel} placeholder={textFieldPlaceholder} />
            {isUseSetAll && (
              <FormControlLabel
                control={<Checkbox checked={selectAll} onChange={handleCheckAllOffices} />}
                label={selectAll ? 'Убрать все' : 'Выбрать все'}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default CheckBoxAutocomplete;
