import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import toast from 'react-hot-toast';

import { filteredUsersField } from '@/utils/utils';
import ClientsList, { ClientsEvents, IClients } from 'components/common/ClientsList/ClientsList';
import EventsList from 'components/common/EventsList';
import FilterClients from 'components/common/FilterClients';
import UserList from 'components/common/UserList';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useGetAllClientsWithEventsQuery, useUpdateClientsEventsMutation } from 'redux/client/client.service';
import { useTransferEventMutation } from 'redux/event/event.service';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { viewingAccessByRole } from 'redux/managment/management.selectors';
import { fireUser } from 'redux/user/user.actions';
import { getUserListByRole } from 'redux/user/user.selectors';
import { IUser } from 'redux/user/user.types';

import useStyles from './FireEmployeeModal.styles';

type UserClients = {
  [key: string]: IClients[];
};

interface IModalData {
  id: string;
}

const FireEmployeeModal = () => {
  const { globalAccess } = useAppSelector(viewingAccessByRole);
  const [clientsList, setClientsList] = useState<Array<IClients>>([]);
  const [filteredClients, setFilteredClients] = useState<Array<IClients>>([]);
  const [events, setEvents] = useState<Array<ClientsEvents>>([]);
  const [selectedClient, setSelectedClient] = useState(''); // для хайлайта клиента в общем списке
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [selectedUser, setSelectedUser] = useState<string>(''); // для хайлайта сотрудника куда попадают клиенты
  const [userClients, setUserClients] = useState<UserClients>({}); // объект где ключ это ид сотрудика а значение массив клиентов
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const roleUsers = useAppSelector(getUserListByRole);
  const { id } = useAppSelector<IModalData>(modalDataSelector);
  const { data: myClients, isLoading } = useGetAllClientsWithEventsQuery(id);
  const [updateClientsEvents] = useUpdateClientsEventsMutation();
  const [transferEventsToUser] = useTransferEventMutation();
  const filterRoleUsers = roleUsers.filter((user) => user._id !== id);

  useEffect(() => {
    if (myClients) {
      setFilteredClients(myClients);
      setClientsList(myClients);
    }
  }, [myClients]);

  useEffect(() => {
    const events = clientsList.find((client) => client._id === selectedClient)?.events || [];
    setEvents(events);
  }, [selectedClient]);

  const handleSelectClient = useCallback((userId) => setSelectedClient(userId), []);

  const handleAddUser = (userId: string | undefined) => {
    // создаем массив сотрудников кому будем передавать клиентов
    if (userId) {
      const selected = roleUsers.find((user) => user._id === userId);
      const checkUser = users.find((user) => user._id === userId);
      if (selected && !checkUser) {
        setUsers((prevState) => prevState.concat(selected));
        setSelectedUser(String(selected._id));
      }
    }
  };

  const handleAddClientToUser = (client: IClients) => {
    if (selectedUser) {
      if (userClients.hasOwnProperty(selectedUser)) {
        setUserClients((prevState) => ({ ...prevState, [selectedUser]: [...prevState[selectedUser], client] }));
      } else {
        setUserClients((prevState) => ({ ...prevState, [selectedUser]: [client] }));
      }
      const clientInList = filteredClients.filter((clientData) => clientData._id !== client._id);
      setFilteredClients(clientInList);
    }
  };

  const handleRemoveUser = (userId: string) => {
    setUsers((prevState) => {
      return prevState.filter((user) => user._id !== userId);
    });
    setFilteredClients((prevState) => {
      const removeUserClients = userClients[userId];
      return removeUserClients ? [...removeUserClients, ...prevState] : [...prevState];
    });
    setSelectedUser('');
    setUserClients((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[selectedUser];
      return updatedState;
    });
  };

  const handleRemoveClient = (clientData: IClients) => {
    if (selectedUser) {
      if (userClients[selectedUser].length === 1) {
        setUserClients((prevState) => {
          const updatedState = { ...prevState };
          delete updatedState[selectedUser];
          return updatedState;
        });
      } else {
        setUserClients((prevState) => ({
          ...prevState,
          [selectedUser]: prevState[selectedUser].filter((client) => client._id !== clientData._id),
        }));
      }
      setFilteredClients((prevState) => {
        return prevState.find((client) => client._id === clientData._id) ? [...prevState] : [clientData, ...prevState];
      });
    }
  };

  const handleFiltersClient = (eventNames: string[] | null) => {
    if (eventNames?.length) {
      const filtered = clientsList.filter((client) =>
        eventNames.every((eventName) => client.events.some((event) => event.title === eventName && event.status === 'Выполнено')),
      );
      const chooseClients = Object.values(userClients).reduce((acc, arr) => acc.concat(arr), []);
      const newState = filtered.filter((item) => !chooseClients.includes(item));
      setFilteredClients(newState);
      setUserClients((prevState) => {
        return Object.fromEntries(
          Object.entries(prevState).map(([userId, clients]) => {
            const filteredClients = clients.filter((client) =>
              eventNames.every((eventName) =>
                client.events.some((event) => event.title === eventName && event.status === 'Выполнено'),
              ),
            );
            return [userId, filteredClients];
          }),
        );
      });
    }
  };

  const handleActionAllClients = (action: string) => {
    if (selectedUser) {
      if (action === 'add' && filteredClients.length) {
        setUserClients((prevState) => {
          if (prevState[selectedUser] === undefined) {
            return { ...prevState, [selectedUser]: [...filteredClients] };
          }
          return { ...prevState, [selectedUser]: [...prevState[selectedUser], ...filteredClients] };
        });
        setFilteredClients([]);
      } else if (action === 'remove') {
        setFilteredClients((prevState) => [...userClients[selectedUser], ...prevState]);
        setUserClients((prevState) => {
          return { ...prevState, [selectedUser]: [] };
        });
      }
    }
  };

  const handleRemoveFilters = () => {
    const chooseClients = Object.values(userClients).reduce((acc, arr) => acc.concat(arr), []);
    const newState = clientsList.filter((item) => !chooseClients.includes(item));
    setFilteredClients(newState);
  };

  const handleConfirmFired = () => {
    if (!globalAccess && !Object.keys(userClients).length && myClients?.length) {
      toast.error('Клиенты не переданы');
    } else {
      const entries = Object.entries(userClients).map((user) => {
        const [userId, clients] = user;
        return {
          clients: { assignee: userId, clientsIds: clients.map((client) => client._id) },
          events: {
            assignee: filteredUsersField(
              ['_id', 'personalData', 'role', 'registered_by', 'avatar', 'office'],
              users.find((user) => user._id === userId),
            ),
            clients: clients.map((user) => ({ _id: user._id, client: user.client })),
          },
        };
      });
      for (const data of entries) {
        transferEventsToUser(data.events);
        updateClientsEvents(data.clients);
      }
      dispatch(fireUser({ _id: id, fired: true }));
      dispatch(closeModal());
    }
  };

  if (isLoading) {
    return (
      <Box position={'relative'} display={'flex'} justifyContent={'center'} padding={'40px 0'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box display={'flex'} flexDirection={'column'} gridGap={20} paddingTop={'5px'}>
        <Autocomplete
          options={filterRoleUsers}
          fullWidth
          size={'small'}
          onChange={(_, value) => handleAddUser(value?._id)}
          getOptionLabel={(option) => `${option.personalData.name} ${option.personalData.surname}`}
          renderInput={(params) => (
            <TextField {...params} label="Выберите сотрудника" placeholder={'Найти сотрудника...'} variant="outlined" />
          )}
        />
        <FilterClients
          clients={clientsList}
          handleFiltersClient={handleFiltersClient}
          handleRemoveFilters={handleRemoveFilters}
        />
        <Button fullWidth size={'small'} onClick={handleConfirmFired} variant={'contained'} color={'primary'}>
          Уволить сотрудника
        </Button>
        <UserList
          users={users}
          handleRemoveUser={handleRemoveUser}
          selected={selectedUser}
          setSelectUser={(id) => setSelectedUser(id)}
        />
      </Box>
      <Box display={'flex'}>
        <ClientsList
          clients={filteredClients}
          selected={selectedClient}
          selectClient={handleSelectClient}
          action={'add'}
          addClient={handleAddClientToUser}
          handleAddAllClient={handleActionAllClients}
        />
      </Box>
      <Box display={'flex'}>
        <ClientsList
          clients={userClients[selectedUser] || []}
          action={'remove'}
          selected={''}
          selectClient={() => null}
          remove={handleRemoveClient}
          handleAddAllClient={handleActionAllClients}
        />
      </Box>
      <EventsList events={events} />
    </Box>
  );
};

export default FireEmployeeModal;
