import { ReactElement } from 'react';

import { BaseModalProps } from '@/types/common';
import CreateMessageModal from 'components/common/Modals/CreateMessageModal';
import { CreateRatingItem } from 'components/common/Modals/CreateRatingItem';
import { SupportModal } from 'components/common/Modals/SupportModal';
import SwitchOffOfficeModal from 'components/common/Modals/SwitchOffOffice';
import WelcomeModal from 'components/common/Modals/WelcomeModal';
import CreateCategoryModal from 'components/Topics/CreateCategoryModal';
import CreateTopicModal from 'components/Topics/CreateTopicModal';

import {
  CHANGE_PASSWORD_MODAL,
  CLIENT_MODAL,
  COMMENT_MODAL,
  CONFIRMATION_MODAL,
  TOPIC_MODAL,
  EMPLOYEE_MODAL,
  EVENT_MODAL,
  FINE_MODAL,
  OFFICE_MODAL,
  PLAN_MODAL,
  SALARY_MODAL,
  CATEGORY_MODAL,
  SWITCH_OFF_OFFICE_MODAL,
  SUPPORT_MODAL,
  CREATE_RATING_MODAL,
  RESET_PASSWORD_MODAL,
  CREATE_MESSAGE_MODAL,
  WELCOME_MODAL, FIRE_EMPLOYEE_MODAL, CLIENT_LOG_MODAL,
} from './constants';
import ChangePasswordModal from '../components/common/Modals/ChangePassword/ChangePassword';
import ConfirmationModal from '../components/common/Modals/ConfirmationModal';
import CreateClient from '../components/common/Modals/CreateClient/CreateClient';
import CreateComment from '../components/common/Modals/CreateComment/CreateComment';
import CreateEvent from '../components/common/Modals/CreateEvent/CreateEvent';
import CreateFine from '../components/common/Modals/CreateFine/CreateFine';
import CreateOffice from '../components/common/Modals/CreateOffice/CreateOffice';
import CreatePlan from '../components/common/Modals/CreatePlan/CreatePlan';
import CreateUser from '../components/common/Modals/CreateUser/CreateUser';
import SalaryPaymentsModal from '../components/common/Modals/SalaryPaymentsModal/SalaryPaymentsModal';
import FireEmployeeModal from 'components/common/Modals/FireEmployeeModal';
import ClientLogModal from 'components/common/Modals/ClientLogModal';

interface IModalList {
  [key: string]: (props: any) => ReactElement;
}

export const ModalComponents: IModalList = {
  [CHANGE_PASSWORD_MODAL]: () => <ChangePasswordModal />,
  [RESET_PASSWORD_MODAL]: () => <ChangePasswordModal />,
  [FIRE_EMPLOYEE_MODAL]: () => <FireEmployeeModal />,
  [CREATE_MESSAGE_MODAL]: () => <CreateMessageModal />,
  [COMMENT_MODAL]: (props: BaseModalProps) => <CreateComment {...props} />,
  [EMPLOYEE_MODAL]: (props: BaseModalProps) => <CreateUser {...props} />,
  [EVENT_MODAL]: (props: BaseModalProps) => <CreateEvent {...props} />,
  [CLIENT_MODAL]: (props: BaseModalProps) => <CreateClient {...props} />,
  [CLIENT_LOG_MODAL]: () => <ClientLogModal />,
  [OFFICE_MODAL]: (props: BaseModalProps) => <CreateOffice {...props} />,
  [CONFIRMATION_MODAL]: () => <ConfirmationModal />,
  [PLAN_MODAL]: (props: BaseModalProps) => <CreatePlan {...props} />,
  [FINE_MODAL]: (props: BaseModalProps) => <CreateFine />,
  [SALARY_MODAL]: (props: BaseModalProps) => <SalaryPaymentsModal {...props} />,
  [TOPIC_MODAL]: (props: BaseModalProps) => <CreateTopicModal />,
  [CATEGORY_MODAL]: (props: BaseModalProps) => <CreateCategoryModal />,
  [SWITCH_OFF_OFFICE_MODAL]: () => <SwitchOffOfficeModal />,
  [SUPPORT_MODAL]: () => <SupportModal />,
  [CREATE_RATING_MODAL]: (props) => <CreateRatingItem {...props} />,
  [WELCOME_MODAL]: (props) => <WelcomeModal {...props} />,
};

export const modalNameTranslate: Record<string, string> = {
  [CLIENT_MODAL]: 'Добавить клиента',
  [EVENT_MODAL]: 'Добавить событие',
  [OFFICE_MODAL]: 'Добавить офис',
  [EMPLOYEE_MODAL]: 'Добавить сотрудника',
  [CREATE_MESSAGE_MODAL]: 'Добавить приветственное сообщение',
};
