import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector } from 'redux/login/auth.selectors';

const DefaultLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (!isLoggedIn) {
      router.push('/login');
    }
  }, [dispatch, isLoggedIn]);

  return <div>{children}</div>;
};

export default DefaultLayout;
