import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.5)',
    padding: 10,
    margin: '5px 0',
    width: (props: Record<string, any>) => props?.width || '100%',
    height: (props: Record<string, any>) => props?.height || 'auto',
    [theme.breakpoints.up('xl')]: {
      padding: 20,
      margin: '15px 5px',
      borderRadius: 20,
    },
  },
  paperBackground: {
    background: (props: Record<string, string>) => props?.background || theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  disableOverflow: {
    overflow: 'hidden',
  },
  noPadding: {
    borderRadius: 20,
    boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.5)',
    overflow: 'hidden',
    margin: 5,
    [theme.breakpoints.up('md')]: {
      margin: '15px 5px',
    },
  },
}));
