import axios from 'api';

const urlForOperations = 'api/operations';

const OperationsAPI = {
  getClientOperations: (id: string) => axios.get(`${urlForOperations}/${id}`),
  deleteClientOperations: (id: string) => axios.delete(`${urlForOperations}/${id}`),
};

export default OperationsAPI;
