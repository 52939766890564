import React, { useEffect, useState } from 'react';

import { Badge, Button, CircularProgress, Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import { IFetchingStatuses } from '@/types/common';
import NotificationItem from 'components/common/NotificationItem';
import { useStyles } from 'components/common/UserNotifications/UserNotifications.styles';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getNotifications, updateNotifications } from 'redux/notifications/notifications.actions';
import {
  notificationStatusSelector,
  readNotificationCountSelector,
  readNotificationSelector,
  unreadNotificationCountSelector,
  unreadNotificationSelector,
} from 'redux/notifications/notifications.selectors';
import { setReadNotifications, setUnreadNotifications } from 'redux/notifications/notifications.slice';
import { INotification } from 'redux/notifications/notifications.types';

enum NotificationTabs {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

const UserNotifications = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const readNotifications = useAppSelector<Array<INotification>>(readNotificationSelector);
  const unreadNotifications = useAppSelector<Array<INotification>>(unreadNotificationSelector);
  const readNotificationCount = useAppSelector<number>(readNotificationCountSelector);
  const unreadNotificationCount = useAppSelector<number>(unreadNotificationCountSelector);
  const isLoading = useAppSelector<IFetchingStatuses>(notificationStatusSelector) === IFetchingStatuses.pending;
  const [listOpen, setListOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<NotificationTabs>(NotificationTabs.UNREAD);
  const [currentNotifications, setCurrentNotifications] = useState<Array<INotification>>([]);
  const [notificationsToUpdate, setNotificationsToUpdate] = useState<Set<string>>(new Set());

  useEffect(() => {
    const options = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setNotificationsToUpdate((prevState) => new Set<string>([...Array.from(prevState), entry.target.id]));
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    if (activeTab === NotificationTabs.UNREAD && listOpen) {
      setTimeout(() => {
        const targets = document.querySelectorAll('.notification');
        targets.forEach((target) => {
          observer.observe(target);
        });
      }, 10);
    }
    return () => {
      observer.disconnect();
    };
  }, [activeTab, listOpen, unreadNotifications]);

  const handleOpen = () => {
    setListOpen(true);
    setActiveTab(NotificationTabs.UNREAD);
    setCurrentNotifications(unreadNotifications);
  };

  useEffect(() => {
    setCurrentNotifications(activeTab === NotificationTabs.UNREAD ? unreadNotifications : readNotifications);
  }, [unreadNotifications, activeTab, readNotifications]);

  const handleSwitchTab = (tab: NotificationTabs) => {
    if (tab === activeTab) {
      return;
    }
    setUnreadNotifications([...unreadNotifications.filter((notification) => !notificationsToUpdate.has(notification._id))]);
    setActiveTab(tab);
  };

  const loadMoreNotifications = () => {
    dispatch(
      getNotifications({
        type: activeTab === NotificationTabs.UNREAD ? 'unread' : 'read',
        skip: activeTab === NotificationTabs.UNREAD ? unreadNotifications.length : readNotifications.length,
      }),
    );
  };

  const handleClose = () => {
    setListOpen(false);
    if (notificationsToUpdate.size > 0) {
      dispatch(updateNotifications({ data: Array.from(notificationsToUpdate).filter((item) => !!item) }))
        .unwrap()
        .then(() => {
          const watchedNotifications = unreadNotifications.filter((notification) => notificationsToUpdate.has(notification._id));
          dispatch(
            setUnreadNotifications([
              ...unreadNotifications.filter((notification) => !notificationsToUpdate.has(notification._id)),
            ]),
          );
          dispatch(setReadNotifications([...watchedNotifications, ...readNotifications]));
        });
    }
  };

  return (
    <div className={classes.root}>
      <Badge overlap="rectangular" badgeContent={unreadNotifications.length} color="secondary" onClick={() => handleOpen()}>
        <NotificationsActiveIcon className={classes.icon} color="action" />
      </Badge>
      <Drawer anchor={'right'} open={listOpen} onClose={() => handleClose()}>
        <div className={classes.notifications}>
          <IconButton className={classes.closeButton} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
          {!readNotifications.length && !unreadNotifications.length ? (
            <div className={classes.notificationsContainer}>
              <div className={classes.emptyBlock}>У Вас пока нет уведомлений</div>
            </div>
          ) : (
            <>
              <div className={classes.tabs}>
                <Button
                  variant={activeTab === NotificationTabs.UNREAD ? 'contained' : 'outlined'}
                  size={'small'}
                  onClick={() => handleSwitchTab(NotificationTabs.UNREAD)}
                >
                  Непросмотренные
                </Button>
                <Button
                  variant={activeTab === NotificationTabs.READ ? 'contained' : 'outlined'}
                  size={'small'}
                  onClick={() => handleSwitchTab(NotificationTabs.READ)}
                >
                  Просмотренные
                </Button>
              </div>
              <div className={classes.notificationsContainer}>
                {activeTab === NotificationTabs.UNREAD && !unreadNotifications.length && (
                  <div className={classes.emptyBlock}>У Вас нет новых уведомлений</div>
                )}
                {currentNotifications.map((notification, index) => (
                  <NotificationItem
                    id={notification._id}
                    key={notification._id}
                    type={notification.type}
                    description={notification.description}
                    date={notification.createdAt}
                    metaId={notification.metaId}
                  />
                ))}
                {!isLoading &&
                  currentNotifications.length <
                    (activeTab === NotificationTabs.UNREAD ? unreadNotificationCount : readNotificationCount) && (
                    <Button variant={'outlined'} onClick={loadMoreNotifications}>
                      Загрузить более ранние уведомления
                    </Button>
                  )}
                {isLoading && (
                  <div className={classes.loaderContainer}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default UserNotifications;
