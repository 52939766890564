import { createSlice } from '@reduxjs/toolkit';
import type { AnyAction } from '@reduxjs/toolkit';

import { ActionStatus, ApiState } from 'redux/api/api.types';

const statusMatcher = (type: string, status: ActionStatus): boolean => {
  const regexp = new RegExp(status, 'g');
  return regexp.test(status);
};

const initialState: ApiState = {
  lastError: [],
  lastFetch: [],
  lastPending: [],
};
export const apiSlice = createSlice({
  initialState,
  name: 'api',
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => statusMatcher(action.type, 'fulfilled'),
      (state, { meta, type }) => {
        state.lastFetch = [{ meta, type }];
      },
    );
    builder.addMatcher(
      (action) => statusMatcher(action.type, 'pending'),
      (state, { meta, type }) => {
        state.lastPending = [{ meta, type }];
      },
    );
    builder.addMatcher(
      (action) => statusMatcher(action.type, 'rejected'),
      (state, { meta, type }) => {
        state.lastFetch = [{ meta, type }];
      },
    );
  },
});

export default apiSlice.reducer;
