import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppSelector } from 'redux/hooks';

import useStyles from './WelcomeModal.styles';

const WelcomeModal = () => {
  const classes = useStyles();
  const modalData = useAppSelector<{ message: string } | undefined>(modalDataSelector);
  return (
    <Box>
      <Typography variant={'caption'} className={classes.messageText} id="modal-modal-description">
        {modalData?.message || ''}
      </Typography>
    </Box>
  );
};

export default WelcomeModal;
