import { createApi } from '@reduxjs/toolkit/dist/query/react';

import axiosBaseQuery from 'redux/axiosBaseQuery';

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: () => ({}),
});

export interface IRTKError {
  data?: {
    error: {
      message: string;
    };
  };
}
