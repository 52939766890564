import Cookies from 'universal-cookie';

export const setCookies = ({ access_token, refresh_token }) => {
  const cookies = new Cookies();

  cookies.set('access', access_token, { path: '/' });
  cookies.set('refresh', refresh_token, { path: '/' });

  // todo: remove condition when refresh presents by the backend
  if (refresh_token) {
    cookies.set('refresh', refresh_token, { path: '/' });
  }
};

export const getAccessToken = () => {
  const cookies = new Cookies();
  return cookies.get('access');
};

export const getRefreshToken = () => {
  const cookies = new Cookies();
  return cookies.get('refresh');
};

export const clearAuthTokensFromCookies = () => {
  const cookies = new Cookies();
  cookies.remove('access', { path: '/' });
  cookies.remove('refresh', { path: '/' });
};
