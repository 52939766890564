import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.background.default,
    padding: '15px',
    gap: '1rem',
    borderRadius: '5px',
    border: '1px white solid',
  },
  flexRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    '& > div:first-child': {
      width: '70%',
    },
    '& > div:last-child': {
      width: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div:first-child': {
        width: '100%',
      },
      '& > div:last-child': {
        width: '100%',
      },
    },
  },
}));
