import React, { FC } from 'react';

import { Checkbox, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { useStyles } from 'components/common/AllowedForSelector/AllowedForSelector.styles';
import { IUserRoles } from 'redux/user/user.types';

interface AllowedForSelectorProps {
  values: Array<IUserRoles | any>;
  setSelectedItems: (e: any) => void;
  targetObject: Record<string, string>;
  label: string;
}

// закинь объект, значения и колл бек, дальше будет магия

const AllowedForSelector: FC<AllowedForSelectorProps> = ({ values, setSelectedItems, label, targetObject }) => {
  const classes = useStyles();
  const isAllSelected = values.length > 0 && values.length === Object.keys(targetObject).length;

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as Array<string>;
    if (value[value.length - 1] === 'all') {
      setSelectedItems(values.length === Object.keys(targetObject).length ? [] : Object.keys(targetObject));
      return;
    }
    setSelectedItems(value);
  };

  return (
    <>
      <FormControl>
        <InputLabel id="allowed-for-label">{label}</InputLabel>
        <Select
          MenuProps={{ classes: { paper: classes.root } }}
          labelId="allowed-for-label"
          value={values}
          multiple
          onChange={(e) => handleChange(e)}
          renderValue={(selected: unknown) => (selected as Array<IUserRoles>).map((item) => targetObject[item]).join(', ')}
        >
          <MenuItem value="all">
            <Checkbox checked={isAllSelected} />
            <ListItemText primary="Выбрать все" />
          </MenuItem>
          {Object.keys(targetObject).map((key) => (
            <MenuItem key={key} value={key}>
              <Checkbox checked={values?.indexOf(key as IUserRoles) > -1} />
              <ListItemText primary={targetObject[key]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AllowedForSelector;
