import { createSelector } from 'reselect';

import { IFetchingStatuses, IFilters, IStatuses, LoadingStatus } from '@/types/common';
import { TRAINEE, SENIORMANAGER } from '@/variables/management';
import { roleSelector, myIdSelector } from 'redux/profile/profile.selectors';
import { RootState } from 'redux/store';

import { IEvent } from './event.types';

export const eventSort = (state: RootState) => state.events.sort;
export const eventRequestStatusSelector = (state: RootState): IStatuses => state.events.status;
export const totalEventsSelector = (state: RootState): number => state.events.totalEvents;
export const currentEventSelector = (state: RootState): IEvent | null => state.events.currentEvent;

export const eventsFilters = (state: RootState): IFilters => state.events.filters;
export const eventFiltersSelector = createSelector(eventsFilters, (filters): IFilters => filters);
export const eventSortSelector = createSelector(eventSort, (sort): Record<string, string> | null => sort);

export const eventListSelector = (state: RootState): IEvent[] => state?.events?.data;

export const eventsSelector = createSelector(eventListSelector, (events): IEvent[] => events);

export const eventsStatusSelector = createSelector(
  eventRequestStatusSelector,
  (status): LoadingStatus => ({
    isLoading: status === IFetchingStatuses.pending,
    isSuccess: status === IFetchingStatuses.success,
    isError: status === IFetchingStatuses.error,
  }),
);

export const getEventListByRole = createSelector(eventListSelector, myIdSelector, roleSelector, (events, id, role): IEvent[] => {
  const myAndTraineeEventsRole = role === SENIORMANAGER;

  if (myAndTraineeEventsRole) {
    return events.length
      ? events.filter(
          (event) =>
            [event.author?._id, event.assignee?._id].includes(id) || [event.author?.role, event.assignee?.role].includes(TRAINEE),
        )
      : [];
  } else return events;
});
