import React from 'react';

import { AppBar, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import useStyles from 'components/InvestingView/InvestingView.styles';
import { useAppDispatch } from 'redux/hooks';
import { logout } from 'redux/login/auth.actions';

const InvestingView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleLogout = (): void => {
    dispatch(logout());
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <IconButton size={'small'} onClick={handleLogout}>
          <ExitToAppIcon />
        </IconButton>
      </AppBar>
      <div className={classes.frameContainer}>
        <iframe
          src="https://ru.widgets.investing.com/top-cryptocurrencies?theme=darkTheme"
          width="100%"
          height="100%"
          allowTransparency={true}
        />
      </div>
      <div className={classes.frameContainer}>
        <iframe
          src="https://ru.widgets.investing.com/ico-calendar?theme=darkTheme"
          width="100%"
          height="100%"
          frameBorder="0"
          allowTransparency={true}
        />
      </div>
      <div className={classes.frameContainer}>
        <iframe
          src="https://ru.widgets.investing.com/live-currency-cross-rates?theme=darkTheme"
          width="100%"
          height="100%"
          frameBorder="0"
          allowTransparency={true}
        />
      </div>
    </div>
  );
};

export default InvestingView;
