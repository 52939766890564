import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  CHANGE_PASSWORD_MODAL,
  COMMENT_MODAL,
  CONFIRMATION_MODAL,
  CREATE_RATING_MODAL,
  FINE_MODAL,
  OFFICE_MODAL,
  PLAN_MODAL,
  RESET_PASSWORD_MODAL,
  SALARY_MODAL,
  WELCOME_MODAL,
} from '@/utils/constants';
import { showErrorToast, showSuccessToast } from '@/utils/notification';
import axios from 'api';
import { IOffice } from 'redux/office/office.types';

import {
  APP_GET_ROLES,
  CLOSE_ALERT_NOTIFICATION,
  CLOSE_APP_MODAL,
  ICreateCommentModal,
  ModalActionTypes,
  ModalTypes,
  SHOW_ALERT_NOTIFICATION,
  SHOW_APP_MODAL,
  ShowModalProps,
} from './app.types';

export const showModal = createAction(SHOW_APP_MODAL, ({ name, type, data = null, title = '', size = 'sm' }: ShowModalProps) => ({
  payload: {
    modalName: name,
    showModal: true,
    modalType: type,
    size,
    title,
    modalData: data,
  },
}));

export const createCommentModal = createAction(SHOW_APP_MODAL, ({ comments, userId, eventId, edit }: ICreateCommentModal) => ({
  payload: {
    modalName: COMMENT_MODAL,
    title: edit ? 'Изменить комментарий' : 'Добавить комментарий',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData: { comments, userId, eventId, edit: false },
  },
}));

export const createOfficeModal = createAction(SHOW_APP_MODAL, (modalData: IOffice | undefined) => ({
  payload: {
    modalName: OFFICE_MODAL,
    title: modalData?._id ? 'Изменить данные офиса' : 'Добавить Офис',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const createPlanModal = createAction(SHOW_APP_MODAL, (modalData) => ({
  payload: {
    modalName: PLAN_MODAL,
    title: modalData?.planData?._id ? 'Изменить план' : 'Создать план',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const openFineModal = createAction(SHOW_APP_MODAL, (modalData) => ({
  payload: {
    modalName: FINE_MODAL,
    title: 'Добавить штраф',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));
export const changePasswordModal = createAction(SHOW_APP_MODAL, (modalData: string) => ({
  payload: {
    modalName: CHANGE_PASSWORD_MODAL,
    title: 'Изменить пароль',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const resetPasswordModal = createAction(SHOW_APP_MODAL, (modalData: string) => ({
  payload: {
    modalName: RESET_PASSWORD_MODAL,
    title: 'Вам необходимо изменить свой текущий пароль',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const openWelcomeModal = createAction(SHOW_APP_MODAL, (modalData: any) => ({
  payload: {
    modalName: WELCOME_MODAL,
    title: 'Приветственное сообщение',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const createRatingItem = createAction(SHOW_APP_MODAL, (modalData) => ({
  payload: {
    modalName: CREATE_RATING_MODAL,
    title: modalData?.action === ModalActionTypes.CREATE ? 'Создать рейтинг' : 'Изменить рейтинг',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const showSalaryModal = createAction(SHOW_APP_MODAL, (modalData: Record<string, unknown> | null = null) => ({
  payload: {
    modalName: SALARY_MODAL,
    title: 'Сделать выплаты сотрудникам',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const showConfirmationModal = createAction(SHOW_APP_MODAL, (modalData: Record<string, unknown> | null = null) => ({
  payload: {
    modalName: CONFIRMATION_MODAL,
    title: '',
    size: 'sm',
    showModal: true,
    modalType: ModalTypes.modal,
    modalData,
  },
}));

export const closeModal = createAction(CLOSE_APP_MODAL);
export const showAlertNotification = createAction(SHOW_ALERT_NOTIFICATION, (payload) => ({ payload }));
export const closeAlertNotification = createAction(CLOSE_ALERT_NOTIFICATION);

export const showSuccessAlert = createAction(SHOW_ALERT_NOTIFICATION, (message: string) => {
  showSuccessToast(message);
  return { payload: null };
});
export const showErrorNotification = createAction(SHOW_ALERT_NOTIFICATION, (message: string) => {
  showErrorToast(message);
  return { payload: null };
});
export const showErrorAlert = createAction(SHOW_ALERT_NOTIFICATION, (errorData: Record<string, unknown>) => {
  const errorMessage = errorData?.message ? errorData?.message : errorData?.error ? errorData.error : 'Something went wrong';
  const messages = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
  messages.forEach((message) => showErrorToast(message));
  return {
    payload: null,
  };
});

export const getRolesData = createAsyncThunk(APP_GET_ROLES, async (_v, { rejectWithValue }) => {
  return axios
    .get('/api/roles')
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err.response?.data?.detail || ''));
});
