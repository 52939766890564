import { createSlice } from '@reduxjs/toolkit';

import { EntityParsedResponse, IFetchingStatuses } from '@/types/common';
import { getDefaultFilterValues } from '@/utils/utils';
import {
  clearEventsFilters,
  createEvent,
  deleteEvent,
  getEvents,
  getSingleEvent,
  setEventsFilters,
  updateEvent,
  isEventActionsPending,
  isEventActionsRejected,
  setEventSorting,
  clearClientSorting,
} from 'redux/event/event.actions';

import { EVENT_FILTERS, IEvent, IEventState } from './event.types';

const initialState: IEventState = {
  data: [],
  status: null,
  currentEvent: null,
  totalEvents: 0,
  filters: getDefaultFilterValues(EVENT_FILTERS),
  sort: null,
};

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      const { total, result } = payload as EntityParsedResponse<IEvent>;
      state.data = result;
      state.totalEvents = total;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(createEvent.fulfilled, (state, { payload }) => {
      if (payload) {
        state.data.unshift(payload);
      }
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      const index = state.data.findIndex((event) => event._id === payload._id);
      if (index !== -1) {
        state.data[index] = payload;
      }
      state.currentEvent = { ...state.currentEvent, ...payload };
    });
    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      state.status = IFetchingStatuses.success;
      state.data = state.data.filter((event) => event._id !== payload);
    });
    builder.addCase(getSingleEvent.fulfilled, (state, { payload }) => {
      state.currentEvent = payload;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(setEventsFilters, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(clearEventsFilters, (state) => {
      state.filters = null;
      window.localStorage.setItem(EVENT_FILTERS, '');
    });
    builder.addCase(setEventSorting, (state, { payload }) => {
      state.sort = payload;
    });
    builder.addCase(clearClientSorting, (state) => {
      state.sort = null;
    });
    builder.addMatcher(isEventActionsPending, (state) => {
      state.status = IFetchingStatuses.pending;
    });
    builder.addMatcher(isEventActionsRejected, (state) => {
      state.status = IFetchingStatuses.error;
    });
  },
});

export default eventSlice.reducer;
