import { OptionItem } from '@/types/common';

const eventsType: Array<OptionItem<string>> = [
  { title: 'Регистрация', value: 'Регистрация', type: 'title' },
  { title: 'Звонок', value: 'Звонок', type: 'title' },
  { title: 'Консультация', value: 'Консультация', type: 'title' },
  { title: 'Лекция', value: 'Лекция', type: 'title' },
  { title: 'Практика', value: 'Практика', type: 'title' },
  { title: 'Стажировка', value: 'Стажировка', type: 'title' },
  { title: 'Конец стажировки', value: 'Конец стажировки', type: 'title' },
  { title: 'Мастер класс', value: 'Мастер класс', type: 'title' },
  { title: 'Вопрос о счете', value: 'Вопрос о счете', type: 'title' },
  { title: 'Школа трейдера', value: 'Школа трейдера', type: 'title' },
  { title: 'Последняя практика', value: 'Последняя практика', type: 'title' },
  { title: 'Открытие счета', value: 'Открытие счета', type: 'title' },
  { title: 'Пополнение счета', value: 'Пополнение счета', type: 'title' },
  { title: 'Встреча', value: 'Встреча', type: 'title' },
];

export const eventsTypeForAnalytic: Array<OptionItem<string>> = [
  { title: 'Сервис', value: 'Сервис', type: 'title' },
  { title: 'Пополнение счета', value: 'Пополнение счета', type: 'title' },
  { title: 'Повышение лояльности', value: 'Повышение лояльности', type: 'title' },
];

export const eventsTypeForHR: Array<OptionItem<string>> = [
  { title: 'Регистрация', value: 'Регистрация', type: 'title' },
  { title: 'Собеседование', value: 'Собеседование', type: 'title' },
  { title: 'Стажировка', value: 'Стажировка', type: 'title' },
  { title: 'Прием на работу', value: 'Прием на работу', type: 'title' },
  { title: 'Первая продажа', value: 'Первая продажа', type: 'title' },
  { title: 'Звонок', value: 'Звонок', type: 'title' },
];

export const hrStatisticsEventType: Array<OptionItem<string>> = [
  { title: 'Регистрация', value: 'registration', type: 'title' },
  { title: 'Собеседование', value: 'interview', type: 'title' },
  { title: 'Стажировка', value: 'internship', type: 'title' },
  { title: 'Прием на работу', value: 'recruitment', type: 'title' },
  { title: 'Первая продажа', value: 'firstSale', type: 'title' },
];

export const analyticStatisticsEventType: Array<OptionItem<string>> = [
  { title: 'Сервис', value: 'service', type: 'title' },
  { title: 'Повышение лояльности', value: 'loyaltyPromotion', type: 'title' },
  { title: 'Пополнение счета', value: 'refill', type: 'title' },
];

export const operatorStatisticsEventType: Array<OptionItem<string>> = [
  { title: 'Регистрация', value: 'registration', type: 'event' },
  { title: 'Консультация', value: 'consultation', type: 'event' },
];

export const salesStatisticEventType: Array<OptionItem<string>> = [
  { title: 'Регистрация', value: 'registration', type: 'event' },
  { title: 'Консультация', value: 'consultation', type: 'event' },
  { title: 'Лекция', value: 'lecture', type: 'event' },
  { title: 'Практика', value: 'practice', type: 'event' },
  { title: 'Последняя практика', value: 'lastPractice', type: 'event' },
  { title: 'Вопрос о счете', value: 'accountQuestion', type: 'event' },
  { title: 'Встреча', value: 'meeting', type: 'event' },
  { title: 'Счет', value: 'refill', type: 'event' },
];

const statusType: Array<OptionItem<string>> = [
  { title: 'Запланировано', value: 'Запланировано', type: 'status' },
  { title: 'Обработано', value: 'Обработано', type: 'status' },
  { title: 'Выполнено', value: 'Выполнено', type: 'status' },
  { title: 'Просрочено', value: 'Просрочено', type: 'status' },
];

const genderTypes: Array<OptionItem<string>> = [
  { title: '', value: '', type: 'gender' },
  { title: 'M', value: 'male', type: 'gender' },
  { title: 'Ж', value: 'female', type: 'gender' },
];

const taskStatus: Array<OptionItem<string>> = [
  { title: 'Активна', value: 'Активна', type: 'status' },
  { title: 'Просрочена', value: 'Просрочена', type: 'status' },
  { title: 'Завершена', value: 'Завершена', type: 'status' },
];

const advertiseSource: Array<OptionItem<number>> = [
  { title: '', value: 0, type: 'advertiseSource' },
  { title: 'Yandex', value: 1, type: 'advertiseSource' },
  { title: 'Google', value: 2, type: 'advertiseSource' },
  { title: 'Avito', value: 3, type: 'advertiseSource' },
  { title: 'Mail.ru', value: 4, type: 'advertiseSource' },
  { title: 'Twitter', value: 5, type: 'advertiseSource' },
  { title: 'Instagram', value: 6, type: 'advertiseSource' },
  { title: 'Facebook', value: 7, type: 'advertiseSource' },
  { title: 'Tik-tok', value: 8, type: 'advertiseSource' },
  { title: 'Газета', value: 9, type: 'advertiseSource' },
];
const taskPriority: Array<OptionItem<number>> = [
  { title: 'Высокий', value: 1, type: 'taskPriority' },
  { title: 'Средний', value: 2, type: 'taskPriority' },
  { title: 'Низкий', value: 3, type: 'taskPriority' },
];
export const roles: Record<string, string> = {
  applicant: 'Претендент',
  trainee: 'Стажер',
  manager: 'Менеджер',
  seniorManager: 'Старший менеджер',
  topManager: 'Топ менеджер',
  salesHeadManager: 'Руководитель отдела продаж',
  regionalDirector: 'Региональный директор',
  groupOfRegionDirector: 'Руководитель группы',
  developmentDirectorDeputy: 'Зам.директора по развитию',
  developmentDirector: 'Директор по развитию',
  accountant: 'Бухгалтер',
  attractionManager: 'Менеджер по привлечению',
  attractionHeadManager: 'Старший менеджер по привлечению',
  operator: 'Оператор',
  HR: 'Hr',
  headOfAttractionDepartment: 'Руководитель операторов',
  analytic: 'Аналитик',
};
const roleTypes = Object.keys(roles).map((key) => ({ value: key, title: roles[key] }));
// const taskTypeOptions = ['individual', 'office', 'role'].map((type) => ({ type: 'type', value: type }));
export const taskTypeOptions: Array<OptionItem<string>> = [
  { type: 'type', value: 'individual', title: 'Индивидуальный' },
  { type: 'type', value: 'office', title: 'Офис' },
  // { type: 'type', value: 'role', title: 'Должность' },
];

export default {
  eventsType,
  eventsTypeForAnalytic,
  eventsTypeForHR,
  statusType,
  genderTypes,
  taskStatus,
  advertiseSource,
  taskPriority,
  roles,
  roleTypes,
};
