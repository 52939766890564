import axios from 'api';
import { IComment, ITopic } from 'redux/topics/topics.types';

const url = '/api/topics';

const TopicsAPI = {
  getAll: (params: Record<string, string | number>) => axios.get(url, { params }),
  getOne: (id: string) => axios.get(`${url}/${id}`),
  create: (data: Partial<ITopic>) => axios.post(url, data),
  update: (data: Partial<ITopic>) => axios.patch(`${url}/${data._id}`, data),
  sendTopicRemindNotifications: (id: string) => axios.post(`${url}/remind/${id}`),
  delete: (id: string) => axios.delete(`${url}/${id}`),
  getTopicComments: (id: string, params: Record<string, string | number>) => axios.get(`${url}/comments/${id}`, { params }),
  getNestedComments: (id: string) => axios.get(`${url}/comment/${id}`),
  createComment: (data: Partial<IComment>) => axios.post(`${url}/comment`, data),
  updateComment: (data: Partial<IComment>) => axios.patch(`${url}/comment/${data._id}`, data),
  deleteComment: (id: string) => axios.delete(`${url}/comment/${id}`),
};
export default TopicsAPI;
