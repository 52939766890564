import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: '5px',
    padding: '0px 10px 10px 10px',
    // '&:focus-visible': {
    //   border: 'none',
    // },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  messageText: {
    margin: '15px 0',
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export default useStyles;
