import React, { useState } from 'react';

import { Slide } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { useRouter } from 'next/router';

import { SUPPORT_MODAL, SUPPORT_PAGE } from '@/utils/constants';
import { showModal } from 'redux/app/app.actions';
import { useAppDispatch } from 'redux/hooks';

import { useStyles } from './styles';

export const SupportButton = React.memo(() => {
  const router = useRouter();
  const isSupportPage = router.pathname === SUPPORT_PAGE;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleOpenModal = () => {
    setIsVisible(false);
    dispatch(
      showModal({
        name: SUPPORT_MODAL,
        type: 'modal',
      }),
    );
  };

  return (
    <div className={classes.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Slide in={isVisible} direction={'left'} unmountOnExit>
        <Box className={classes.buttonBlock}>
          <Fab onClick={handleOpenModal} size={'small'} color={'secondary'}>
            <AddIcon fontSize={'small'} color={'primary'} />
          </Fab>
          {!isSupportPage && (
            <Fab size={'small'} color={'secondary'}>
              <FormatListBulletedRoundedIcon onClick={() => router.push(SUPPORT_PAGE)} fontSize={'small'} color={'primary'} />
            </Fab>
          )}
        </Box>
      </Slide>
      <Fab size={'small'} color="primary">
        <HelpOutlineRoundedIcon color={'secondary'} fontSize={'small'} />
      </Fab>
    </div>
  );
});
