import format from 'date-fns/format';

import { CreatePlanDto } from 'redux/plans/plans.types';

export const filterEmptyObjectField = (obj: Record<string, unknown>): Record<string, any> => {
  const keys = Object.keys(obj);
  if (!keys.length) return obj;
  return keys.reduce((acc, item) => {
    if (obj[item] || typeof obj[item] === 'number') {
      acc[item as string] = obj[item];
      return acc;
    }
    return acc;
  }, {} as Record<string, any>);
};

export const transformValidatePlan = (plan: Record<string, any>): Partial<CreatePlanDto> => {
  const filterEmptyFields = Object.keys({
    ...plan,
    assignee: plan?.assignee?._id,
  }).reduce((acc, key) => {
    if (plan[key] && acc) {
      acc[key] = plan[key];
      if (key === 'assignee') {
        acc[key] = plan[key]?._id;
      }
      return acc;
    }
    return acc;
  }, {} as Record<string, unknown> | undefined);
  return {
    createdAt: new Date(),
    ...filterEmptyFields,
    updatedAt: new Date(),
    period: typeof plan.period === 'string' ? plan.period : format(plan.period, 'M/yyyy'),
    goal: +plan.goal,
    percent: {
      before: +plan.percent.before,
      in: +plan.percent.in,
    },
  };
};
