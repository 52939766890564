import { lighten, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  group: {
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: lighten(theme.palette.background.default, 0.1),
      transition: 'all 0.3s ease',
    },
  },
  selected: {
    background: lighten(theme.palette.background.default, 0.1),
  },
}));
