import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import CategoriesAPI from '@/api/categories';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { CLOSE_APP_MODAL } from 'redux/app/app.types';
import { RootState } from 'redux/store';
import { ITopic } from 'redux/topics/topics.types';

import {
  CATEGORIES_CREATE,
  CATEGORIES_GET_ALL,
  CATEGORIES_UPDATE,
  CATEGORY_DELETE,
  CATEGORY_GET_CURRENT,
  CATEGORIES_MOVE_TOPIC,
  BaseCategoryResponse,
  GetCategoriesResponse,
  GetCategoryResponse,
  ICategory,
  UpdateCategoryRequest,
  MoveTopicCategory,
} from './categories.types';

export const getCategories = createAsyncThunk(CATEGORIES_GET_ALL, async (_, { rejectWithValue }) => {
  return CategoriesAPI.getAll()
    .then((response: AxiosResponse<GetCategoriesResponse>): GetCategoriesResponse => response.data)
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const createCategory = createAsyncThunk(
  CATEGORIES_CREATE,
  async (category: Partial<ICategory>, { rejectWithValue, dispatch }) => {
    return CategoriesAPI.create(category)
      .then((res: AxiosResponse<BaseCategoryResponse>): BaseCategoryResponse => {
        dispatch(showSuccessAlert('Категория успешно создана'));
        dispatch({ type: CLOSE_APP_MODAL });
        return res.data;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const getCategoryTopics = createAsyncThunk(CATEGORY_GET_CURRENT, async (id: string, { rejectWithValue, dispatch }) => {
  return CategoriesAPI.getOne(id)
    .then((res: AxiosResponse<GetCategoryResponse>): Array<Partial<ITopic>> => {
      return res.data.topics;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const updateCategory = createAsyncThunk(
  CATEGORIES_UPDATE,
  async (data: UpdateCategoryRequest, { rejectWithValue, dispatch }) => {
    return CategoriesAPI.update(data)
      .then((res: AxiosResponse<BaseCategoryResponse>): ICategory => {
        dispatch(showSuccessAlert('Категория успешно обновлена'));
        // TODO подумать над получением обновленных данных только по обновленным категориям
        dispatch(getCategories());
        return res.data.category;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const deleteCategory = createAsyncThunk(CATEGORY_DELETE, async (id: string, { rejectWithValue, dispatch }) => {
  return CategoriesAPI.delete(id)
    .then(() => {
      dispatch(showSuccessAlert('Категория удалена!'));
      return id;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});
