import axios from 'api';
import { GetLastMessageProps, IMessage } from 'redux/message/message.types';

const url = '/api/message';

const MessageAPI = {
  create: (data: IMessage) => axios.post(url, data),
  getOne: (params: GetLastMessageProps) => {
    return axios.get(url, { params: params });
  },
};
export default MessageAPI;
