import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: { position: 'absolute', top: '-8px', left: 62 },
  box: {
    width: 35,
    height: 30,
    borderRadius: 10,
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
    },
  },
}));
