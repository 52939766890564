//@ts-nocheck

import React from 'react';

import Link from 'next/link';

import { IOperation, ISortPayload } from '@/types/common';
import { IClient } from 'redux/client/client.types';
import { IEvent } from 'redux/event/event.types';

export const getMemberById = (members, id) => (members && id ? members.find((user) => user._id === id) : null);

export const composeUserName = (user) => (user ? `${user?.personalData?.name} ${user?.personalData?.surname}` : '');
export const composeUserFullName = (user) =>
  user ? `${user?.personalData?.surname.trim()} ${user?.personalData?.name.trim()} ${user?.personalData?.patronymic.trim()}` : '';

export const composeClientName = (client) => (client ? `${client?.name} ${client?.surname}` : '');

export const composeClientShortName = (client) => (client ? `${client?.surname[0]}${client?.name[0]}` : '');
export const composeClientFullName = (client) => (client ? `${client?.surname} ${client?.name} ${client?.patronymic}` : '');

export enum linkType {
  user = 'users',
  client = 'clients',
  event = 'events',
  topic = 'topics',
}

// todo: rename function
export const composeMemberLink = (title, id, type: linkType, isMyProfile = false) => {
  return isMyProfile ? (
    <Link
      href={{
        pathname: `/profile`,
      }}
    >
      <a style={{ color: 'inherit', fontFamily: 'inherit' }}>{title.trim()}</a>
    </Link>
  ) : title && id && type ? (
    <Link
      href={{
        pathname: `/${type}/[pid]`,
        query: { pid: id },
      }}
    >
      <a style={{ color: 'inherit', fontFamily: 'inherit' }}>{title.trim()}</a>
    </Link>
  ) : (
    <></>
  );
};

export const getUserNameById = (users, id) => {
  const user = getMemberById(users, id);
  return `${user?.personalData?.name} ${user?.personalData?.surname}`;
};

export const getLogUserNameById = (users, userId) => {
  const id = typeof userId === 'string' ? userId : userId._id;
  const user = getMemberById(users, id);
  if (!user) return;
  return `${user?.personalData?.name || ''} ${user?.personalData?.surname || ''}`;
};

export const generateUserProfileLinkById = (users, id) => (
  <Link
    href={{
      pathname: '/users/[pid]',
      query: { pid: id },
    }}
  >
    <a style={{ color: 'inherit', fontFamily: 'inherit' }}>{getUserNameById(users, id)}</a>
  </Link>
); // <Link href={'/users/[pid]'} as={`/users/${id}`} passHref>

export const compressImage = (file: File) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(
        function (blob) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (event) => {
            if (event?.target?.result) {
              resolve(event?.target?.result);
            }
          };
        },
        'image/jpeg',
        0.8,
      );
    };
  });
};

const textRoleColors: Record<string, string> = {
  developmentDirector: 'red',
  salesHeadManager: 'yellowgreen',
};
export const textColorByRole = (role: string): string => {
  if (role in textRoleColors) {
    return textRoleColors[role];
  } else return 'inherit';
};

export const sortColumns = (operations: Array<IOperation>, sort: ISortPayload) => {
  if (sort.id === 'totalAmount') {
    if (sort.desc) {
      return [...operations].sort((a, b) => b.totalAmount - a.totalAmount);
    } else {
      return [...operations].sort((a, b) => a.totalAmount - b.totalAmount);
    }
  } else if (sort.id === 'type') {
    if (sort.desc) {
      return [...operations].sort((a, b) => b.type.localeCompare(a.type));
    } else {
      return [...operations].sort((a, b) => a.type.localeCompare(b.type));
    }
  } else if (sort.id === 'createdAt') {
    if (sort.desc) {
      return [...operations].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } else {
      return [...operations].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }
  }
};

export const getListAssignees = (data: Partial<IClient> | Partial<IEvent>, client: IClient | null) => {
  if (client) {
    if (client.list_assignees?.length && data.assignee && typeof data.assignee !== 'string') {
      return client.list_assignees.includes(data.assignee?._id)
        ? client.list_assignees
        : [...client.list_assignees, data.assignee?._id];
    } else if (!client.list_assignees?.length && data.assignee && typeof data.assignee !== 'string') {
      return [data.assignee._id];
    }
  }
};
