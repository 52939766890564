import { createSelector } from 'reselect';

import { LoadingStatus, CheckBoxAutocompleteOptionItem, OptionItem } from '@/types/common';
import { REQUEST_STATUS_ERROR, REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS } from '@/utils/constants';
import { _officeListForClientAutocomplete } from '@/utils/selectVariants';
import { IOffice, OfficeOptions } from 'redux/office/office.types';
import { RootState } from 'redux/store';

export const officeListSelector = (state: RootState) => state.offices.data;
const officeState = (state: RootState) => state.offices;

export const getOfficeListByRole = createSelector(officeListSelector, (offices): IOffice[] => offices);
export const officeFilterSelector = (state: RootState) => state.offices.filters;

export const officeOptionsSelector = createSelector(officeListSelector, (offices): CheckBoxAutocompleteOptionItem<string>[] => {
  return offices.map((office) => ({ value: office._id, title: office.name, isActive: false }));
});

export const groupedOffices = createSelector(officeListSelector, (offices) => {
  const acc: Record<string, any> = {};
  return offices.length
    ? offices.reduce((acc, current) => {
        if (!acc.hasOwnProperty(current.region)) {
          acc[current.region] = {};
          if (acc[current.region].hasOwnProperty(current.group)) {
            acc[current.region][current.group].push(current);
          } else {
            acc[current.region][current.group] = [];
            acc[current.region][current.group].push(current);
          }
        } else {
          if (acc[current.region].hasOwnProperty(current.group)) {
            acc[current.region][current.group].push(current);
          } else {
            acc[current.region][current.group] = [];
            acc[current.region][current.group].push(current);
          }
        }
        return acc;
      }, acc)
    : null;
});
export const officeLoaderSelector = createSelector(officeState, (state): boolean => state.status === 'pending');

export const officesAutoCompleteOptions = createSelector(getOfficeListByRole, (offices): Array<OptionItem<IOffice>> => {
  return _officeListForClientAutocomplete(offices);
});

export const officeLoadingStatusSelector = createSelector(
  officeState,
  (state): LoadingStatus => ({
    isLoading: state.status === REQUEST_STATUS_PENDING,
    isSuccess: state.status === REQUEST_STATUS_SUCCESS,
    isError: state.status === REQUEST_STATUS_ERROR,
  }),
);

export const officeOptionSelector = createSelector(officeListSelector, (offices): OfficeOptions => {
  return {
    regionOptions: Array.from(new Set(offices.map((office) => office.region))).map((value) => ({ title: value, value })),
    groupOptions: Array.from(new Set(offices.map((office) => office.group))).map((value) => ({ title: value, value })),
  };
});
