import React, { useRef } from 'react';

import { Box, Button, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'components/common/Spinner/Spinner';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppSelector } from 'redux/hooks';
import { changeEmployeePassword, changePassword } from 'redux/login/auth.actions';
import { isLoginLoadingSelector } from 'redux/login/auth.selectors';

interface IModalData {
  isEmployeeProfile: boolean;
  id: string;
}

export default function ChangePasswordModal() {
  const modalData = useAppSelector<IModalData | string>(modalDataSelector);
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoginLoadingSelector);
  const { errors, control, watch, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  const submit = (data: Record<string, string>) => {
    if (typeof modalData === 'object' && modalData.isEmployeeProfile) {
      dispatch(changeEmployeePassword({ newPassword: data.newPassword, employeeId: modalData.id }));
    } else {
      dispatch(changePassword({ newPassword: data.newPassword, oldPassword: data.password }));
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {isLoading && <Spinner />}
      <Grid container spacing={1}>
        {typeof modalData === 'string' && (
          <Grid item xs={12}>
            <Controller
              name="password"
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  label="Текущий пароль"
                  required
                  fullWidth
                  type={'password'}
                  error={!!errors.password}
                  size={'small'}
                  variant="outlined"
                />
              )}
            />
            {errors.password && <p>{errors.password.message}</p>}
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="newPassword"
            rules={{
              required: 'Вы должны ввести новый пароль',
              minLength: {
                value: 8,
                message: 'Пароль должен быть более 8 символов',
              },
            }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Новый пароль"
                required
                fullWidth
                type={'password'}
                error={!!errors.newPassword}
                size={'small'}
                variant="outlined"
              />
            )}
          />
          {errors.newPassword && <p>{errors.newPassword.message}</p>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            rules={{
              validate: (value) => value === newPassword.current || 'Пароли не совпадают',
            }}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                label="Повторите новый пароль"
                required
                fullWidth
                type={'password'}
                error={!!errors.confirmPassword}
                size={'small'}
                variant="outlined"
              />
            )}
          />
          {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
        </Grid>
      </Grid>

      <Box style={{ justifyContent: 'center', display: 'flex', padding: '10px 0' }}>
        <Button disabled={isLoading} onClick={handleSubmit(submit)} size={'small'} variant={'contained'}>
          Сохранить
        </Button>
      </Box>
    </form>
  );
}
