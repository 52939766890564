import { createSelector } from 'reselect';

import { IFetchingStatuses, IStatuses, LoadingStatus } from '@/types/common';
import { RootState } from 'redux/store';
import { IUser, IUserRoles } from 'redux/user/user.types';

export const profileSelector = (state: RootState): IUser => state.profile.data;
export const roleSelector = (state: RootState) => state.profile.data.role;
export const myIdSelector = (state: RootState) => state.profile.data._id;
export const isHeadOfAttractionDepartmentRole = createSelector(
  roleSelector,
  (role) => role === IUserRoles.headOfAttractionDepartment,
);
export const profileLoadingStatus = (state: RootState) => state.profile.status;
export const avatarSelector = (state: RootState) => state.profile.data?.avatar;
export const userOffice = (state: RootState) => state.profile.data.office;

export const myProfileFullName = createSelector(profileSelector, (profile): string | undefined => {
  return profile._id ? `${profile.personalData.name} ${profile.personalData.surname}` : undefined;
});

export const profileStatusSelector = createSelector(
  profileLoadingStatus,
  (status): LoadingStatus => ({
    isLoading: status === IFetchingStatuses.pending,
    isSuccess: status === IFetchingStatuses.success,
    isError: status === IFetchingStatuses.error,
  }),
);
