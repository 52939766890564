import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { EntityParsedResponse } from '@/types/common';
import UserAPI from 'api/user';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';

import {
  IUser,
  USER_CLEAR_FILTERS,
  USER_CREATE,
  USER_DELETE,
  USER_GET_ALL,
  USER_GET_ONE,
  USER_SET_FILTERS,
  USER_UPDATE,
} from './user.types';
import { CLOSE_APP_MODAL } from '../app/app.types';

export const getUsers = createAsyncThunk(USER_GET_ALL, async (params: string = '', { rejectWithValue }) => {
  return UserAPI.getAll(params)
    .then((response): EntityParsedResponse<IUser> => {
      return {
        total: response.data?.totalCount || 0,
        result: response.data.result,
      };
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const createUser = createAsyncThunk(USER_CREATE, async (user: Partial<IUser>, { rejectWithValue, dispatch }) => {
  return UserAPI.create(user)
    .then((response) => {
      dispatch(showSuccessAlert('Сотрудник успешно добавлен!'));
      dispatch({ type: CLOSE_APP_MODAL });
      return response.data.user;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error.response.data));
      return rejectWithValue(error.response.data);
    });
});

export const setUserFilters = createAction(USER_SET_FILTERS, (payload) => ({ payload }));

export const clearUserFilters = createAction(USER_CLEAR_FILTERS);

export const patchUser = createAsyncThunk(USER_UPDATE, async (user: Partial<IUser>, { rejectWithValue, dispatch }) => {
  return UserAPI.update(user)
    .then((response) => {
      dispatch(showSuccessAlert('Сотрудник успешно изменен!'));
      dispatch({ type: CLOSE_APP_MODAL });
      return response.data.user;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error.response.data));
      return rejectWithValue(error.response.data);
    });
});

export const fireUser = patchUser;
export const postUser = createUser;

export const getSingleUser = createAsyncThunk(USER_GET_ONE, async (id: string, { rejectWithValue }) => {
  return UserAPI.getOne(id)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response?.data || ''));
});

export const deleteUser = createAsyncThunk(USER_DELETE, async (id: string, { rejectWithValue }) => {
  return UserAPI.delete(id)
    .then(() => id)
    .catch((error) => rejectWithValue(error.response?.data || ''));
});
