import { createSlice } from '@reduxjs/toolkit';

import { clearOperationsState, getClientOperations } from 'redux/operations/operations.actions';
import { OperationState } from 'redux/operations/operations.types';

const initialState: OperationState = {
  data: [],
};

const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setOperations: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientOperations.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(clearOperationsState, (state) => {
      state.data = [];
    });
  },
});

export const { setOperations } = operationsSlice.actions;
export default operationsSlice.reducer;
