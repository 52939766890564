import React from 'react';

import { Box, Button, ClickAwayListener, Divider, Fade, List, ListItem, MenuList, Paper, Popper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import clsx from 'clsx';
import cx from 'clsx';
import { useRouter } from 'next/router';

import { sidebarStyles } from './Sidebar.styles';
import ListItemLink from '../ListItemLink';

interface SidebarProps {
  isOpen: boolean;
  highAccess: boolean;
  globalAccess: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, highAccess, globalAccess }): any => {
  const classes = sidebarStyles();
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const isNestedLinks = ['/lost-clients', '/transitions', '/ratings'].includes(router.pathname as unknown as string);
  return (
    <aside
      className={clsx(classes.root, {
        [classes.open]: isOpen,
      })}
    >
      <List>
        <ListItemLink href={'/'} icon={'/offices.svg'} primary={'Главная'} isActive={router.pathname === '/'} />
        <ListItemLink href={'/clients'} icon={'/team.svg'} primary={'Клиенты'} isActive={router.pathname === '/clients'} />
        <ListItemLink href={'/events'} icon={'/event.svg'} primary={'События'} isActive={router.pathname === '/events'} />
        <ListItemLink href={'/topics'} icon={'/topics.svg'} primary={'Топики'} isActive={router.pathname === '/topics'} />
        {highAccess && (
          <ListItemLink
            href={'/black-list'}
            icon={'/blacklist.svg'}
            primary={'Blacklist'}
            isActive={router.pathname === '/black-list'}
          />
        )}
        <>
          <ListItem
            style={{ padding: 0 }}
            className={cx({
              [classes.nestedLinkActive]: isNestedLinks,
            })}
          >
            <Button style={{ width: '100%', height: '109px', textTransform: 'unset' }} onClick={handleClick}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <InsertChartIcon style={{ fontSize: 40 }} />
                <Typography
                  style={{
                    fontSize: '14px',
                    marginTop: 10,
                    fontWeight: 500,
                    lineHeight: '14px',
                  }}
                  align={'center'}
                  variant={'body2'}
                >
                  Отчеты
                </Typography>
              </Box>
            </Button>
          </ListItem>
          <Popper open={open} anchorEl={anchorEl} placement={'left'} transition style={{ zIndex: 2 }}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box className={classes.sidebarPopover}>
                  <Paper onClick={handleClose}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList className={classes.menuList} autoFocusItem={open} onKeyDown={handleListKeyDown}>
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/transitions'}
                          icon={'/transitions.svg'}
                          primary={'Продажи'}
                          isActive={router.pathname === '/transitions'}
                        />
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/transitions/additional'}
                          icon={'/transitions.svg'}
                          primary={'Доп. продажи'}
                          isActive={router.pathname === '/transitions/additional'}
                        />
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/transitions/personal'}
                          icon={'/transitions.svg'}
                          primary={'Найм персонала'}
                          isActive={router.pathname === '/transitions/personal'}
                        />
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/transitions/operator'}
                          icon={'/transitions.svg'}
                          primary={'Операторы'}
                          isActive={router.pathname === '/transitions/operator'}
                        />
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/lost-clients'}
                          icon={'/transitions.svg'}
                          primary={'Потерянные клиенты'}
                          isActive={router.pathname === '/lostClients'}
                        />
                        <ListItemLink
                          withPadding={true}
                          isNestedElement
                          href={'/'}
                          icon={'/transitions.svg'}
                          primary={'Список переходов клиентов по сотрудникам'}
                          isActive={router.pathname === '/transition'}
                        />
                        <ListItemLink
                          isNestedElement
                          withPadding={true}
                          href={'/ratings'}
                          icon={'/transitions.svg'}
                          primary={'Рейтинги сотрудников'}
                          isActive={router.pathname === '/ratings'}
                        />
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Box>
              </Fade>
            )}
          </Popper>
        </>
        {globalAccess && (
          <>
            <Divider />
            <ListItemLink href={'/settings'} icon={'/settings.svg'} primary={'Настройки'} isActive={false} />
          </>
        )}
      </List>
    </aside>
  );
};

export default React.memo(Sidebar);
