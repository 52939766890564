import React from 'react';

import Loader from 'react-loader-spinner';

import { useStyles } from './loader.styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

interface LoaderComponentProps {
  blur?: boolean;
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({ blur }) => {
  const classes = useStyles({ blur });
  return (
    <div className={classes.root}>
      <Loader type="Bars" color="#00BFFF" height={100} width={100} />
    </div>
  );
};

export default LoaderComponent;
