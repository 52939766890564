import React from 'react';

import { Button, MenuItem, Menu, Avatar, ListItemIcon, ListItemText, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'redux/login/auth.actions';
import { avatarSelector } from 'redux/profile/profile.selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  menuItem: {
    minWidth: 160,
  },
  icon: {
    minWidth: 40,
  },
}));

const HeaderAvatar = () => {
  const dispatch = useDispatch();
  const avatar = useSelector(avatarSelector) || '';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        disableElevation
        size={'small'}
        endIcon={open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownIcon />}
        aria-controls="header-avatar"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar src={avatar} />
      </Button>

      <Menu
        className={classes.root}
        id="header-avatar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <Link href={'/profile'} passHref>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <ListItemIcon className={classes.icon}>
              <SettingsRoundedIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primary="Профиль" />
          </MenuItem>
        </Link>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.icon}>
            <PowerSettingsNewRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Выйти" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(HeaderAvatar);
