import { createAsyncThunk } from '@reduxjs/toolkit';

import LogAPI from '@/api/log';

import { LOGS_GET_ALL, LOGS_GET_CLIENTS_LOG } from './log.types';

export const getLogs = createAsyncThunk(LOGS_GET_ALL, async (data: any, { rejectWithValue }) => {
  return LogAPI.get(data)
    .then((res) => res.data)
    .catch((err) => rejectWithValue(err.response?.data));
});

export const getClientLog = createAsyncThunk(LOGS_GET_CLIENTS_LOG, async (params: any, { rejectWithValue, dispatch }) => {
  return LogAPI.getClientLogs(params)
    .then((res) => res.data)
    .catch((err) => rejectWithValue(err.response?.data));
});
