import { useEffect, useState } from 'react';

import { LAST_LOGIN_DATE } from '@/utils/constants';

export const useFirstTimeEntry = () => {
  const [isFirstEntry, setIsFirstEntry] = useState(false);

  useEffect(() => {
    const lastLoginDateStr = localStorage.getItem(LAST_LOGIN_DATE);
    if (lastLoginDateStr) {
      const lastLoginDate = new Date(lastLoginDateStr).getDate();
      const currentDate = new Date().getDate();
      if (currentDate !== lastLoginDate) {
        localStorage.setItem(LAST_LOGIN_DATE, new Date().toISOString());
        setIsFirstEntry(true);
      } else {
        setIsFirstEntry(false);
      }
    } else {
      localStorage.setItem(LAST_LOGIN_DATE, new Date().toISOString());
      setIsFirstEntry(true);
    }
  }, []);

  return isFirstEntry;
};
