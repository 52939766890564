import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { EntityParsedResponse, ISortPayload } from '@/types/common';
import ClientAPI from 'api/client';
import { closeModal, showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';
import { CLOSE_APP_MODAL } from 'redux/app/app.types';
import { postCreateClientEvent } from 'redux/event/event.actions';

import {
  BROKER_CLIENT_CLEAR_FILTERS,
  BROKER_CLIENT_FILTERS,
  BROKER_CLIENT_SET_FILTERS,
  CLEAR_CURRENT_CLIENT,
  CLIENT_BROKER_CLIENTS_CLEAR_SORTING,
  CLIENT_BROKER_CLIENTS_SET_SORTING,
  CLIENT_CLEAR_FILTERS,
  CLIENT_CLEAR_SORTING,
  CLIENT_CREATE,
  CLIENT_FILTERS,
  CLIENT_GET_ALL,
  CLIENT_GET_BLACKLIST,
  CLIENT_GET_BROKER_CLIENTS,
  CLIENT_GET_CURRENT,
  CLIENT_SET_FAILED_EVENT,
  CLIENT_SET_FILTERS,
  CLIENT_SET_SORTING,
  CLIENT_UPDATE,
  IBrokerClient,
  IClient,
  IUpdateClientActionPayload,
} from './client.types';

export const setClientFilters = createAction(CLIENT_SET_FILTERS, (payload) => {
  window.localStorage.setItem(CLIENT_FILTERS, JSON.stringify(payload));
  return { payload };
});

export const setBrokerClientFilters = createAction(BROKER_CLIENT_SET_FILTERS, (payload) => {
  window.localStorage.setItem(BROKER_CLIENT_FILTERS, JSON.stringify(payload));
  return { payload };
});

export const clearClientFilters = createAction(CLIENT_CLEAR_FILTERS);

export const clearBrokerClientFilters = createAction(BROKER_CLIENT_CLEAR_FILTERS);

export const clearCurrentClient = createAction(CLEAR_CURRENT_CLIENT);

export const setClientFailedEvent = createAction<string>(CLIENT_SET_FAILED_EVENT);

export const setClientSorting = createAction(CLIENT_SET_SORTING, (payload: Array<ISortPayload>) => {
  return {
    payload: {
      [payload[0].id]: payload[0].desc ? '-1' : '1',
    },
  };
});

export const clearClientSorting = createAction(CLIENT_CLEAR_SORTING);

export const setBrokerClientSorting = createAction(CLIENT_BROKER_CLIENTS_SET_SORTING, (payload: Array<ISortPayload>) => {
  return {
    payload: {
      [payload[0].id]: payload[0].desc ? '-1' : '1',
    },
  };
});

export const clearBrokerClientSorting = createAction(CLIENT_BROKER_CLIENTS_CLEAR_SORTING);

export const getClients = createAsyncThunk(CLIENT_GET_ALL, async (params: Record<string, any>, { rejectWithValue }) => {
  return ClientAPI.getAll(params)
    .then((response): EntityParsedResponse<IClient> => {
      return {
        total: response.data?.totalCount || 0,
        result: response?.data.result || [],
      };
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const getBrokerClients = createAsyncThunk(
  CLIENT_GET_BROKER_CLIENTS,
  async (params: Record<string, any>, { rejectWithValue }) => {
    return ClientAPI.getBrokerClients(params)
      .then((response): EntityParsedResponse<IBrokerClient> => {
        return {
          total: response.data?.totalCount || 0,
          result: response?.data.result || [],
        };
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);

export const createClient = createAsyncThunk(CLIENT_CREATE, async (client: Partial<IClient>, { rejectWithValue, dispatch }) => {
  return ClientAPI.create(client)
    .then((response) => {
      const createdClient = response.data.client;
      dispatch(postCreateClientEvent(createdClient));
      dispatch(showSuccessAlert('Клиент успешно добавлен!'));
      dispatch(closeModal());
      return createdClient;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const updateClient = createAsyncThunk(
  CLIENT_UPDATE,
  async (payload: IUpdateClientActionPayload, { rejectWithValue, dispatch }) => {
    const { isShowToast, client } = payload;
    return ClientAPI.update(client)
      .then((response) => {
        isShowToast && dispatch(showSuccessAlert('Клиент обновлен!'));
        isShowToast && dispatch({ type: CLOSE_APP_MODAL });
        return response.data.client;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const getClientById = createAsyncThunk(CLIENT_GET_CURRENT, async (id: string, { rejectWithValue }) => {
  return ClientAPI.getOne(id)
    .then((response) => response?.data.result)
    .catch((error) => {
      const errorMessage = error.response.data?.message || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const findInBlackList = createAsyncThunk(
  CLIENT_GET_BLACKLIST,
  async (params: Record<string, unknown>, { rejectWithValue }) => {
    return ClientAPI.getInBlacklist(params)
      .then((response): EntityParsedResponse<IClient> => {
        return {
          total: response.data?.totalCount || 0,
          result: response?.data?.result || [],
        };
      })
      .catch((error) => {
        const errorMessage = error.response.data?.message || 'Something went wrong';
        return rejectWithValue(errorMessage);
      });
  },
);

export const postClient = createClient;
