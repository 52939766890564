import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { closeModal, getRolesData, showModal } from 'redux/app/app.actions';

import { AppState, IModalProps } from './app.types';

const initialState: AppState = {
  modal: {
    showModal: false,
    modalName: null,
    modalType: null,
    modalData: null,
    size: 'md',
    title: '',
  },
  alertNotify: {
    show: false,
    type: undefined,
    message: undefined,
  },
  roles: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRolesData.fulfilled, (state, { payload }) => {
      state.roles = payload;
    });
    builder.addCase(closeModal, (state) => {
      state.modal = initialState.modal;
    });
    builder.addMatcher(isAnyOf(showModal), (state, { payload }) => {
      state.modal = { ...(payload as IModalProps) };
    });
  },
});

export default appSlice.reducer;
