export interface IOperation {
  _id: string;
  createdAt: Date;
  type: string;
  totalAmount: number;
  brokerClientId: string;
}

export interface OperationState {
  data: Array<IOperation>;
}

export const GET_OPERATIONS = 'GET_OPERATIONS';
export const CLEAR_OPERATIONS_STATE = 'CLEAR_OPERATIONS_STATE';
