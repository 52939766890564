import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 10,
    overflow: 'hidden',
    bottom: 25,
    right: 25,
  },

  buttonBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
}));
