import { createSlice } from '@reduxjs/toolkit';

import { ratingApi } from 'redux/ratings/rating.service';
import { RatingState } from 'redux/ratings/rating.types';

const initialState: RatingState = {
  data: [],
};

const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ratingApi.endpoints.getAllRatings.matchFulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});

export default ratingSlice.reducer;
