import { createAsyncThunk } from '@reduxjs/toolkit';

import OfficeAPI from '@/api/office';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';

import {
  IOffice,
  OFFICE_CREATE,
  OFFICE_DELETE,
  OFFICE_GET_ALL,
  OFFICE_UPDATE,
  SwitchOffOfficeRequest,
  TOGGLE_OFFICE_ACTIVE,
} from './office.types';

export const getOffices = createAsyncThunk(OFFICE_GET_ALL, async (params: Record<string, string>, { rejectWithValue }) => {
  return OfficeAPI.getAll(params)
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = error?.response?.data?.detail || 'Something went wrong';
      return rejectWithValue(errorMessage);
    });
});

export const postOffice = createAsyncThunk(OFFICE_CREATE, async (office: Partial<IOffice>, { dispatch, rejectWithValue }) => {
  return OfficeAPI.create(office)
    .then((res) => {
      dispatch(showSuccessAlert('Офис успешно добавлен!'));
      return res.data.office;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const patchOffice = createAsyncThunk(OFFICE_UPDATE, async (office: IOffice, { rejectWithValue, dispatch }) => {
  return OfficeAPI.update(office)
    .then((res) => {
      dispatch(showSuccessAlert('Офис успешно обновлен!'));
      return res.data.office;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});

export const toggleOfficeActive = createAsyncThunk(
  TOGGLE_OFFICE_ACTIVE,
  async (data: SwitchOffOfficeRequest, { rejectWithValue, dispatch }) => {
    return OfficeAPI.toggleActive(data)
      .then((res) => {
        dispatch(showSuccessAlert(!data.isActive ? 'Офис выключен' : 'Офис включен'));
        return res.data.office;
      })
      .catch((error) => {
        dispatch(showErrorAlert(error?.response?.data));
        return rejectWithValue(error?.response?.data);
      });
  },
);

export const deleteOffice = createAsyncThunk(OFFICE_DELETE, async (id: string, { rejectWithValue, dispatch }) => {
  return OfficeAPI.delete(id)
    .then(() => {
      dispatch(showSuccessAlert('Офис удален!'));
      return id;
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    });
});
