import React, { useState } from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Button,
  Select,
  Switch,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useForm, Controller } from 'react-hook-form';

import { BaseModalProps, BaseOptionItem } from '@/types/common';
import { closeModal } from 'redux/app/app.actions';
import { modalDataSelector } from 'redux/app/app.selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchOffice, postOffice } from 'redux/office/office.actions';
import { officeLoadingStatusSelector, officeOptionSelector } from 'redux/office/office.selectors';
import { IOffice } from 'redux/office/office.types';

const CreateOffice = (props: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(officeLoadingStatusSelector);
  const modalData = useAppSelector(modalDataSelector) as IOffice;
  const { regionOptions, groupOptions } = useAppSelector(officeOptionSelector);
  const [newOptions, setNewOptions] = useState({ region: false, group: false });
  const officeData = modalData?._id ? modalData : null;
  const [office, setOffice] = useState({
    name: officeData?.name || '',
    city: officeData?.city || '',
    address: officeData?.address || '',
    group: officeData?.group || '',
    region: officeData?.region || '',
    payDate: officeData?.payDate || null,
  });

  const { errors, register, handleSubmit, control, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { ...officeData },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const switchNewOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOptions({ ...newOptions, [event.target.name]: event.target.checked });
  };
  const submit = (data: IOffice) => {
    if (!!officeData) {
      dispatch(
        patchOffice({
          ...data,
          _id: officeData._id,
        }),
      );
    } else {
      dispatch(
        postOffice({
          ...data,
          // createdAt: new Date(),
        }),
      );
    }
    dispatch(closeModal());
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={'name'}
            rules={{ required: true }}
            defaultValue={office.name}
            control={control}
            render={(props) => (
              <TextField {...props} label={'Имя офиса'} error={!!errors.name} fullWidth size={'small'} variant={'outlined'} />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={'city'}
            rules={{ required: true }}
            defaultValue={office.city}
            control={control}
            render={(props) => (
              <TextField {...props} error={!!errors.city} label={'Город'} fullWidth size={'small'} variant={'outlined'} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name={'address'}
            rules={{ required: true }}
            defaultValue={office.address}
            control={control}
            render={(props) => (
              <TextField {...props} error={!!errors.address} label={'Адрес'} fullWidth size={'small'} variant={'outlined'} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'10px'} flexWrap={'nowrap'}>
            <Controller
              name="group"
              defaultValue={office.group}
              control={control}
              rules={{ required: true }}
              render={(props) =>
                !newOptions.group ? (
                  <FormControl fullWidth size={'small'} variant="outlined">
                    <InputLabel id="select-outlined-label-group">Группа офисов</InputLabel>
                    <Select
                      {...props}
                      defaultValue={groupOptions.find((option) => option.value === office.group) || null}
                      error={!!errors.group}
                      labelId="select-outlined-label-group"
                      id="select-outlined-group"
                      // value={userData.role}
                    >
                      {groupOptions.map((region: BaseOptionItem<string>) => (
                        <MenuItem value={region.value} key={region.value}>
                          {region.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    {...props}
                    fullWidth
                    size={'small'}
                    variant={'outlined'}
                    label={'Группа регионов'}
                    error={!!errors.group}
                  />
                )
              }
            />
            <FormControlLabel
              control={<Switch checked={newOptions.group} onChange={switchNewOption} name="group" />}
              label="новый"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'10px'} flexWrap={'nowrap'}>
            <Controller
              name="region"
              defaultValue={office.region}
              control={control}
              rules={{ required: true }}
              render={(props) =>
                !newOptions.region ? (
                  <FormControl fullWidth size={'small'} variant="outlined">
                    <InputLabel id="select-outlined-label-group">Регион</InputLabel>
                    <Select
                      {...props}
                      error={!!errors.region}
                      labelId="select-outlined-label-group"
                      id="select-outlined-group"
                      // value={userData.role}
                    >
                      {regionOptions.map((region: BaseOptionItem<string>) => (
                        <MenuItem value={region.value} key={region.value}>
                          {region.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField {...props} fullWidth label={'Регион'} size={'small'} variant={'outlined'} error={!!errors.region} />
                )
              }
            />

            <FormControlLabel
              control={<Switch checked={newOptions.region} onChange={switchNewOption} name="region" />}
              label="новый"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={'payDate'}
            rules={{ required: true }}
            defaultValue={office.payDate}
            control={control}
            render={(props) => (
              <TextField
                {...props}
                type={'number'}
                inputProps={{ min: 1, max: 31 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  const formatted = +value >= 31 ? '31' : +value < 0 ? 0 : value;
                  setValue('payDate', formatted);
                }}
                error={!!errors.payDate}
                label={'День выплат (число)'}
                fullWidth
                size={'small'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box style={{ justifyContent: 'center', display: 'flex', padding: '15px 0' }}>
        <Button
          variant={'contained'}
          color={'primary'}
          fullWidth
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} color="secondary" />}
          onClick={handleSubmit(submit)}
        >
          Сохранить
        </Button>
      </Box>
    </form>
  );
};

export default CreateOffice;
