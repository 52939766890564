import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    gridGap: 10,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  heading: {
    fontSize: 20,
  },
  mainContainer: {
    height: 400,
    width: '100%',
    display: 'flex',
    gap: 20,
    padding: '40px 0',
  },
}));

export default useStyles;
