import { createSlice } from '@reduxjs/toolkit';

import { IFetchingStatuses } from '@/types/common';
import { getDefaultFilterValues } from '@/utils/utils';
import {
  clearOrderFilter,
  clearOrdersSorting,
  getClientOrders,
  setOrdersFilters,
  setOrdersSorting,
} from 'redux/orders/orders.actions';
import { ORDERS_FILTERS, OrdersState } from 'redux/orders/orders.types';

const initialState: OrdersState = {
  orders: [],
  status: null,
  totalOrdersCount: 0,
  filters: getDefaultFilterValues(ORDERS_FILTERS),
  sort: null,
};

const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientOrders.fulfilled, (state, { payload }) => {
      state.orders = payload.result;
      state.totalOrdersCount = payload.total;
      state.status = IFetchingStatuses.success;
    });
    builder.addCase(getClientOrders.pending, (state, {}) => {
      state.orders = [];
      state.status = IFetchingStatuses.pending;
      state.totalOrdersCount = 0;
    });
    builder.addCase(getClientOrders.rejected, (state, {}) => {
      state.orders = [];
      state.totalOrdersCount = 0;
      state.status = IFetchingStatuses.error;
    });
    builder.addCase(clearOrderFilter, (state) => {
      state.filters = null;
      window.localStorage.setItem(ORDERS_FILTERS, '');
    });
    builder.addCase(setOrdersFilters, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(setOrdersSorting, (state, { payload }) => {
      state.sort = payload;
    });
    builder.addCase(clearOrdersSorting, (state) => {
      state.sort = null;
    });
  },
});

export default OrdersSlice.reducer;
