import { createSelector } from 'reselect';

import {
  APPLICANT,
  TRAINEE,
  MANAGER,
  SENIORMANAGER,
  TOPMANAGER,
  SALESHEADMANAGER,
  REGIONALDIRECTOR,
  GROUPOFREGIONDIRECTOR,
  DEVELOPMENTDIRECTORDEPUTY,
  DEVELOPMENTDIRECTOR,
  ADMIN,
  ACCOUNTANT,
  ATTRACTIONMANAGER,
  ATTRACTIONHEADMANAGER,
  HR,
  ANALYTIC,
  OPERATOR,
  HEADOFATTRACTIONDEPARTMENT,
} from '@/variables/management';
import { ICreationAccessByRole, IFullOfficeAccessByRole, ViewAccessSelectorTypes } from 'redux/managment/management.types';
import { roleSelector, myIdSelector } from 'redux/profile/profile.selectors';
import { RootState } from 'redux/store';

export const globalAccessByRole = createSelector(roleSelector, (role): IFullOfficeAccessByRole => {
  const anyAction = [
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    SALESHEADMANAGER,
  ].includes(role);

  return {
    fullAccess: anyAction,
  };
});

export const accessViewSelector = createSelector(roleSelector, (role): boolean => {
  return [
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    SALESHEADMANAGER,
  ].includes(role);
});

export const attractionAccessByRole = createSelector(roleSelector, (role): Record<string, boolean> => {
  const attractionRole = [ATTRACTIONMANAGER, ATTRACTIONHEADMANAGER].includes(role);

  return {
    attractionAccess: attractionRole,
  };
});

export const creationAccessByRole = createSelector(roleSelector, (role): ICreationAccessByRole => {
  const userCreation = [
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    HEADOFATTRACTIONDEPARTMENT,
    ADMIN,
    HR,
  ].includes(role);

  const messageCreation = [
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    HEADOFATTRACTIONDEPARTMENT,
  ].includes(role);

  const officeCreation = [
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
  ].includes(role);

  const clientCreation = [
    TRAINEE,
    MANAGER,
    SENIORMANAGER,
    TOPMANAGER,
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    ATTRACTIONMANAGER,
    ATTRACTIONHEADMANAGER,
    HEADOFATTRACTIONDEPARTMENT,
    HR,
  ].includes(role);

  const eventCreation = [
    TRAINEE,
    MANAGER,
    SENIORMANAGER,
    TOPMANAGER,
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    ANALYTIC,
    HEADOFATTRACTIONDEPARTMENT,
  ].includes(role);

  return {
    createUser: userCreation,
    createOffice: officeCreation,
    createClient: clientCreation,
    createEvent: eventCreation,
    createMessage: messageCreation,
  };
});

export const viewingAccessByRole = createSelector(roleSelector, (role): ViewAccessSelectorTypes => {
  const userProfilePreview = [
    SENIORMANAGER,
    TOPMANAGER,
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    ACCOUNTANT,
    HR,
    HEADOFATTRACTIONDEPARTMENT,
  ].includes(role);

  const roleListPreview = [
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
  ].includes(role);

  const fullaccessListPreview = [DEVELOPMENTDIRECTORDEPUTY, DEVELOPMENTDIRECTOR, ADMIN].includes(role);

  const officeListPreview = role !== APPLICANT;

  const clientListPreview = [
    TRAINEE,
    MANAGER,
    SENIORMANAGER,
    TOPMANAGER,
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    ATTRACTIONMANAGER,
    ATTRACTIONHEADMANAGER,
    HEADOFATTRACTIONDEPARTMENT,
  ].includes(role);

  const brokerClientListPreview = [
    SENIORMANAGER,
    TOPMANAGER,
    SALESHEADMANAGER,
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    ATTRACTIONMANAGER,
    ATTRACTIONHEADMANAGER,
  ].includes(role);

  const highAccess = [
    REGIONALDIRECTOR,
    GROUPOFREGIONDIRECTOR,
    DEVELOPMENTDIRECTORDEPUTY,
    DEVELOPMENTDIRECTOR,
    ADMIN,
    SALESHEADMANAGER,
  ].includes(role);

  const globalAccess = [DEVELOPMENTDIRECTORDEPUTY, DEVELOPMENTDIRECTOR, ADMIN].includes(role);

  return {
    isAllowAccessToUsersProfile: userProfilePreview,
    isAllowAccessToRoleList: roleListPreview,
    isAllowAccessToOfficeList: officeListPreview,
    isAllowAccessToClientList: clientListPreview,
    isAdminAccess: fullaccessListPreview,
    isAllowCreateBrokerId: globalAccess,
    isAllowToBrokerClients: brokerClientListPreview,
    highAccess,
    globalAccess,
  };
});

export const canAssignClientAnalytic = createSelector(roleSelector, (role) => {
  return ![ATTRACTIONMANAGER, ATTRACTIONHEADMANAGER, HR, OPERATOR, ACCOUNTANT].includes(role);
});
export const techSupportRoleSelector = createSelector(myIdSelector, (id) => {
  // TODO хардкод юзера на роль саппорта, переделать после внедрения роли саппорта
  return id === '642a9a58028bd80014d74ee2';
});
