import { AxiosResponse } from 'axios';

import { IStorageItemData } from '@/utils/fileStorage';
import axios from 'api';
import { IUser } from 'redux/user/user.types';

const url = '/api/upload';

const storageAPI = {
  uploadFiles: (data: FormData, folder: string): Promise<IStorageItemData[]> =>
    axios
      .post(`${url}?path=${folder}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response: AxiosResponse<IStorageItemData[]>): IStorageItemData[] => response.data)
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      }),
  uploadAvatar: (data: FormData): Promise<IUser> =>
    axios
      .post(`${url}/avatar`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res: AxiosResponse<IUser>): IUser => res.data)
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      }),
  deleteFile: (key: string): Promise<unknown> =>
    axios
      .delete(`${url}?key=${key}`)
      .then((res) => res)
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      }),
  deleteUserDoc: (key: string, id: string): Promise<unknown> =>
    axios
      .delete(`${url}/docs/?key=${key}&id=${id}`)
      .then((res) => res)
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      }),
};

export default storageAPI;
