import { IFetchingStatuses } from '@/types/common';
import { IUser } from 'redux/user/user.types';

export enum NotificationTypes {
  TOPICS = 'TOPICS',
  EVENTS = 'EVENTS',
  CLIENTS = 'CLIENTS',
  USERS = 'USERS',
  TASKS = 'TASKS',
  OTHER = 'OTHER',
}

export interface INotification {
  _id: string;
  to: IUser | string;
  type: NotificationTypes;
  description: string;
  isRead: boolean;
  createdAt: Date;
  metaId: string;
}

export interface NotificationsState {
  unreadNotifications: Array<INotification>;
  readNotifications: Array<INotification>;
  unreadCount: number;
  readCount: number;
  status: IFetchingStatuses;
}

export interface UpdateNotificationsRequest {
  data: Array<string>;
}

export interface GetAllNotificationResponse extends Partial<NotificationsState> {
  result?: Array<INotification>;
  type?: 'read' | 'unread';
}

export const NOTIFICATIONS_GET_ALL = 'NOTIFICATIONS_GET_ALL';
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const NOTIFICATIONS_DELETE = 'NOTIFICATIONS_DELETE';
